import '@babylonjs/loaders'

import * as BABYLON from "@babylonjs/core";
import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

// Brown bottle three-dimensional model.
class BottleModel extends AbstractModel {
    dimensions = new Vector3(31.75, 40, 31.75);
    heightMm = 170;

    buildInternalMesh(scene) {
        return new Promise((resolve, reject) => {
            SceneLoader.ImportMeshAsync("", "/models/bottle/", "bottle.glb", scene)
            .then((data) => {
                data.meshes[2].material = new StandardMaterial("bottle", scene);
                data.meshes[2].material.diffuseColor = new Color3(102 / 255, 51 / 255, 0 / 255);
                data.meshes[2].name = this.meshName
                data.meshes[1].name = this.meshName
                data.meshes[0].name = this.meshName

                resolve(Object.assign(this, { mesh: data.meshes[0] }))
            })
            .catch((error) => { // Handle any errors that occurred during the asynchronous operation
                console.error("Error loading meshes:", error);
                reject(error);
            })
        })
    }
}

export default BottleModel;
