import Item from '../../api/geo/Item'
import * as types from '../actions.types'

import { formatFilters } from "../../shared/utils";
import { Dispatch } from 'redux';
import { ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc'

export const readItem = (uid: string) => async (dispatch: Dispatch) => {
    const itemId = new ItemPb.ItemID(); // Create a new FurnitureID
    itemId.setUid(uid) // using the provided SN

    Item.read(itemId, dispatch)
    .then((response) => dispatch(
        { type: types.GET_ITEM_SUCCESS, payload: response.toObject() }
    ))
    .catch((err) => dispatch(
        { type: types.GET_ITEM_FAILURE, payload: null, err }
    ))
};

export const getAllItems = (filters: {}) => async (dispatch: Dispatch) => {
    const arg = formatFilters(filters, "item") // Format the filters as needed

    Item.getAll(arg, dispatch)
    .then((response) => {
        dispatch({ type: types.GET_ALL_ITEM_SUCCESS, payload: {...response.toObject(), pagination: {
            size: arg.getPage().getSize(),
            page: arg.getPage().getPage(),
            desc: arg.getPage().getIsDescending(),
            sort: arg.getPage().getSort(),
        }}}
    )})
    .catch((err) => dispatch(
        { type: types.GET_ALL_ITEM_FAILURE, payload: null, err }
    ))
};