export const REQUEST_PENDING = 'REQUEST_PENDING';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

// ================================================================

/* User's log in. */
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';

/* User's log out. */
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

/* Scope the user's permissions. */
export const USER_SCOPE_PERMISSIONS_FAILURE = 'USER_SCOPE_PERMISSIONS_FAILURE';
export const USER_SCOPE_PERMISSIONS_SUCCESS = 'USER_SCOPE_PERMISSIONS_SUCCESS';

// ================================================================

/* Create creates a new board. */
export const CREATE_BOARD_FAILURE = 'CREATE_BOARD_FAILURE';
export const CREATE_BOARD_SUCCESS = 'CREATE_BOARD_SUCCESS';

/* Delete allows to delete a board. */
export const DELETE_BOARD_FAILURE = 'DELETE_BOARD_FAILURE';
export const DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';

/* Get returns board information. */
export const GET_BOARD_FAILURE = 'GET_BOARD_FAILURE';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';

/* Update updates an existing board. */
export const UPDATE_BOARD_FAILURE = 'UPDATE_BOARD_FAILURE';
export const UPDATE_BOARD_SUCCESS = 'UPDATE_BOARD_SUCCESS';

/* GetAll returns all boards identifiers. */
export const GET_ALL_BOARD_FAILURE = 'GET_ALL_BOARD_FAILURE';
export const GET_ALL_BOARD_SUCCESS = 'GET_ALL_BOARD_SUCCESS';

/* GetItems returns the content (items) paginated of the given boards. */
export const GET_ITEMS_BOARD_FAILURE = 'GET_ITEMS_BOARD_FAILURE';
export const GET_ITEMS_BOARD_SUCCESS = 'GET_ITEMS_BOARD_SUCCESS';

/* Ping sends a request to the board for getting connection state. */
export const PING_BOARD_FAILURE = 'PING_BOARD_FAILURE';
export const PING_BOARD_SUCCESS = 'PING_BOARD_SUCCESS';

/* FullScan sends a request to the board for getting a full scan. */
export const FULLSCAN_BOARD_FAILURE = 'FULLSCAN_BOARD_FAILURE';
export const FULLSCAN_BOARD_SUCCESS = 'FULLSCAN_BOARD_SUCCESS';

/* SetMqttURL sends a request to the board for setting the MQTT broker URL. */
export const SET_MQTT_URL_BOARD_FAILURE = 'SET_MQTT_URL_BOARD_FAILURE';
export const SET_MQTT_URL_BOARD_SUCCESS = 'SET_MQTT_URL_BOARD_SUCCESS';

/* SetMqttCred sends a request to the board for setting the MQTT broker credentials. */
export const SET_MQTT_CRED_BOARD_FAILURE = 'SET_MQTT_CRED_BOARD_FAILURE';
export const SET_MQTT_CRED_BOARD_SUCCESS = 'SET_MQTT_CRED_BOARD_SUCCESS';

/* SetWifiCred sends a request to the board for setting its wifi credentials. */
export const SET_WIFI_CREDENTIALS_BOARD_FAILURE = 'SET_WIFI_CREDENTIALS_BOARD_FAILURE';
export const SET_WIFI_CREDENTIALS_BOARD_SUCCESS = 'SET_WIFI_CREDENTIALS_BOARD_SUCCESS';

/* SetRFType sends a request to the board for setting its RF type. */
export const SET_RF_TYPE_BOARD_FAILURE = 'SET_RF_TYPE_BOARD_FAILURE';
export const SET_RF_TYPE_BOARD_SUCCESS = 'SET_RF_TYPE_BOARD_SUCCESS';

/* Reboot sends a request to the board for reboot. */
export const REBOOT_BOARD_FAILURE = 'REBOOT_BOARD_FAILURE';
export const REBOOT_BOARD_SUCCESS = 'REBOOT_BOARD_SUCCESS';

// ================================================================

/* Create a new gateway reference. */
export const CREATE_GW_FAILURE = 'CREATE_GW_FAILURE';
export const CREATE_GW_SUCCESS = 'CREATE_GW_SUCCESS';

/* Delete allows to delete a gateway. */
export const DELETE_GW_FAILURE = 'DELETE_GW_FAILURE';
export const DELETE_GW_SUCCESS = 'DELETE_GW_SUCCESS';

/* Get returns gateway information. */
export const GET_GW_FAILURE = 'GET_GW_FAILURE';
export const GET_GW_SUCCESS = 'GET_GW_SUCCESS';

/* GetAll returns all gateways matching with the filters given. */
export const GET_ALL_GW_FAILURE = 'GET_ALL_GW_FAILURE';
export const GET_ALL_GW_SUCCESS = 'GET_ALL_GW_SUCCESS';

/* Ping sends a request to the gateway for getting connection state. */
export const PING_GW_FAILURE = 'PING_GW_FAILURE';
export const PING_GW_SUCCESS = 'PING_GW_SUCCESS';

/* EnrolKnownBoards enrols known boards on a gateway. */
export const ENROL_KNOWN_BOARDS_GW_FAILURE = 'ENROL_KNOWN_BOARDS_GW_FAILURE';
export const ENROL_KNOWN_BOARDS_GW_SUCCESS = 'ENROL_KNOWN_BOARDS_GW_SUCCESS';

/* FindAndEnrolBoard find a board and enrol it on a gateway (to repeat, one by one board). */
export const FIND_AND_ENROL_BOARDS_GW_FAILURE = 'FIND_AND_ENROL_BOARDS_GW_FAILURE';
export const FIND_AND_ENROL_BOARDS_GW_SUCCESS = 'FIND_AND_ENROL_BOARDS_GW_SUCCESS';

/* UnenrolBoards unenrol all boards from a gateway. */
export const UNENROL_BOARDS_GW_FAILURE = 'UNENROL_BOARDS_GW_FAILURE';
export const UNENROL_BOARDS_GW_SUCCESS = 'UNENROL_BOARDS_GW_SUCCESS';

/* FindByBoard allows to find a gateway from a board enrolled. */
export const FIND_BY_BOARD_GW_FAILURE = 'FIND_BY_BOARD_GW_FAILURE';
export const FIND_BY_BOARD_GW_SUCCESS = 'FIND_BY_BOARD_GW_SUCCESS';

// ================================================================

/* Create creates an item. */
export const CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';

/* Read returns the data related to an item. */
export const READ_ITEM_FAILURE = 'READ_ITEM_FAILURE';
export const READ_ITEM_SUCCESS = 'READ_ITEM_SUCCESS';

/* Delete deletes an item. */
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';

/* Get invokes while returned from board content. */
export const GET_ITEM_FAILURE = 'GET_ITEM_FAILURE';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';

/* GetAll returns all items corresponding to the filters. */
export const GET_ALL_ITEM_FAILURE = 'GET_ALL_ITEM_FAILURE';
export const GET_ALL_ITEM_SUCCESS = 'GET_ALL_ITEM_SUCCESS';

// ================================================================

/* UpdateBoard updates the board's tenant. */
export const UPDATE_BOARD_TENANT_FAILURE = 'UPDATE_BOARD_TENANT_BOARD_FAILURE';
export const UPDATE_BOARD_TENANT_SUCCESS = 'UPDATE_BOARD_TENANT_BOARD_SUCCESS';

/* UpdateItem updates the item's tenant. */
export const UPDATE_ITEM_TENANT_FAILURE = 'UPDATE_ITEM_TENANT_BOARD_FAILURE';
export const UPDATE_ITEM_TENANT_SUCCESS = 'UPDATE_ITEM_TENANT_BOARD_SUCCESS';

/* Transfer transfers all data owned by a tenant to another. */
export const TRANSFER_TENANT_FAILURE = 'TRANSFER_TENANT_FAILURE';
export const TRANSFER_TENANT_SUCCESS = 'TRANSFER_TENANT_SUCCESS';

/* Delete deletes all data related to a tenant and optionally the tenant itself from the authentication system. */
export const DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE';
export const DELETE_TENANT_SUCCESS = 'DELETE_SUCCESS';

// ================================================================

/* Get invokes while returned from furniture content. */
export const GET_FURNITURE_FAILURE = 'GET_FURNITURE_FAILURE';
export const GET_FURNITURE_SUCCESS = 'GET_FURNITURE_SUCCESS';

/* GetAll returns all furnitures corresponding to the filters. */
export const GET_ALL_FURNITURE_FAILURE = 'GET_ALL_FURNITURE_FAILURE';
export const GET_ALL_FURNITURE_SUCCESS = 'GET_ALL_FURNITURE_SUCCESS';
