import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { outputLog } from "../shared/utils";
import { BoardPb, ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { FurniturePb } from '@centiloc/centiloc-ops-api-inventory-grpc'
import { default as CLIENTS } from "../api"

import CustomModal from "../components/Modal"
import Playground from "../pages/Playground";

// Prepares all the necessary data for three-dimensional viewing.
export default () => {
    // State of the displayed initialized setup error 
    const [setupError, setSetupError] = useState({ show: false, message: "" });

    // Collected campaign data URL
    const url = JSON.parse(new URLSearchParams(useLocation()?.search)?.getAll('filters'));

    // This hook returns a function that lets you navigate programmatically, able to redirect to another component, refresh the current one, etc...
    const navigate = useNavigate();

    // Allows you to extract data from the Redux store state
    const referer = useSelector(state => state.root.referer);

    // Component's custom state logic that involves multiple collected and required sub-values
    const [state, dispatch] = useReducer((state, action) => {
        if (action.type === "SETUP_FAILED") {
            setSetupError(action.error)
            console.error("Failed to setup, something went wrong on requesting data : ", action.payload, action.error)
        } else {
            outputLog(["localhost", ".dev"], false, "Requested dataset:", action.payload)
        }
        
        switch (action.type) {
            case "SET_BOARD_SN":
                return { ...state, bSN: action.payload };
            case "SET_BOARD":
                return { ...state, board: action.payload };
            case "SET_FURNITURE":
                return { ...state, furniture: action.payload };
            case "SET_ITEM":
                return { ...state, item: action.payload };
            default:
                break;
        }
        
        return { ...state }
    }, { 
        bSN: url.hasOwnProperty('board_sn') ? url.board_sn : null, 
        board: null, 
        furniture: null, 
        item: null 
    }), { bSN, board, furniture, item } = state;

    // Collect the URL informations
    const uid = useMemo(() => url.hasOwnProperty('uid') ? url.uid : "", [url]);
    const fSN = useMemo(() => (url.hasOwnProperty('furniture_sn') ? url.furniture_sn : null), [url]);

    // Retrieves the item's parent's serial.
    useEffect(() => {
        if (!bSN) {
            item && dispatch({ type: 'SET_BOARD_SN', payload: item.boardSn });
        } 
    }, [bSN, item, dispatch]);

    // GET Furniture
    useEffect(() => {
        if (fSN) {
            const arg = new FurniturePb.FurnitureID(); 
            arg.setSn(fSN);

            CLIENTS.inventory.furniture.get(arg, dispatch).then((response) => {
                dispatch({ type: 'SET_FURNITURE', payload: response.toObject() })
            }).catch((err) => {
                dispatch({ type: 'SETUP_FAILED', payload: fSN, error: {show: true, message: err.message} })
            });
        }
    }, [fSN])

    // GET Board
    useEffect(() => {
        if (bSN) {
            const boardId = new BoardPb.BoardID(); boardId.setSn(bSN)

            // Get Board query
            CLIENTS.geo.board.get(boardId, dispatch, null, null, (err, response) => {
                if (err) {
                    dispatch({ type: 'SETUP_FAILED', payload: sn, error: {show: true, message: err.message} })
                } else {
                    dispatch({ type: 'SET_BOARD', payload: response.toObject() })
                }
            });
        }
    }, [bSN])

    // GET Item
    useEffect(() => {
        if (uid) {
            const itemId = new ItemPb.ItemID(); 
            itemId.setUid(uid);
    
            CLIENTS.geo.item.read(itemId, dispatch).then((response) => {
                dispatch({ type: 'SET_ITEM', payload: response.toObject() })
            }).catch((err) => {
                dispatch({ type: 'SETUP_FAILED', payload: uid, error: {show: true, message: err.message} })
            });
        }
    }, [uid])

    // Playground renderer
    return useMemo(() => {
        if (board || furniture) {
            return <Playground uid={ uid } board={ board } furniture={ furniture } />
        } else if (setupError.show) {
            return <CustomModal show={ setupError.show } title={ "No data available, please return back." } message={ setupError.message } footer={ 
                <Modal.Footer>
                    <Button variant="light" onClick={() => { // Construct the new URL with the relative path and previous parameters
                        referer ? navigate(referer.pathname + referer.search) : navigate("..", { relative: "path" })
                    }}>Back</Button>
                </Modal.Footer> 
            } />
        }
    }, [board, furniture, setupError])
}
