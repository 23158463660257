import React, { useLayoutEffect } from "react";

// Footer React component.
const Footer = () => {

    // Manages the footer version.
    // The useLayoutEffect Hook allows you to perform side effects in your components
    //     as fetching data, directly updating the DOM, etc....
    useLayoutEffect(() => {
        let footer = document.getElementById("footer")

        // Running on development environment
        if (process.env.NODE_ENV === 'development')
            footer.innerHTML = "© Centiloc 2023 | development" // Replace the version on development environment

        // Lite running app
        if (window.location.origin === 'file://')
            footer.innerHTML = "© Centiloc 2023 | lite" // TODO:
    }, []);

    return (
        <footer id="footer" className='text-uppercase'>
            © Centiloc 2023 | v1.8.2
        </footer>
    )
};

export default Footer