import '@babylonjs/loaders'

import { Color3 } from "@babylonjs/core/Maths/math.color"
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

/**
 * @name GlueModel
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
class GlueModel extends AbstractModel {
    dimensions = new Vector3(10, 10, 10);
    heightMm = 150;

    buildInternalMesh(scene) {
        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/glue/", "glue.obj", scene);
            this.mesh = data.meshes[0]

            resolve(this)
        })
    }
}

export default GlueModel;
