import React from "react";
import { useSelector } from "react-redux"
import { RootState } from '../redux/root.reducer.js'; // Import the RootState from your Redux store
import { default as Gangplank } from "../components/Gangplank.jsx";
import { getAllFurnitures } from "../redux/actions/furniture.actions"
import { column } from "../types/interfaces.js";

// Data defining a filter for furnitures
const filters = []

// Define type for the table columns content settings
const columns: column[] = [
    { Header: "SN", value: "ID", isSortable: true, accessor: "id", Cell: (props) => /* data displayed */ props.cell.row.original?.id?.sn},
]

const FurnitureList: React.FC = () => {
    // Redux stored data selection & extraction 
    // Allows you to extract data from the Redux store state for use in this component, using a selector function.
    const furnitures = useSelector((state: RootState) => state.furniture);

    return (
        <Gangplank
            type={"furniture"}
            getAll={getAllFurnitures}
            payload={furnitures}
            columns={columns}
            filters={filters}
            actions={[]}
            createForm={null}
        />
    );
};

export default FurnitureList;
