import * as BABYLON from "@babylonjs/core";

class Furniture {
    _scene;
    _width;
    _height;
    _depth;
    _shelfNb;

    constructor(scene, width, height, depth) {
        this._scene = scene
        this._width = width
        this._height = height
        this._depth = depth
    }

    create() {
        this._scene.addMesh(new BABYLON.Mesh(
            this.addRod(0, 0, 0)
        ))
        this._scene.addMesh(new BABYLON.Mesh(
            this.addRod(this._width, 0, 0)
        ))
        this._scene.addMesh(new BABYLON.Mesh(
            this.addRod(0, 0, this._depth)
        ))
        this._scene.addMesh(new BABYLON.Mesh(
            this.addRod(this._width, 0, this._depth)
        ))
    }

    addRod(x, y, z) {
        const rod = new BABYLON.MeshBuilder.CreateBox("box", {width: this._width / 100, height: this._height, depth: this._width / 100}, this._scene)
        rod.position = new BABYLON.Vector3(x, y, z)

        rod.material = new BABYLON.StandardMaterial("materia", this._scene)
        rod.material.diffuseColor = BABYLON.Color3.White()

        return rod
    }
}

export default Furniture;