import Furniture from '../../api/inventory/FurnitureService'
import * as types from '../actions.types'

import { formatFilters } from "../../shared/utils";
import { Dispatch } from 'redux';
import { FurniturePb } from '@centiloc/centiloc-ops-api-inventory-grpc'

export const getFurniture = (sn: string) => async (dispatch: Dispatch) => {
    const message = new FurniturePb.FurnitureID(); // Create a new FurnitureID
    message.setSn(sn) // using the provided SN

    Furniture.get(message, dispatch)
    .then((response) => dispatch(
        { type: types.GET_FURNITURE_SUCCESS, payload: response.toObject() }
    ))
    .catch((err) => dispatch(
        { type: types.GET_FURNITURE_FAILURE, payload: null, err }
    ))
};

export const getAllFurnitures = (filters: {}) => async (dispatch: Dispatch) => {
    const arg = formatFilters(filters, "furniture") // Format the filters as needed

    Furniture.getAll(arg, dispatch)
    .then((response) => dispatch(
        { type: types.GET_ALL_FURNITURE_SUCCESS, payload: {...response.toObject(), pagination: {
            size: arg.getPage().getSize(),
            page: arg.getPage().getPage(),
            desc: arg.getPage().getIsDescending(),
            sort: arg.getPage().getSort(),
        }} }
    ))
    .catch((err) => dispatch(
        { type: types.GET_ALL_FURNITURE_FAILURE, payload: null, err }
    ))
};