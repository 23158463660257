import { createStore, applyMiddleware, /* compose */ } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension'
import { thunk } from "redux-thunk";

// Reducers
import rootReducers from "../root.reducer.tsx";

// Middlewares
import { itemMiddleware } from "../middlewares/item.middleware";
import { boardMiddleware } from "../middlewares/board.middleware.js";
import { furnitureMiddleware } from "../middlewares/furniture.middleware";
import { gatewayMiddleware } from "../middlewares/gateway.middleware";
import { userMiddleware } from "../middlewares/user.middleware";

export function getMiddlewares() {

    // List of all custom middlewares
    const middlewares = [
        itemMiddleware,
        boardMiddleware,
        furnitureMiddleware,
        gatewayMiddleware,
        userMiddleware
    ];

    // config thunk
    middlewares.push(thunk);

    // config logger
    if (process.env.NODE_ENV === "development") {
        const { logger } = require(`redux-logger`);

        middlewares.push(logger);
    }

    return middlewares;
}

export default function configureStore(initialState = {}) {
    // const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // const enhancers = composeEnhancers(applyMiddleware(...getMiddlewares()));

    const middlewareEnhancer = applyMiddleware(...getMiddlewares());
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    // return createStore(rootReducers, initialState, enhancers);
    const store = createStore(rootReducers, initialState, composedEnhancers);
    return store;
}