import React, { useCallback, useEffect, useRef, useState  } from "react";
import { useDispatch } from "react-redux"

import { default as CLIENTS } from "../../../api/index"
import { ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import { formatFilters } from "../../../shared/utils"
import '../../../assets/style/layout/form.css'

const pattern = /^(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|(https?|ftp):\/\/[a-zA-Z0-9.-]+\/?.*|www\.[a-zA-Z0-9.-]+\/?.*)$/
const SetURL = (props) => {
    const dispatch = useDispatch();

    const streamingRPC = useRef(null);

    // Creates state form's fields
    const [fields, setFields] = useState({ ...{ uid : "", url : "" }, uid: props.uid, url: props.url });

    // Effect used to clean all remaining ressources on leaving components (DOM events, streaming, etc...)
    useEffect(() => {
        return () => { // Stream cancellation on leaving the page (component unmount)
            if (streamingRPC.current) {
                streamingRPC.current?.cancel() // Clean on leaving
            }
        }
    }, [streamingRPC.current])
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, uid: props.uid }))
    }, [props.uid])

    // Changes the input value
    const handleChange = e => {
        const validate = (e, regex) => {
            if (regex.test(e.value) || e.value.length === 0) {
                e.className = 'form-control w-75';
            } else {
                e.className = 'form-control w-75 invalid';
            }
        }
        const { name, value } = e.target;

        if (name === "url") {
            validate(e.target, pattern)
        }
        setFields(fields => ({ ...fields, [name]: value }));
    };

    return (
        <Form
            title={"Set URL"}
            response={'URL set successfully'}
            fields={{...fields, uid: props.uid}}
            toggle={
                useCallback(() => { /* setURL */
                    const URLFilters = new ItemPb.URLFilters()
                    URLFilters.setUrl(fields.url)

                    const itemFilters = formatFilters({ filters: [{ filter : "UID", value: fields.uid }]}, "item")
                    URLFilters.setSelectedItemsList(itemFilters)

                    return new Promise(async (resolve, reject) => {
                        // Starts to stream set an URL for all items corresponding to the filters (here it's unitary).
                        const stream = await CLIENTS.geo.item.setURL(URLFilters, dispatch)
                        
                        stream.on('data', (response) => { // on stream received data listener
                            if (response.array[0] === 100) {
                                stream.cancel() // Streaming cancellation - cleans the ressources used before leaving
                                resolve()
                            }
                        })
                
                        stream.on('error', (error) => { // on stream received error listener
                            stream.cancel() // Streaming cancellation - cleans the ressources used before leaving
                            reject(error)
                        })
                        streamingRPC.current = stream
                    })
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-10 mb-4">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="uid" type="text" value={fields.uid}
                            className="form-control w-auto"
                            readOnly="readonly" 
                            required 
                        />
                    </div>
                    <small id="uidHelp" className="form-text text-muted">Item UID</small>
                </div>
                <div className="d-flex align-items-center">
                    <div className="col-sm-12">
                    <input id="url" name="url"  type="url" value={fields.url} autoComplete="false"
                        className="form-control w-75"
                        onChange={handleChange}
                        required
                        onInput={(e) => {
                            if (!pattern.test(e.target.value)) {
                                e.target.setCustomValidity('Invalid input. Please enter a valid URL or email.');
                            } else {
                                e.target.setCustomValidity('');
                            }
                        }}
                    />

                        <small id="urlHelp" className="form-text text-muted">Redirect URL</small><br/>
                        <small id="urlHelp" className="form-text form-help">Invalid URL format</small>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default SetURL;