import * as gwTypes from '../actions.types'

/**
 * @constant INITIAL_STATE
 * 
 * Initial gateways state structure.
 */
const INITIAL_STATE = {
    list : [],
    links: {first: undefined, previous: undefined, next: undefined, last: undefined},
    pagination: {size: 25, page: 1, desc: false, sort: 1},
    count: 0
}

/**
 * @constant reducer
 * @param { initial gateway state } state
 * @param { action } action Actions are the only source of information for the store
 * @returns state
 * 
 * Build the gateways state.
 */
const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {

        /** GATEWAYS ACTIONS **/
        case gwTypes.CREATE_GW_SUCCESS : // Succeed Gw.create action result
            if(!state.list.find(gw => gw.id === action.payload.id && gw.id.sn === action.payload.id.sn)) {
                return {
                    ...state, list: [{ id: { sn: action.payload.sn, type: action.payload.type }, status: "GW_DISCONNECTED" }].concat(state.list)
                }
            } else {
                console.error('Inconsistency data, try to dupplicate an entry already stored with the id : ' + action.payload.id)
            }

            return { ...state }
        case gwTypes.DELETE_GW_SUCCESS : // Succeed Gw.delete action result
            return {
                ...state, list : state.list.filter((gw) => gw.id !== action.payload.id && gw.id.sn !== action.payload.id.sn)
            }
        case gwTypes.GET_GW_SUCCESS : // Succeed Gw.get action result
            if(!state.list.find(gw => gw.id === action.payload.id)) {
                return { // While none dupplication, juste concatenate the store with the returned payload
                    ...state, list: state.list.concat(action.payload)
                }
            } else return { // Concatenate the previous state with the returned payload and replace same occurencies by the new returned dataset 
                ...state, list: [...state.list.filter((gw) => gw.id !== action.payload.id), action.payload]
            }
        case gwTypes.GET_ALL_GW_SUCCESS : // Succeed Gw.getAll action result
            return {
                list: action.payload.gwsList,
                links: { ...state.links, ...action.payload.links },
                pagination: { ...state.pagination, ...action.payload.pagination },
                count: !isNaN(action.payload.count) ? action.payload.count : 0
            }
        default :
            return { ...state }
    }
}

export { reducer }