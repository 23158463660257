import { useCallback } from 'react'
import { Color3 } from "@babylonjs/core/Maths/math.color"
import { HighlightLayer } from "@babylonjs/core/Layers/highlightLayer"

function useHideClipboard(setIsOpen) {
    const hideClipboard = useCallback((scene, uid, HL) => {
        if (HL.hasMesh(scene.getMeshByName(uid))) {
            HL.removeAllMeshes();
            setIsOpen(false);
            return true;
        }
        return false;

    }, [setIsOpen]);

    return hideClipboard;
}

function useUpdateHPick(scene, name, itemUI, highlighter) {
    const updateHPick = useCallback((scene, name, itemUI, highlighter) => {
        let mesh, productL;

        const cleanUpMeshes = () => {
            for (const elem of scene.meshes) {
                if (elem.name === name) {
                    productL?.removeMesh(elem);
                }
            }
        };
    
        const handleTargetedElement = () => {
            for (const elem of scene.meshes) {
                if (elem.name === name && elem.isPickable) {
                    highlighter.addMesh(elem, new Color3(0.06, 0.77, 0.94));
                }
            }
        };

        if (highlighter) {
            highlighter.removeAllMeshes(); // Starts by cleaning current effect(s) on mesh(es)
        
            // Then tries to manage the new selection
            if ((mesh = scene.getMeshByName(name))) {
                if ((productL = scene.getHighlightLayerByName("HL_" + itemUI?.productName))) {
                    if (productL?.hasMesh(mesh)) {
                        // On having highlight ON (by product reference),
                        // removes the picked one's effect (can only be an item)
                        // to avoid layered highlighting effects
                        cleanUpMeshes();
                    }
                }
        
                // Then handle the targeted element,
                // by checking if it was made for (is 'pickable' ?)
                if (!highlighter.hasMesh(mesh)) {
                    handleTargetedElement();
                }
            }
        }
    }, [scene, name, itemUI, highlighter]);

    return updateHPick;
}

function useUpdateHProducts(scene, productL) {
    const updateHProducts = useCallback((scene, productL) => {
        const sampleSelect = document.querySelector('#sample-select');
        
        if (!sampleSelect) {
            return;
        }
    
        // Helper function to confirm the option's value consistency
        const isValidOption = (opt) => {
            if (!opt.value || typeof opt.value !== 'string' || opt.value.length === 0) {
                console.error(`Invalid value. Expected a 'string' as product reference, but got: '${opt.value}'.`);
                return false;
            }
            return true;
        }

        for (const opt of sampleSelect.options || []) {
            if (!isValidOption(opt)) {
                return;
            }
    
            const highlighter = scene.getHighlightLayerByName("HL_" + opt.value) ?? new HighlightLayer("HL_" + opt.value);
    
            if (opt.isSelected) {
                for (const itemName of opt.customData.items) {
                    const mesh = scene.getMeshByName(itemName);
                    if (mesh && !productL.hasMesh(mesh)) {
                        highlighter.addMesh(mesh, new Color3(100 / 255, 1, 0));
                    }
                }
            } else {
                if (Object.keys(highlighter?._meshes)?.length > 0) {
                    highlighter.removeAllMeshes();
                }
            }
        }
    }, [scene, productL]);

    return updateHProducts;
}

function useUpdateOptions() {
    const updateOptions = useCallback((items, itemsUI) => {
        const sampleSelect = document.querySelector('#sample-select'); // Ensure that 'sample-select' exists in the DOM
        const options = sampleSelect?.options;
        const customDataMap = new Map();
        const selectedOpts = [];
    
        if (!sampleSelect) {
          return;
        }
    
        // Helper function to add or update an option
        const addOrUpdateOption = (product) => {
            const { product_id, uid } = product;
        
            if (!product_id) return;
        
            // Ensure customData.items exists for each product_id
            if (!customDataMap.has(product_id)) {
                customDataMap.set(product_id, { items: [uid] });
            } else {
                customDataMap.get(product_id).items.push(uid)
            }

            const data = customDataMap.get(product_id)
            const index = options.findIndex((option) => option.value === product_id);

            if (index == -1) {
                options.push({
                    label: product_id.charAt(0).toUpperCase() + product_id.slice(1),
                    value: product_id,
                    customData: data,
                });
            } else {
                options[index].customData = data 
            }
        };
    
        // Start building the list
        const products = [];
        items.forEach((item) => {
            const ref = item.productReference?.split("-");
            const index = products.findIndex((product) => product.uid === item.uid);
            const product = { uid: item.uid, product_id: ref?.[0] ?? item.productReference };
        
            if (index !== -1) {
                // If a matching uid is found, update the existing object
                products[index] = product;
            } else {
                // If no matching uid is found, add a new object to the array
                products.push(product);
            }
        });

        // Iterate through the sorted products and call addOrUpdateOption for each
        const sortedProducts = products.sort((a, b) =>
            ((a && a?.product_id && a?.product_id) || '').localeCompare((b && b?.product_id) || '')
        );
        sortedProducts.forEach(addOrUpdateOption);

        options.forEach((option) => {
            if (option.customData.items.every((uid) => !itemsUI[uid] || !items.find((i) => i.uid === uid))) {
                options.splice(options.indexOf(option), 1)
            } else if (option.isSelected) {
                selectedOpts.push(option.value);
            }
        });
    
        sampleSelect.style.display = options.length > 0 ? 'block' : 'none';
        if (sampleSelect.style.display === "block") {
            sampleSelect.children[0].style.display = "block"
        }

        sampleSelect.setOptions(options.sort((a, b) => a.value.localeCompare(b.value)));
        sampleSelect.setValue(selectedOpts);
        sampleSelect.querySelector('.vscomp-options-list').style.height = 'auto';
    }, []);

    return updateOptions
}

export {
    useHideClipboard,
    useUpdateHPick,
    useUpdateHProducts,
    useUpdateOptions
};
