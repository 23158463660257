import '@babylonjs/loaders'

import { SubMesh } from "@babylonjs/core/Meshes/subMesh"
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder"
import { Color3 } from "@babylonjs/core/Maths/math.color"
import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

/**
 * @name Beontag
 * @extends AbstractModel
 * 
 * Model of meshes as items as cards, linked tools & static functionnalities representing its movement events
 */
class Beontag extends AbstractModel {
    dimensions = new Vector3(1, 1, 1);
    rotation   = new Vector3(0, 0, 0);
    offsets    = new Vector3(0, 46, 0);

    buildInternalMesh(scene) {
        return new Promise(async(resolve) => {
            
            // Build the mesh as a board
            this.mesh = MeshBuilder.CreateBox(this.name, { height: 92, width: 60, depth: 60}, scene)
            this.mesh.showBoundingBox=false;

            const materialFront = new StandardMaterial("beontag_front", scene);
            materialFront.diffuseTexture = new Texture("/models/beontag/textures/Face1.jpg",scene);
            const materialBack=new StandardMaterial("beontag_front",scene);
            materialBack.diffuseTexture = new Texture("/models/beontag/textures/Face2.jpg");
            const materialLeft = new StandardMaterial("beontag_left",scene);
            materialLeft.diffuseTexture = new Texture("/models/beontag/textures/Face3.jpg");
            const materialRight = new StandardMaterial("beontag_right",scene);
            materialRight.diffuseTexture = new Texture("/models/beontag/textures/Face4.jpg");
            const materialTop = new StandardMaterial("beontag_top",scene);
            materialTop.diffuseColor = Color3.FromHexString("#0557a8");
            const materialBottom = new StandardMaterial("beontag_bottom",scene);
            materialBottom.diffuseColor = Color3.FromHexString("#0557a8");

            // merge textures
            const multi = new MultiMaterial("beontag", scene);
            multi.subMaterials.push(materialFront);
            multi.subMaterials.push(materialBack);
            multi.subMaterials.push(materialLeft);
            multi.subMaterials.push(materialRight);
            multi.subMaterials.push(materialTop);
            multi.subMaterials.push(materialBottom);

            // apply material
            const verticesCount=this.mesh.getTotalVertices();
            this.mesh.subMeshes=[];
            this.mesh.subMeshes.push(new SubMesh(0, 0, verticesCount, 0, 6, this.mesh));
            this.mesh.subMeshes.push(new SubMesh(1, 1, verticesCount, 6, 6, this.mesh));
            this.mesh.subMeshes.push(new SubMesh(2, 2, verticesCount, 12, 6, this.mesh));
            this.mesh.subMeshes.push(new SubMesh(3, 3, verticesCount, 18, 6, this.mesh));
            this.mesh.subMeshes.push(new SubMesh(4, 4, verticesCount, 24, 6, this.mesh));
            this.mesh.subMeshes.push(new SubMesh(5, 5, verticesCount, 30, 6, this.mesh));
            this.mesh.material = multi;
            
            resolve(this)
        })
    }
}

export default Beontag;