import { getClient, captureException } from "@sentry/react";
import { StreamingWeb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { default as ApiManager } from '../ApiManager'

/**
 * @name Streaming
 * @extends ApiManager
 * 
 * Client of Centiloc's API Geo over Stream service.
 */
export default class Streaming extends ApiManager {
    constructor() {
        super(); // Mandatory with extended classes

        try {
            this.client = new StreamingWeb.StreamingClient(window.API_URL, null, null) // Streaming handles all stream events

            if(typeof this.client !== "object") {
                throw new Error("Service unavailable. Failed to create the `streaming` client")
            }
        } catch(err) {
            console.error(err);
            
            if(getClient()?.getOptions()?.enabled)
                captureException(err)
                
            throw new Error("Service Unavailable Exception : Streaming service (api-geo) not ready to handle the request.", err)
        }
    }
    
    /**
     * @function streamItemsEvents()
     * @returns []itemMoveEvent || error
     * 
     * StreamItemsEvents begins a stream to collect any item's move.
     */
    streamItemsEvents(itemMoveOptions, dispatch) {
        try {
            return this.constructor.callAPI(this.client.streamItemsEvents.bind(this.client), itemMoveOptions, null);
        } catch(err) {
            console.error("Error during Strem.streamItemsEvents", err);
        }
    }
}