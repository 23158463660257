// import clients from "../RPCFactory"
// import { default as ApiManager } from '../RPC'
import clients from "../ClientFactory"
import { default as ApiManager } from '../ApiManager'
import { GwWeb, GwId, GwCreation, GwBoards, GwFilters, GwContinuousScanMode, BoardID } from '@centiloc/centiloc-ops-api-geo-grpc'

class Gateway extends ApiManager {
    private static instance: Gateway | null = null;
    public client: GwWeb.GatewayClient;

    private constructor() {
        super();
        
        if (!Gateway.instance) { // If the instance doesn't exist, set it to the current instance
            if (!this.client) {
                if(!(clients.getGatewayGeo() instanceof GwWeb.GatewayClient)) {
                    throw new Error("Failed to instantiate the client of the service 'Gateway' of `geo`");
                }
        
                this.client = clients.getGatewayGeo()
            }
        }
    }

    public static getInstance(): Gateway {
        if (!Gateway.instance) {
            Gateway.instance = new Gateway();
        }
        return Gateway.instance;
    }

    /**
     * Create a new gateway reference.
     *
     * @param arg - represents initial gateway information.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async create(
        arg: GwCreation,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getSn());
                    scope.setExtra("gateway_type", arg.getType());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.create.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     *  Get returns gateway information.
     *
     * @param arg - represents the identifier of a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async get(
        arg: GwId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getSn());
                    scope.setExtra("gateway_type", arg.getType());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.get.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * Delete allows to delete a gateway.
     *
     * @param arg - represents the identifier of a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async delete(
        arg: GwId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getSn());
                    scope.setExtra("gateway_type", arg.getType());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.delete.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * GetAll returns all gateways matching with the filters given.
     *
     * @param filters - represents the data defining a filter for gateways.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async getAll(
        arg: GwFilters,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_filters", arg.getFiltersList());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.getAll.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     *  Ping sends a request to the gateway for getting connection state.
     *
     * @param arg - represents the identifier of a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async ping(
        arg: GwId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getSn());
                    scope.setExtra("gateway_type", arg.getType());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.ping.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * EnrolKnownBoards enrols known boards on a gateway.
     *
     * @param arg - represents information necessary to enrol known boards on a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async enrolKnownBoards(
        arg: GwBoards,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        try {
            return Gateway.callAPI(this.client.enrolKnownBoards.bind(this.client), arg, callback, dispatch);
        } catch (error) {
            Gateway.handleResponseOrError(null, error, null, null, (scope: any) => { 
                scope.setExtra("gateway_id", arg.getId());
                    scope.setExtra("boards_sn", arg.getBoardsSnList());
            });
        }
    }

    /**
     * FindAndEnrolBoard find a board and enrol it on a gateway (to repeat, one by one board).
     *
     * @param arg - represents the identifier of a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async findAndEnrolBoard(
        arg: GwId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getSn());
                    scope.setExtra("gateway_type", arg.getType());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.findAndEnrolBoard.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * UnenrolBoards unenrol all boards from a gateway.
     *
     * @param arg - represents the identifier of a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async unenrolBoards(
        arg: GwId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getSn());
                    scope.setExtra("gateway_type", arg.getType());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.unenrolBoards.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * FindByBoard allows to find a gateway from a board enrolled.
     *
     * @param arg - represents the identifier of a board.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async findByBoard(
        arg: BoardID,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("board_sn", arg.getSn());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.findByBoard.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * SetContinuousScanMode allows to target a gateway and enable its `continuous scan mode` (allows to trig requests for getting a full scan from its enrolled boards).
     *
     * @param arg - represents the identifier of a gateway.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with gateway data or rejects with an error. 
     */
    async setContinuousScanMode(
        arg: GwContinuousScanMode,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Gateway.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("gateway_sn", arg.getId().getSn());
                    scope.setExtra("gateway_type", arg.getId().getType());
                    scope.setExtra("start", arg.getStart());
                    scope.setExtra("full_scan_true", arg.getFullScanTrue());
                })
            }

            // Request the API
            Gateway.callAPI(this.client.setContinuousScanMode.bind(this.client), arg, callback, dispatch);
        });
    }
}

export default Gateway.getInstance()
