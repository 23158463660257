import React from "react";
import { Link } from 'react-router-dom';
import { Layout } from "../components/layouts/Layout.js";
import { Card } from "../components/Card.jsx";
import CentilocItem from '../assets/img/illus-tag-nfc.svg'
import CentilocShelves from '../assets/img/illus-smart-shelves.svg'
import CentilocSoftware from '../assets/img/illus-cloud-software.svg'
import CentilocDigitalTwin from '../assets/img/illus-digital-twin.svg'

export default () => {
    return (
        <Layout>
            <div className="container">  
                <div className="row">
                    <div className="card-container col text-center"> {/* Items */}
                        <Link to='/item' className='card-button shadow btn btn-light'>
                            <Card title={ "Items" } illus={{ src: CentilocItem, alt : "illus-tag-nfc" }} />
                        </Link>
                    </div>
                    <div className="card-container col text-center"> {/* Boards */}
                        <Link to='/board' className='card-button shadow btn btn-light'>
                            <Card title={ "Boards" } illus={{ src: CentilocShelves, alt : "illus-smart-shelves" }} />
                        </Link>
                    </div>
                    <div className="card-container col text-center"> {/* Gateways */}
                        <Link to='/gateway' className='card-button shadow btn btn-light'>
                            <Card title={ "Gateways" } illus={{ src: CentilocSoftware, alt : "illus-cloud-software" }} />
                        </Link>
                    </div>
                    <div className="card-container col text-center"> {/* Furnitues */}
                        <Link to='/furniture' className='card-button shadow btn btn-light'>
                            <Card title={ "Furnitures" } illus={{ src: CentilocDigitalTwin, alt : "illus-digital-twin" }} />
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

