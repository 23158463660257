import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import { BsFillEyeFill, BsFillTrashFill } from 'react-icons/bs';
import { default as CLIENTS } from "../../api/index";
import { BoardPb, GwPb, ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc';

const TableContent = (props) => {
    const dispatch = useDispatch();

    // Stored datasets
    const permissions   = useSelector(state => state.user.permissions, shallowEqual);
    const boards        = useSelector(state => state.board);
    const furnitures    = useSelector(state => state.furniture);
    const gateways      = useSelector(state => state.gateway);
    const items         = useSelector(state => state.item);

    // Component's state
    const [id, setId] = useState(null);
    const [type, setType] = useState(null);
    const [message, setMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ show: false, message: "", variant: "default"});

    // Manages the display of the Alert component
    const toggleAlert = useCallback(() => setAlertMessage({ show: false, message: "", variant: "default"}))
   
    // Handles the display of the delete modal.
    const toggleModal = useCallback((event, type, id) => {
        setShowModal(!showModal)

        if(event) {
            setId(id);
            setType(type);
            setAlertMessage({ show: false, message: "", variant: "default"});

            switch(props.type) {
                case "board" :
                    setMessage(<span>Are you sure you want to delete the board: <b>{boards.list.find((e) => e.sn === id).sn}</b> ?</span>);
                    break;
                case "gateway" :
                    setMessage(<span>Are you sure you want to delete the gateway: <b>{gateways.list.find((e) => e.id === id).id.sn}</b> of the type <b>{gateways.list.find((e) => e.id === id).id.type}</b> ?</span>);
                    break;
                case "item" :
                    setMessage(<span>Are you sure you want to delete the item: <b>{items.list.find((e) => e.uid === id).uid}</b> ?</span>);
                    break;
                default:
                    console.error("Expected 'board', 'gateway' or 'item' as value but got :", props.type)
                    return;
            }
        }
    }, [boards, gateways, items, showModal, props.type]);

    // Returns data to the parent component from the selected action to define the acion form to display and its target.
    const formAction = useCallback((event) => {
        let action = event.target.nodeName === "SELECT" // Determines which form has to be display by the selected action
            ? event.target.value 
            : event.target.closest("td").querySelector(".form-select").value

        let id = props.type === "gateway" // Retrieves the first cell of the row and then its identifier (as serial number, uid, ...)
            ? JSON.parse(event.target.closest("tr").getElementsByTagName('td')[0].id)
            : event.target.closest("tr").getElementsByTagName('td')[0].id

        props.func({ id: id, action: action })
    }, [props]);
    
    // Sort the current table by renewing the listed dataset
    const sortRow = useCallback((column) => {
        if (props.sortBy.id === column.id) {
            column.desc = !column.desc
            props.setSortBy({id: props.sortBy.id, value: props.sortBy.value, desc: column.desc})
        } else {
            column.desc = false
            props.setSortBy({id: column.id, value: column.value, desc: column.desc})
        }
    }, [props.type, props.sortBy, props.setSortBy, items, boards, furnitures, gateways]);
    
    // Build icons of the `actions` column
    const actionsIcons = useMemo(() => (row) => {
        let identifier, urlParam, serial
        switch (props.type) {
            case "board":
                serial = row.original?.sn
                urlParam = { board_sn: serial }; 
                identifier = { id: serial, sn: serial };
                break
            case "furniture":
                serial = row.original?.id?.sn
                urlParam = { furniture_sn: serial};
                identifier = { id: serial, sn: serial };
                break;
            case "gateway":
                identifier = { id: row.original.id, sn: row.original.id.sn };
                break;
            case "item":
                urlParam = { uid: row.original.uid };
                identifier = { id: row.original.uid, uid: row.original.uid };
                break;
            default: break;
        }

        return <>           
            { props.type !== "gateway" ? // Nullish coalescing operator (??) => While we aren't handling gateways returns a link to a 3D view 
                <Link 
                    to={ `/${props.type}/demo?filters=` + encodeURIComponent(JSON.stringify(urlParam)) } 
                    style={{ color: "#000", cursor: "pointer", display: permissions["geo.gateway.get"] ? "block" : "none" }}
                >
                    <BsFillEyeFill title="Show" size={ 25 } />
                </Link>
            : null }
            <BsFillTrashFill size={ 25 }
                title="Delete" 
                style={{ cursor: "pointer", display: permissions["geo."+props.type+".delete"] ? "block" : "none" }} 
                onClick={(e) => toggleModal(e, props.type, identifier.id)}
            /> 
        </>
    }, [toggleModal, permissions, props.type])

    // Build the actions list allowed per row
    const actionsList = useMemo(() => (status, gwId) => {
        let options = []

        if(props.actions?.length > 0)
            props.actions.forEach((actionOption => {
                let toPush = true

                if(actionOption.value && actionOption.Label && actionOption.exceptions) {
                    actionOption.exceptions.forEach(exception => {
                        switch(exception.attribute) {
                        case "status":  // Compare the status with the exception set to the action (may be restricted)
                            if(status === exception.value) // If the value is equal as the expected exception
                                toPush = false // Then avoid to oush it to the list of the row
                            break;
                        case "gateway":
                            if(exception.value === "notnull" && gwId != null)
                                toPush = false
                            break;
                        }
                    })
                }
                if (toPush) options.push(<option key={ actionOption.value } value={ actionOption.value }>{ actionOption.Label }</option>)
            }))

        if(options.length > 0) {
            return <div className="input-group">
                <select id="actions" name="actions" className="form-select" aria-label="Choose an action" onChange={(e) => formAction(e) } defaultValue={ "" } required>
                    <option value="" disabled>Choose an action...</option>
                    { options }
                </select>
            </div>
        }
    }, [formAction, props.actions])

    return (
        <>
            <div className="px-6 pb-4 d-flex flex-column justify-content-center">
                <div className="shadow responsive-table">
                    {/* Header alert (deletion response, ...) */
                        alertMessage.message && <div 
                            className={`alert alert-${alertMessage.variant} alert-dismissible fade show px-4 rounded-0`} 
                            style={{ marginBottom: 0, borderRadius: 0 }}
                            role="alert"
                        >
                            <span className={"text-uppercase fw-bold"}>{alertMessage.message}</span>
                            <button type="button" className={"btn-close"} data-bs-dismiss="alert" aria-label="Close" onClick={toggleAlert}></button>
                        </div>
                    }
                    
                    {/* COLUMNS Building */}
                    <table {...props.getTableProps()}> 
                        <thead className="bg-secondary bg-opacity-10">
                            { props.headerGroups.map((headerGroup) => (
                                <tr { ...headerGroup.getHeaderGroupProps() }>
                                    { headerGroup.headers.map((column) => (
                                    <th scope="col" className="text-uppercase" { ...column.getHeaderProps() } onClick={() => { if (column.isSortable) sortRow(column) }} > { column.render("Header") }
                                            <span>
                                                { column.isSortable && props.sortBy.id === column.id ? (column.desc ? ' ▼' : ' ▲') : '' }
                                            </span>
                                        </th>
                                    ))}
                                    <th id="actions" scope="col" className="text-uppercase">Actions</th>
                                </tr>
                            )) }
                        </thead>
                        <tbody { ...props.getTableBodyProps() } className="bg-white border-top">
                            { props.page.map((row, i) => { props.prepareRow(row);
                                return <tr {...row.getRowProps()}> 
                                    { row.cells.map((cell, i) => {
                                        return i === 0 
                                            ? <td id={ cell.column.Header === "SN/Type" && props.type === "gateway" ? JSON.stringify(cell.value) : cell.value } className="px-6 py-4 text-nowrap" { ...cell.getCellProps() }>{ cell.render("Cell") }</td>
                                            : <td className="px-6 py-4 text-nowrap" { ...cell.getCellProps() }>{ cell.render("Cell") }</td>
                                    })}
                                    <td className="px-6 py-4 text-nowrap">
                                        { actionsIcons(row) } {/* Icons */}
                                        { actionsList(row.original.status, row.original.gwId) } {/* Actions */}
                                    </td> 
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal show={ showModal } onHide={() => { toggleModal() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body><div className="alert alert-danger">{ message }</div></Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { toggleModal() }}>Cancel</Button>
                    <Button variant="danger" onClick={() => new Promise((resolve, reject) => {
                            switch(type) {
                            case "item":
                                const itemId = new ItemPb.ItemID();
                                itemId.setUid(id)                
                                CLIENTS.geo.item.delete(itemId, dispatch, resolve, reject) // gRPC delete item request
                                break;
                            case "board":
                                const boardId = new BoardPb.BoardID();
                                boardId.setSn(id)          
                                CLIENTS.geo.board.delete(boardId, dispatch, resolve, reject) // gRPC delete board request
                                break;
                            case "gateway":
                                const gwId = new GwPb.GwID();
                                gwId.setType(id.type)
                                gwId.setSn(id.sn)
                                return CLIENTS.geo.gateway.delete(gwId, null, null, dispatch).then((response) => resolve(response)).catch((err) => reject(err))
                            default: break;
                            }
                        }).then(() => setAlertMessage({
                            show: true, 
                            message : `The ${type} has been deleted successfully.`,
                            variant : "success" 
                        })).catch(err => setAlertMessage({
                            show: true, 
                            message : `The ${type} hasn't been deleted. ${err.message}`,
                            variant : "danger" 
                        })).finally(() => toggleModal())
                    }>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TableContent;