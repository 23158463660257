import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const RFTypes = {
    0: "NFC Forum tag type 2", // RFID_TYPE_A
    1: "NFC Forum tag type 5", // RFID_TYPE_V
    2: "Alternative tag type", // RFID_TYPE_ALT (alternating A and V)
    3: "First tag type found", // RFID_TYPE_FIRST (A or V in function of the first tag found)
}

const RFType = (props) => {
    const dispatch = useDispatch();
    
    // Creates state form's fields
    const [fields, setFields] = useState({...{
        sn : "",
        rfType : 0
    }, sn: props.sn || "" });
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn }))
    }, [props.sn])

    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: name === "rfType" 
            ? Number(value) 
            : value 
        }));
    };

    return (
        <Form
            title={"Set RF Type"}
            fields={{...fields, sn: props.sn}}
            toggle={
                useCallback(() => { /* RFType */
                    const arg = new BoardPb.BoardRFType();
                    arg.setSn(fields.sn)
                    arg.setRfType(fields.rfType)
                    
                    // Sends a request to the board for setting its RF type.
                    return new Promise((resolve, reject) => CLIENTS.geo.board.setRFType(arg, dispatch, resolve, reject))
                })
            }
            setFields={setFields}
            // reboot={true}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-10 mb-4">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="sn" type="text" value={fields.sn}
                            className="form-control w-auto"
                            readOnly="readonly"
                            required 
                        />
                    </div>
                    <small id="serial_numberHelp" className="form-text text-muted">Board serial number</small>
                 </div>
                 <div className="d-flex align-items-center">
                    <div className="col-sm-6">
                        <select id="rf_type" name="rfType" value={fields.rfType}
                            onChange={handleChange}
                            className="form-control form-select w-100"
                        >
                            {/* RF types mapping to build options */
                                Object.entries(BoardPb.RFType).map(([key, val]) => (
                                    <option key={key} value={val}>{RFTypes[val]}</option>
                                ))
                            }
                        </select>
                        <small id="rfTypeHelp" className="form-text text-muted">RF type</small>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default RFType;