import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    type: ""
}

const Ping = (props) => {
    const dispatch = useDispatch();
    
    // Creates state form's fields
    const [{ sn, type }, setFields] = useState({...initialFields, sn: props.sn, type: props.type});
    
    // Trig the props' changes to update the form's fields
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn, type: props.type }))
    }, [props.sn, props.type])

    return (
        <Form
            title={"Ping"}
            fields={{...initialFields, sn: props.sn, type: props.type}}
            toggle={
                useCallback(() => { /* Ping */
                    const gwId = new GwPb.GwID();
                    gwId.setSn(sn)
                    gwId.setType(type)
                    
                    // Sends a request to the gw for getting connection state.
                    return CLIENTS.geo.gateway.ping(gwId, null, null, dispatch)
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-start align-items-center">
                            <input id="gw_type" name="type" type="text" value={type}
                                className="form-control w-75"
                                readOnly="readonly"
                                required
                            />
                        </div>
                        <small id="serial_numberHelp" className="form-text text-muted">Gateway type</small>
                    </div>
                    <div className="col-sm-6">
                        <input id="gw_serial_number" name="sn" type="text" value={sn}
                            className="form-control w-75"
                            readOnly="readonly"
                            required
                        />
                        <small id="ssidHelp" className="form-text text-muted">Gateway serial number</small>
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default Ping;