import '@babylonjs/loaders'

import { Color3 } from "@babylonjs/core/Maths/math.color"
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

// Open-front bin (bac à bec) three-dimensional model.
export class BinModel extends AbstractModel {
    dimensions = new Vector3(0.55, 0.55, 0.55);
    rotation   = new Vector3(0, 180 * (Math.PI / 180), 0)
    offsets    = new Vector3(-68, 0, 0)
    heightMm   = 10

    buildInternalMesh(scene) {
        return new Promise((resolve, reject) => {
            SceneLoader.ImportMeshAsync("", "/models/bin/", "bin.obj", scene)
            .then((data) => {
                const meshes = data.meshes;
    
                // Remove the unused imported elements along its whole
                // meshes[0].dispose() /!\ disturb the mesh display
                meshes[2].dispose();
                meshes[3].dispose();
    
                this.mesh = meshes[1];
                this.mesh.material = new StandardMaterial("bin"); // Bin texture
                this.mesh.material.diffuseColor = new Color3(230 / 255, 126 / 255, 34 / 255);
    
                resolve(this);
            })
            .catch((error) => { // Handle any errors that occurred during the asynchronous operation
                console.error("Error loading meshes:", error);
                reject(error);
            });
        });
    }
}

export class RotatedBinModel extends AbstractModel {
    dimensions = new Vector3(10, 10, 10);

    meshName;
    setMeshName(meshName) { // cheat because MergeMeshes does not work with this glb
        this.meshName = meshName
    }
    buildInternalMesh(scene) {
        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/bin/", "rotated_bin.glb", scene);
            data.meshes.forEach((mesh) => mesh.name = this.meshName)

            resolve({ mesh: data.meshes[0] })
        })
    }
}
