import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from '../redux/root.reducer'; // Import the RootState from your Redux store
import { getAllItems } from "../redux/actions/item.actions";
import { StatusPill } from "../containers/Table";
import { CreateForm, SetURL, UpdateTenant } from "../containers/forms/item";
import { default as Gangplank } from "../components/Gangplank.jsx";
import { column } from "../types/interfaces";

// Data defining a filter for items
const filters = [
    { Name: "UID", type: "text", value: "UID" },
    { Name: "Item Status", type: "status", value: "ITEMSTATUS" },
    { Name: "Last move after", type: "date", value: "MOMENTUM" },
    { Name: "Board SN", type: "date", value: "BOARDSN" }
];

// Define type for the table columns content settings
const columns: column[] = [
    { Header: "UID", accessor: "uid", value: "UID", isSortable: true },
    { Header: "Item Status", accessor: "status", value: "ITEMSTATUS", isSortable: true, Cell: StatusPill },
    { Header: "Position {x, y}", accessor: "posMmList", isSortable: false, Cell: (props: { 
            cell: { value: string[]; row: { 
                values: { status: string } 
            }} 
        }) => {
            const posMm = props.cell.value.length === 2 ? props.cell.value : "";

            if (props.cell.row.values.status === "OUT") {
                return ""; // Empty positioning on 'OUT' item
            } else {
                return posMm.length === 2 ? (
                    <span>{`{ ${posMm[0]}, ${posMm[1]} }`}</span>
                ) : (
                    <span>{posMm}</span>
                );
            }
        }
    },
    { Header: "Board SN", accessor: "boardSn", value: "BOARDSN", isSortable: true, Cell: ({ value }: { value: string }) => (
            // Link redirecting to "/board" and filtering the table by board SN
            <Link to={`/board?filters=${
                encodeURIComponent(JSON.stringify({ 
                    filter: "SN",
                    value 
                }))
            }`} style={{ cursor: "pointer" }}>{value}</Link>
        )
    }
];

const ItemList: React.FC = () => {
    // Returns the reference to the dispatch function from the redux store. May be useful to dispatch actions
    const dispatch = useDispatch();

    // TODO:
    const items       = useSelector((state: RootState) => state.item);
    const permissions = useSelector((state: RootState) => state.user.permissions, shallowEqual);

    // TODO:
    const [actions, setActions] = useState<any[]>([]);

    // TODO:
    useEffect(() => {
        const actionsList: any[] = [];

        permissions["geo.item.setURL"] &&
        actionsList.push({
            Label: "Set URL",
            value: "set_url",
            form: (itemUid: string, redirectURL: string) => {
                return <SetURL uid={itemUid} url={redirectURL} />;
            }
        });

        if (
            permissions["admin.tenant.updateItem"] === true &&
            window.location.origin !== "file://"
        ) {
            actionsList.push({
                Label: "Change tenant",
                value: "tenant",
                form: (itemUid: string) => {
                    return <UpdateTenant uid={itemUid} />;
                }
            });
        }

        if (JSON.stringify(actions) !== JSON.stringify(actionsList)) {
            setActions(actionsList);
        }
    }, [dispatch, permissions, actions]);

    return (
        <Gangplank
            type={"item"}
            getAll={getAllItems}
            payload={items}
            columns={columns}
            filters={filters}
            actions={actions}
            createForm={<CreateForm />}
        />
    );
};

export default ItemList;
