import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { Form } from "../../../components/form/index.js"

import '../../../assets/style/layout/form.css'
import * as types from '../../../redux/actions.types.js'

const initialFields = {
    sn : "",
    type : ""
}

const Create = () => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ sn, type }, setFields] = useState(initialFields);

    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: value }));
    };

    return (
        <Form
            title={"Create"}
            response={"The gateway has been created successfully"}
            fields={initialFields}
            toggle={
                useCallback(() => { // Creates a new gateway.
                    const arg = new GwPb.GwCreation();
                    arg.setSn(sn)
                    arg.setType(type)
            
                    return CLIENTS.geo.gateway.create(arg, dispatch /* EDIT: nest a GetAll() | dispatch */)
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-start align-items-center">
                            <input id="gw_type" name="type" type="text" value={type}
                                onChange={handleChange}
                                className="form-control w-75"
                                required
                            />
                        </div>
                        <small id="serial_numberHelp" className="form-text text-muted">Gateway type</small>
                    </div>
                    <div className="col-sm-6">
                        <input id="gw_serial_number" name="sn" type="text" value={sn}
                            onChange={handleChange}
                            className="form-control w-75"
                            required
                        />
                        <small id="ssidHelp" className="form-text text-muted">Gateway serial number</small>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default Create;