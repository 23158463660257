import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    dimX : "",
    dimY : "",
    marginX : "",
    marginY : "",
    noGeoloc : false,
}

const Update = (props) => {
    const dispatch = useDispatch();

    // Updates state form's fields
    const [{ sn, dimX, dimY, marginX, marginY, noGeoloc }, setFields] = useState({ ...initialFields, ...props });
    
    // Trig the props' changes to update the form's field
    useEffect(() => setFields(fields => ({ ...props, ...fields })), [props.sn])

    // Changes the input value
    const handleChange = e => {
        const { name, value, checked } = e.target;
        setFields(fields => ({ ...fields, [name]: name == "noGeoloc" ? checked : value }));
    };

    return (
        <Form
            title={"Update"}
            response={"The board has been updated with success"}
            fields={{ sn, dimX, dimY, marginX, marginY, noGeoloc }}
            toggle={
                useCallback(() => {
                    const arg = new BoardPb.BoardUpdate();
                    arg.setSn(sn)
            
                    // dimensions_mm
                    const dimOp = new BoardPb.OpIntArray()
                    if (dimX > 0 && dimY > 0) {
                        dimOp.setValueList([parseInt(dimX), parseInt(dimY)])
                        dimOp.setOp(Object.entries(BoardPb.Action).find(([action, _]) => action === "SET")[1]) // SET
                    } else {
                        dimOp.setOp(Object.entries(BoardPb.Action).find(([action, _]) => action === "CLEAR")[1]) // CLEAR
                    }
                    arg.setDimensionsMm(dimOp)
            
                    // margins_mm
                    const marginOp = new BoardPb.OpIntArray()
                    if (marginX > 0 && marginY > 0) {
                        marginOp.setValueList([parseInt(marginX), parseInt(marginY)])
                        marginOp.setOp(Object.entries(BoardPb.Action).find(([action, _]) => action === "SET")[1]) // SET
                    } else {
                        marginOp.setOp(Object.entries(BoardPb.Action).find(([action, _]) => action === "CLEAR")[1]) // CLEAR
                    }
                    arg.setMarginsMm(marginOp)
            
                    // no_geoloc
                    const opBool = new BoardPb.OpBool()
                    if (noGeoloc) {
                        opBool.setValue(noGeoloc)
                        opBool.setOp(Object.entries(BoardPb.Action).find(([action, _]) => action === "SET")[1]) // SET
                    } else {
                        opBool.setOp(Object.entries(BoardPb.Action).find(([action, _]) => action === "CLEAR")[1]) // CLEAR
                    }
                    arg.setNoGeoloc(opBool)
                    
                    // Sends a request to update an existing board.
                    return new Promise((resolve, reject) => CLIENTS.geo.board.update(arg, dispatch, resolve, reject))
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-6">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="sn" type="text" value={sn}
                            className="form-control w-75"
                            readOnly="readonly"
                            required
                        />
                    </div>
                    <small id="serial_numberHelp" className="form-text text-muted">Board serial number</small>
                </div>

                {/* Dimensions list */}
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <input name="dimX" id="dimX" type="number" min="0" step="50" value={dimX} required={!dimY || dimY.length === 0 || isNaN(dimY) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="dimXHelp" className="form-text text-muted">Width (mm)</small>
                    </div>
                    <div className="col-sm-6">
                        <input name="dimY" id="dimY" type="number" min="0" step="50" value={dimY} required={!dimX || dimX.length === 0 || isNaN(dimX) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="dimYHelp" className="form-text text-muted">Height (mm)</small>
                    </div>
                </div>

                {/* Margins list */}
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <input name="marginX" id="marginX" type="number" min="0" step="1" value={marginX} required={!marginY || marginY.length === 0 || isNaN(marginY) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="marginXHelp" className="form-text text-muted">Marge X (mm)</small>
                    </div>
                    <div className="col-sm-6">
                        <input name="marginY" id="marginY" type="number" min="0" step="1" value={marginY} required={!marginX || marginX.length === 0 || isNaN(marginX) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="marginYHelp" className="form-text text-muted">Marge Y (mm)</small>
                    </div>
                </div>

                {/* No geolocation */}
                <div className="form-check pt-3">
                    <input id="update-no-geoloc" name="noGeoloc" type="checkbox" value={noGeoloc} 
                        checked={noGeoloc} 
                        onChange={handleChange}
                        className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="update-no-geoloc">Disable geolocation ?</label>
                </div>
                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default Update;