import '@babylonjs/loaders'

import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

/**
 * @name PotModel
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
class PotModel extends AbstractModel {
    dimensions = new Vector3(30, 30, 30);
    heightMm = 50

    buildInternalMesh(scene) {
        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/pot/", "pot.obj", scene);
            const meshes = data.meshes;

            const materialHat = new StandardMaterial("pot_hat");
            materialHat.diffuseColor = new Color3(0.2, 0.2, 0.2);
            const materialGlass = new StandardMaterial("pot_glass");
            materialGlass.diffuseColor = new Color3(1, 1, 1);

            meshes[0].material = materialHat;
            meshes[1].material = materialGlass;

            this.mesh = Mesh.MergeMeshes(meshes, true, true, undefined, false, true);

            resolve(this)
        })
    }
}

export default PotModel;
