import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    type: ""
}

const FindAndEnrolBoard = (props) => {
    const dispatch = useDispatch();

    const fields = {...initialFields, ...props}
    
    // Creates state form's fields
    const [{ sn, type }, setFields] = useState({...initialFields, ...props});
    
    // Trig the props' changes to update the form's fields
    useEffect(() => setFields(fields => ({ ...fields, ...props })), [props])
    
    // State of new enroled boards
    const [boards, setBoards] = useState([]);

    // Referred response message
    const resp = useRef("")

    return (
        <Form
            title={"Find and enrol board"}
            response={ resp.current }
            fields={fields}
            toggle={
                useCallback(async () => { /* Find and enrol Board */
                    const gwId = new GwPb.GwID();
                    gwId.setSn(sn)
                    gwId.setType(type)
                    
                    return CLIENTS.geo.gateway.findAndEnrolBoard(gwId, null, null, dispatch).then((response) => {
                        if(!boards.find((board) => board.boardSn === (response.toObject().boardSn.length > 0 ? response.toObject().boardSn : sn))) {
                            if (response.toObject().requestStatus.code === 0) {
                                resp.current = "Command succeed"
                                setBoards([...boards, response.toObject()])
                            } else {
                                resp.current = response.toObject().requestStatus.message
                            }
                        } else {
                            if (response.toObject().requestStatus.code === 0) {
                                resp.current = "Board already enroled"
                            } else {
                                resp.current = response.toObject().requestStatus.message
                            }
                        }
                    })
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="d-flex align-items-center pt-3">
                    <div className={(boards.length > 0 ? "col-sm-6" : "col-sm-12") + "pt-0"}  style={{ top: 0 }}>
                        <p style={{ whiteSpace: "normal" }}>Please put the token on the board to enrol,<br/> then click on :</p>
                        <div className="align-items-end pr-2">
                            <button type="submit" style={{ marginTop: 0 }} className="btn btn-outline-secondary pb-2">
                                <span className="d-none d-lg-inline">Find</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6" style={{ maxHeight: "100%", display: boards.length !== 0 ? "block" : "none" }}>
                        <div className="tab-content">
                            <div className="table-responsive align-items-baseline" rows="3">
                                <table id="action-table" valign="middle">
                                    <tbody>
                                        {
                                            boards.map((row, i) => {
                                                return <tr key={ row.boardSn } id={ row.boardSn }>
                                                    <td>
                                                        <div className="accordion accordion-flush" id={"accordionFlush_" + i}>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id={"flush-heading_" + i}>
                                                                    <button className="accordion-button collapsed d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse_" + i} aria-expanded="false" aria-controls={"flush-collapse_" + i}>
                                                                        { row.requestStatus.code === 0 
                                                                            ? <BsFillCheckCircleFill color="green" size="20" style={{ marginRight: "15px" }} /> 
                                                                            : <BsFillXCircleFill color="red" size="20" style={{ marginRight: "15px" }} /> 
                                                                        }{ row.boardSn }
                                                                    </button>
                                                                </h2>
                                                                <div id={"flush-collapse_" + i} className="accordion-collapse collapse" aria-labelledby={"flush-heading_" + i} data-bs-parent={"#accordionFlush_" + i}>
                                                                    <div className="accordion-body">{ row.requestStatus.message }</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="align-items-center pt-0">
                            <small id="sample-selectHelp" className="form-text text-muted">Board(s) list</small>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default FindAndEnrolBoard;