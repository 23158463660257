import clients from "../ClientFactory"
import { default as ApiManager } from '../ApiManager'
import { FurnitureWeb, FurnitureId, FurnitureFilters } from '@centiloc/centiloc-ops-api-inventory-grpc'

class FurnitureService extends ApiManager {
    private static instance: FurnitureService | null = null;
    public client: FurnitureWeb.FurnitureServiceClient;

    private constructor() {
        super();
        
        if (!FurnitureService.instance) { // If the instance doesn't exist, set it to the current instance
            if (!this.client) {
                if(!(clients.getFurnitureServiceInventory() instanceof FurnitureWeb.FurnitureServiceClient)) {
                    throw new Error("Failed to instantiate the client of the service 'Furniture' of `inventory`");
                }
        
                this.client = clients.getFurnitureServiceInventory()
            }
        }
    }

    public static getInstance(): FurnitureService {
        if (!FurnitureService.instance) {
            FurnitureService.instance = new FurnitureService();
        }
        return FurnitureService.instance;
    }

    /**
     * Get returns the data related to a furniture.
     *
     * @param furnitureId - The ID of the furniture to fetch.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with furniture data or rejects with an error. 
     */
    async get(
        furnitureId: FurnitureId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => FurnitureService.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("furniture_sn", furnitureId.getSn());
                })
            }

            // Request the API
            FurnitureService.callAPI(this.client.get.bind(this.client),
                furnitureId,
                callback,
                dispatch
            );
        });
    }

    /**
     * GetAll returns all furnitures corresponding to the filters.
     *
     * @param arg - Filters and pagination options.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with a list of furniture data or rejects with an error.
     */
    async getAll(
        arg: FurnitureFilters,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => FurnitureService.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("filters", arg.getFiltersList());
                })
            }
            
            // Request the API
            FurnitureService.callAPI(this.client.getAll.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * GetItems returns the list of all the items in the furniture
     *
     * @param furnitureId - The ID of the furniture targetted.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with a list of furniture data or rejects with an error.
     */
    getItems(
        arg: FurnitureId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => FurnitureService.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("sn", arg.getFurnitureSn());
                })
            }

            FurnitureService.callAPI( // Request the API
                this.client.getItems.bind(this.client),
                arg,
                callback,
                dispatch
            );
        });
    }
}

export default FurnitureService.getInstance()