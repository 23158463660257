import * as types from '../actions.types'

/**
 * @constant INITIAL_STATE
 * 
 * Initial furnitures state structure.
 */
const INITIAL_STATE = {
    list : [],
    links: {first: undefined, previous: undefined, next: undefined, last: undefined},
    pagination: {size: 25, page: 0, desc: false, sort: 0},
    count: 0
}

/**
 * @constant reducer
 * @param { initial gateway state } state
 * @param { action } action Actions are the only source of information for the store
 * @returns state
 * 
 * Build the furnitures state.
 */
const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {

        /** FURNITURES ACTIONS **/
        case types.GET_FURNITURE_SUCCESS : // Succeed Furniture.get action result
            if(!state.list.find(furniture => furniture.id === action.payload.id)) {
                return { // While none dupplication, juste concatenate the store with the returned payload
                    ...state, list: state.list.concat(action.payload)
                }
            } else {
                return { // Concatenate the previous state with the returned payload and replace same occurencies by the new returned dataset
                    ...state, list: [...state.list.filter((furniture) => furniture.sn !== action.payload.sn), action.payload]
                }
            }
        case types.GET_ALL_FURNITURE_SUCCESS : // Succeed Furniture.getAll action result
            return {
                list:   action.payload.furnituresList,
                links:      { ...state.links, ...action.payload.links },
                pagination: { ...state.pagination, ...action.payload.pagination },
                count:  !isNaN(action.payload.count) ? action.payload.count : 0
            }
        default :
            return { ...state }
    }
}

export { reducer }