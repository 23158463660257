import Gateway from '../../api/geo/Gateway'
import * as types from '../actions.types'

import { formatFilters } from "../../shared/utils";
import { Dispatch } from 'redux';
import { GwPb } from '@centiloc/centiloc-ops-api-inventory-grpc'

export const getGateway = (sn: string, type: string) => async (dispatch: Dispatch) => { // TODO: 
    const id = new GwPb.GwID(); // Create a new GwID
    id.setSn(sn) // using the provided SN
    id.setType(type) // using the provided TYPE

    try { // Make an API call to get gateway by ID
        const response = await Gateway.get(id, dispatch);

        // Dispatch a success action with the response data
        dispatch({
            type: types.GET_GW_SUCCESS,
            payload: response.toObject(),
        });
    } catch (error) {
        dispatch({ // Dispatch a failure action in case of an error
            type: types.GET_GW_FAILURE,
            payload: null,
            error,
        });
    }
};

export const deleteGateway = (sn: string, type: string) => async (dispatch: Dispatch) => { // TODO: 
    const id = new GwPb.GwID(); // Create a new GwID
    id.setSn(sn) // using the provided SN
    id.setType(type) // using the provided TYPE

    try { // Make an API call to get gateway by ID
        const response = await Gateway.get(id, dispatch);

        // Dispatch a success action with the response data
        dispatch({
            type: types.DELETE_GW_SUCCESS,
            payload: response.toObject(),
        });
    } catch (error) {
        dispatch({ // Dispatch a failure action in case of an error
            type: types.DELETE_GW_FAILURE,
            payload: null,
            error,
        });
    }
};

export const getAllGateway = (filters: {}) => async (dispatch: Dispatch) => {
    const arg = formatFilters(filters, "gateway") // Format the filters as needed

    Gateway.getAll(arg, dispatch)
    .then((response) => {
        dispatch({ type: types.GET_ALL_GW_SUCCESS, payload: {...response.toObject(), pagination: {
            size: arg.getPage().getLimit(),
            page: arg.getPage().getPage(),
            desc: arg.getPage().getIsDescending(),
            sort: arg.getPage().getSort(),
        }}}
    )})
    .catch((err) => dispatch(
        { type: types.GET_ALL_GW_FAILURE, payload: null, err }
    ))
};
  
  