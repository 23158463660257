
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux"
import { Modal, Button } from "react-bootstrap";
import { Watch } from "react-loader-spinner";

import { default as CLIENTS } from "../../api/index"
import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { getClient, captureException } from "@sentry/react";

const Form = (props) => {
    const dispatch = useDispatch();

    // Sets up an initial state to manage the HTML form input values and visual tools depending on its lifecycle state
    const [alerting, setAlerting]   = useState(false);
    const [loading, setLoading]     = useState(false);
    const [variant, setVariant]     = useState(null);
    const [booting, setBooting]     = useState(null);
    const [response, setResponse]   = useState(props.response);
    const [showModal, setShowModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(null);

    // Manages the display of the Alert component
    const toggleAlert = useCallback(() => setAlerting(!alerting))

    // Resets the inputs values of the form
    const clearForm = () => props.setFields(props.fields);

    // Sets up an initial state to manage the HTML form input values and visual tools depending on its lifecycle state
    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }

        // Resets/hide the Alert banner
        setAlerting(false)

        try {
            if (props.reboot) {
                setShowModal(true);

                await new Promise((resolve, reject) => {

                    // When the user clicks "Confirm" in the modal
                    const handleConfirm = () => { // Useful to continue through the expected request
                        resolve("confirmed");
                    };

                    // When the user clicks "Cancel" in the modal
                    const handleCancel = () => { // Useful to reach the "catch" block of the current statement
                        reject("cancelled");
                    };

                    // Set the visual content to render as confirmation modal
                    setConfirmationModal(
                        <Modal show={true}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Configuration Changes</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>You are about to apply configuration changes.</p>
                                <p>Applying any changes will require the device to be <b>rebooted</b>.</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                                <Button variant="primary" onClick={handleConfirm}>Apply & Reboot</Button>
                            </Modal.Footer>
                        </Modal>
                    )
                });
            }
            setShowModal(false); // Hide the confirm config changes modal
            setLoading(true); // Display the spinner

            props.toggle(event).then((response) => { // Set values from the query, the alert level as succeed and hide the spinner
                setResponse(props.response ? props.response : response && typeof response?.toObject() == "object"
                    ? response.toObject().message
                    : "Command succeed"
                )
                setVariant("success")
                setLoading(false)
                setAlerting(true)

                if (response?.toObject().status === 0) {
                    clearForm() // Clean the form values - so all states - on a succeed operation

                    if (props.reboot) {
                        const id = new BoardPb.BoardID();
                        id.setSn(props.fields.sn)

                        setBooting(true) // TODO:

                        // Board reboot after setting config
                        return new Promise((resolve, reject) => CLIENTS.geo.board.reboot(id, dispatch, resolve, reject).then(() => setBooting(false)))
                    }
                }
            }).catch(err => { // Set values from the query, the alert level as failed and hide the spinner
                console.error(err)

                setResponse(err.message)
                setVariant("danger")
                setLoading(false)
                setAlerting(true)
            });
        } catch (err) {
            if (props.reboot && err === "cancelled") {
                setShowModal(false); // Hide the confirm config changes modal
                return
            }

            const syntaxError = (err.name == "SyntaxError")

            // Set values from the query, the alert level as failed and hide the spinner
            setResponse(err?.message ? err.message : err)
            setVariant(syntaxError ? "warning" : "danger");
            setAlerting(true);
            setLoading(false);

            if (!syntaxError && getClient()?.getOptions()?.enabled) {
                captureException(err)
            }
            console.error(err)
        }
    }

    const toggleResponse = useMemo(() => {
        if (alerting && response !== null) {
            return <div className={`alert alert-${variant} alert-dismissible fade show px-4 rounded-0`} role="alert">
                <span className={"text-uppercase fw-bold text-wrap"}>{response}</span>
                <button type="button" className={"btn-close"} data-bs-dismiss="alert" aria-label="Close" onClick={toggleAlert}></button>

                {/* On boards configuration changes */}
                { booting && <p><br/>Reboot in progress...</p> } {/* TODO: Reboot progress and signal on finish ? */}
            </div>
        }
    }, [booting, alerting, response])

    const showConfirmationModal = useMemo(() => {
        if (showModal) {
            return confirmationModal
        }
    }, [showModal, confirmationModal])

    return (
        <form className="action form-horizontal" onSubmit={handleSubmit} >
            <div className="form-title d-flex align-items-center justify-content-between bg-secondary bg-opacity-10">
                <span className="text-uppercase fw-bold mx-4">{props.title}</span>

                {/* Loader/SPINNER */}
                <Watch color="#00BFFF" height={"30"} visible={loading} /> 
            </div>

            {/* Response message Alert */}
            {toggleResponse}

            {props.goto !== undefined && props.goto.length !== 0 ?
                <>
                    {props.goto.map(val => (
                        <div className="form-group mx-4 mt-3 mb-2 d-flex justify-content-center" key={val}>
                            <button type="submit" value={val} style={{width: '150px'}} className="mx-4 btn btn-primary" onClick={() => props.setFocus("gotoForm")}>Go to {val}</button>
                        </div>
                    ))}
                    <div className="form-group mt-3">
                        <div className="d-flex justify-content-center"> OR set manually </div>
                    </div>
                </>
            : null}

            {/* Inner HTML content */}
            {props.children}

            {/* Confirm configuration Modal */}
            {showConfirmationModal}
        </form>
    )
}

export default Form;