import { getClient, captureException }from "@sentry/react";

import Keycloak from "keycloak-js";

const _kc = new Keycloak({ url: window.KEYCLOAK_URL + "/auth/", realm: window.KEYCLOAK_REALM, clientId: "centui" });

// Initiates the Keycloak login process.
const doLogin = _kc.login;

// Initiates the Keycloak logout process.
const doLogout = _kc.logout;

// Retrieves the current initiated client for the Keycloak authentication server.
const get = () => _kc;

// Retrieves the current Keycloak access token.
const getToken = () => _kc.token;

// Checks if the user is currently authenticated.
const isLoggedIn = () => !!_kc.token;

// Returns true if the token has less than `minValidity` seconds left before it expires.
const isExpired = () => _kc.isTokenExpired();

// Retrieves the username from the Keycloak token.
const getUsername = () => _kc.tokenParsed?.preferred_username;

// Retrieves the first name from the Keycloak token.
const getFirstname = () => _kc.tokenParsed?.given_name;

// Checks if the user has any of the specified roles.
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

// Initializes Keycloak with the provided configuration and handles authentication callbacks.
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'login-required',
        promiseType: 'native'
    }).then(() => onAuthenticatedCallback(true)).catch((error) => {
        console.error("Error is thrown while trying to initialize KEYCLOAK.", error)

        // TODO: 
        // dispatch type.USER_LOGIN_FAILURE

        if (getClient()?.getOptions()?.enabled) {
            captureException(error)
        }
    });
};

// updateToken refreshes or updates the access token obtained from the Keycloak server.
const updateToken = async() => {
    return _kc.updateToken(30).catch((err) => {
        console.error("Error is thrown while trying to refresh the access token.", err)

        if (getClient()?.getOptions()?.enabled) {
            captureException(err)
        }
    });
}

export default {
    get, 
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    isExpired,
    getToken,
    getUsername,
    getFirstname,
    hasRole,
    updateToken,
};