import React, { createContext, useContext, ReactNode, useState } from 'react';

interface RenderContextType {
    isTrial: boolean;
    setTrialStatus: (status: boolean) => void;
}

const RenderContext = createContext<RenderContextType | undefined>(undefined);

interface RenderProviderProps {
    children: ReactNode;
}

export const RenderProvider: React.FC<RenderProviderProps> = ({ children }) => {
    const [isTrial, setIsTrial] = useState(false);

    const setTrialStatus = (status: boolean) => {
        setIsTrial(status);
    };

    const value: RenderContextType = {
        isTrial,
        setTrialStatus,
    };

    return (
        <RenderContext.Provider value={value}>
            {children}
        </RenderContext.Provider>
    );
};

export const useRenderContext = (): RenderContextType => {
    const context = useContext(RenderContext);

    if (!context) {
        throw new Error('useRenderContext must be used within a RenderProvider');
    }

    return context;
};
