import ModelFactory from "./ModelFactory";

import { Animation } from "@babylonjs/core/Animations/animation"
import { ActionManager } from "@babylonjs/core/Actions/actionManager"
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { Vector3 } from "@babylonjs/core/Maths/math";


class Item {
    _gaugeModel = null;
    
    constructor(scene, apiItem, level, serial) {
        this._scene     = scene // current three-dimensional scene
        this._uid       = apiItem.uid // own Item identifier
        this._pos_mm    = apiItem.posMmList // Item's position
        this._level     = level // fill-level value
        this._blister   = false // is the item hookable ?
        
        this.serial = serial // surface's serial number where was triggered the Item (and referred as the parent in the scene)
        this.isIn = true
        this.isVisible = false
        this.eventPending = true
        this.eventsWaiting = []

        this.ModelFactory = ModelFactory.getInstance();
    }

    // function for create or updating of meshes positions
    createOrMoveItem (resolve, updateHPick, itemUI, retItem, serial, target) {
        if (itemUI.isVisible) { // Item already created, move it
            this.Update(retItem.posMmList, serial, retItem.fillRatio)

            // No need to wait promise because no update of counter/option/highlight
            return resolve()
        }

        // Item not created yet
        this.ModelFactory.getItemModel(retItem).then((resolved) => {
            this.SetModel(resolved) // Show the item UI

            // Highlight the product if it's the current target (selected)
            this.Create().then(() => {
                if (target && this._scene.effectLayers.length > 0) {
                    this._scene.effectLayers.forEach(layer => layer.name === "HL" && updateHPick(this._scene, retItem.uid, itemUI, layer));
                }

                return resolve()
            })
        })
    }

    Create() {
        return new Promise((resolve) => {
            this.objModel.getMesh(this._scene).then((resolved) => { // Get mesh from the model and apply animations and positions
                this._mesh = resolved.mesh
                this._blister = resolved.hookable
                this.updateMesh(this._mesh, this.objModel)
                this._mesh.isPickable = true; // Trigger the double tap evenment
                this._mesh.actionManager = new ActionManager(this._scene) // initialized custom animation (growth effect on an onClick event)
                this._mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev) { /* useful to pick the board and put a pointer cursor  */ }));
                this.fadeInOrOut(this._mesh, true)
                this.isVisible = true
                this.createGauge()

                return resolve() 
            })
        })
    }

    Update(posMm, serial, fillRatio) {
        const hasMoved = (posMm[0] != this._pos_mm[0] || posMm[1] != this._pos_mm[1] || serial != this.serial)
        const levelChanged = (fillRatio != this._level)

        if (hasMoved) {
            this._pos_mm = posMm // Updated position
            this.serial = serial // Updated surface where's tiggered the event

            this.fadeInOrOut(this._mesh, false).then(() => {
                this.setPositionPlusOffset(this._mesh, this.objModel.offsets)
                this.fadeInOrOut(this._mesh, true)
            })
        }

        if (levelChanged) {
            this._level = fillRatio
            this.fadeInOrOut(this._gaugeMesh, false, true).then(() => {
                this.createGauge()
            })
        } else if (hasMoved && this._gaugeMesh) {
            this.fadeInOrOut(this._gaugeMesh, false).then(() => {
                this.setPositionPlusOffset(this._gaugeMesh, this._gaugeModel.offsets)
                this._gaugeMesh.position.y += this.objModel.heightMm;
                this.fadeInOrOut(this._gaugeMesh, true)
            })
        }
    }

    Delete() {
        this.fadeInOrOut(this._mesh, false, true)
        this.fadeInOrOut(this._gaugeMesh, false, true)
        this.isVisible = false
    }

    SetModel(objModel) {
        this._blister    = objModel.hookable
        this.objModel    = objModel
        this.productName = objModel.name
    }

    createGauge() { // separate because the gauge can change but not the obj
        if (this._level >= 0) {
            if (this._gaugeModel == null) {
                this._gaugeModel = this.ModelFactory.getModel("gauge")
            }

            if (this._gaugeModel.levelPercent == this.level) return

            this._gaugeModel.levelPercent = this._level
            this._gaugeModel.getMesh(this._scene).then((resolved) => {
                this._gaugeMesh  = resolved.mesh
                this.updateMesh(this._gaugeMesh, this._gaugeModel)
                this._gaugeMesh.position.y += this.objModel.heightMm;
                this.fadeInOrOut(this._gaugeMesh, true)
            })
        }
    }

    updateMesh(mesh, model) {
        this.setPositionPlusOffset(mesh, model.offsets)
        mesh.visibility = 0
        mesh.name       = this._uid
        mesh.scaling    = model.dimensions;
        mesh.rotation   = model.rotation;
    }

    fadeInOrOut(mesh, show, out=false) {
        return new Promise((resolve) => {
            if (mesh == null) return resolve()

            let anim, begin, end;
            if (show) {
                anim = "show"; begin = 0; end = 1;
            } else {
                anim = "hide"; begin = 1; end = 0;
            }
            Animation.CreateAndStartAnimation(anim, mesh, "visibility", 140, 30, begin, end, 0, null, function() {
                if (!show && out) {
                    mesh.dispose()
                    mesh = null
                }
                return resolve()
            })
        })
    }

    getPositionOnParent(mesh) {
        const surface = this._scene.getMeshByName(this.serial)
        const sizes   = surface.getHierarchyBoundingVectors()
        const meshSz  = new Vector3(sizes.max.x - sizes.min.x, sizes.max.y - sizes.min.y, sizes.max.z - sizes.min.z)

        if (!this._blister) {
            mesh.parent = surface
            
            return new Vector3(
                this._pos_mm[0] - surface.metadata.dimension.x / 2, /* item's position on X axis minus surface's width */
                surface.position.y - sizes.min.y,
                this._pos_mm[1] - surface.metadata.dimension.y / 2, /* item's position on Y axis (== BABYLON.js Z axis) minus surface's depth (== CENTILOC Y axis) */
            )
        } else {
            return new Vector3(surface.position.x, surface.position.y, -this._pos_mm[0])
        }
    }

    setPositionPlusOffset(mesh, offsets) {
        const newPosition = this.getPositionOnParent(mesh)
        mesh.position.x = newPosition.x + offsets.x;
        mesh.position.y = newPosition.y + offsets.y;
        mesh.position.z = newPosition.z + offsets.z;
    }
}

export default Item;