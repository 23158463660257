import AbstractModel from "./AbstractModel"

import '@babylonjs/loaders'
import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Mesh } from "@babylonjs/core/Meshes";
import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";

/**
 * @name Earphone
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
class Earphone extends AbstractModel {
    dimensions = new Vector3(- 0.0875, 0.0475, 0.025)
    offsets = new Vector3(13.5, - 102.5, 5)
    
    buildInternalMesh(scene) {
        this.hookable = true

        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/earphone/", "razer_hammerhead_pro.obj", scene);
            
            const meshes = []
            const multiMaterial = new MultiMaterial(this.meshName, scene);

            // Custom settings for the current sub-mesh 
            for (const mesh of data.meshes) {
                if (mesh.name.includes("background") || mesh.name.includes("Eartip")) {
                    mesh.dispose()
                } else {
                    mesh.rotation.y = Math.PI/180 * 155

                    if(mesh.name == "rhp_box_main") {
                        let material = scene.getMaterialByName("earphone")
                        if (material == null) {
                            material = new StandardMaterial("earphone")
                            material.diffuseTexture = new Texture("/models/earphone/textures/razer_hammerhead_pro_box_specular.png");
                        }
                        mesh.material = material;
                    } else if (mesh.name.includes("plasticTrans")) {
                        let material = scene.getMaterialByName("plasticTrans");
                        if (material == null) {
                            material = new StandardMaterial("plasticTrans")
                            material.diffuseColor = new Color3(1, 1, 1)
                            material.alpha = 0.3;
                        }
                        mesh.material = material;
                    }
                    multiMaterial.subMaterials.push(mesh.material)

                    // Set material to every sub-meshes
                    meshes.push(mesh)
                }
            }

            // Merge the imported meshes as a single one
            this.mesh = Mesh.MergeMeshes(meshes, true, true, null, false, true)
            
            resolve({ mesh: this.mesh, hookable: this.hookable })
        })
    }
}

export default Earphone;
