import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { Color3 } from "@babylonjs/core/Maths/math.color"
// import { Color4 } from "@babylonjs/core/Maths/math.color"
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder"
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Tools } from "@babylonjs/core/Misc/tools";
import { Vector3 } from "@babylonjs/core/Maths/math";

// Shelf (as a box) three-dimensional model.
class Shelf {
    _scene;
    _furnitureHeight;
    _shelfNb;
    _shelf;

    mesh;
    constructor(scene, shelf, shelfNb, furnitureHeight) {
        this._scene = scene
        this._shelf = shelf
        this._shelfNb = shelfNb
        this._furnitureHeight = furnitureHeight
    }

    create() {
        const base = 50 // furniture's lower level
        const height = 10 // shelf's mesh thickness

        // var faceColors = new Array(6);
        // faceColors[4] = new Color4(0,1,0,1); // pruple top

        // Create shelf's mesh
        const shelf = MeshBuilder.CreateBox(this._shelf.id.shelfSn, { height: height, width: this._shelf.dimension.x, depth: this._shelf.dimension.y, /* faceColors: faceColors */ }, this._scene)

        // Set the shelf's boards list as metadata to the mesh
        shelf.metadata = {...this._shelf}

        // Update shelf's texture
        shelf.material = new StandardMaterial("materia", this._scene) 
        shelf.material.diffuseColor = Color3.Gray()

        // Update shelf's pivot (point used as the center of rotation or the center of enlargement)
        shelf.setPivotPoint(new Vector3(-this._shelf.dimension.x / 2, 0, -this._shelf.dimension.y / 2))
        
        // Update shelf's position & offsets
        shelf.position.x = (this._shelf.dimension.x / 2) // position_x = put x_origin on bottom left corner + offset
            + this._shelf.offset.x
        shelf.position.y = (-this._furnitureHeight / 2) // position_y = put y_origin on furniture bottom + base offset & add floor ratio + offset
            + base 
            + ((this._furnitureHeight / this._shelfNb) * (this._shelf.floorInFurniture.floor - 1)) 
            + this._shelf.offset.z 
        shelf.position.z = (this._shelf.dimension.y / 2) // position_z = put z_origin on bottom left corner + offset
            + this._shelf.offset.y

        // Update shelf's rotation
        shelf.rotation.x = Tools.ToRadians(this._shelf.rotationAngles.x)
        shelf.rotation.y = Tools.ToRadians(-this._shelf.rotationAngles.z)
        shelf.rotation.z = Tools.ToRadians(this._shelf.rotationAngles.y)

        // Update shelf's mesh settings
        shelf.isPickable = true;
        shelf.actionManager = new ActionManager(this._scene);
        shelf.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev) { /* useful to pick the shelf and put a pointer cursor  */ }));

        // Add the shelf's mesh to the scene
        return this._scene.addMesh(Object.assign(this, { mesh: new Mesh(shelf) }).mesh)
    }
}

export default Shelf;
