import * as types from "../actions.types";
import { formatAttributes } from "../../shared/utils";

/**
 * @function boardMiddleware()
 * @returns next(action)
 * 
 * Middleware used to convert the returned boards datasets before passing through the store.
 */
export function boardMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            switch(action.type) {
                case types.UPDATE_BOARD_SUCCESS: case types.GET_BOARD_SUCCESS:
                    action.payload = formatAttributes(action.payload, "board");
                    break;  
                case types.GET_ALL_BOARD_SUCCESS:
                    action.payload.boardsList.forEach((board) => {
                        board = formatAttributes(board, "board")
                    });
                    break;
                case types.GET_ITEMS_BOARD_SUCCESS:
                    getState().geo.board.list.forEach((board) => {
                        board = formatAttributes(board, "board")
                    });
                    break;                  
                default:
                    return next(action, dispatch)
            }

            return next(action); // You should always return next(action) in your middleware.
            // If you forget to return this the application will stop, and no other action will reach the reducer.
        }
    }
}