import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux"
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';

import { Form } from "../../../components/form/index.js"
import { outputLog } from "../../../shared/utils"
import { default as CLIENTS } from "../../../api/index"

import { BoardPb, GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import 'virtual-select-plugin/dist/virtual-select.min.css';
import 'virtual-select-plugin/dist/virtual-select.min.js';

import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    type: "",
    boards: []
}

const EnrolKnownBoards = (props) => {
    const dispatch = useDispatch();

    const streamingRPC = useRef(null);

    // Creates state form's fields, streaming state & enrolment feedback
    const [{ sn, type, boards }, setFields] = useState({...initialFields, sn: props.sn, type: props.type});
    const [feedback, setFeedback] = useState([]);

    // Effect used to clean all remaining ressources on leaving components (DOM events, streaming, etc...)
    useEffect(() => {
        return () => { // Stream cancellation on leaving the page (component unmount)
            if (streamingRPC.current) {
                streamingRPC.current?.cancel() // Clean on leaving
            }
        }
    }, [streamingRPC])

    // Trig the props' changes to update the form's fields
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn, type: props.type }))
    }, [props.sn, props.type])
    
    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: value }));
    };

    useEffect(() => {
        window.VirtualSelect.init({ ele: '#sample-select', search: true, required: true });

        // Call the `GetAll` method through the `Board` service (should provide all boards)
        new Promise(async (resolve, reject) => {
            await CLIENTS.geo.board.getAll(new BoardPb.BoardFilters(), dispatch, resolve, reject, (err, response) => {
                if (err) {
                    console.error(err); CLIENTS.geo.board.constructor.handleResponseOrError(response, err, resolve, reject);
                } else {
                    const selectWrapper = document.querySelector('#sample-select');
                    
                    // Updates & manages the selectable boards list
                    selectWrapper.setOptions(response.toObject().boardsList.map((board) => { return board.sn }))

                    // Handle changes listener & set custom properties
                    selectWrapper.addEventListener('change', handleChange);
                }
            })
        }).catch((err) => console.error(err))
    }, [])

    // Disables the new enroled board(s)
    useEffect(() => {
        const selectWrapper = document.querySelector('#sample-select')
        const disabledOpts = [...selectWrapper.getDisabledOptions().map((opt) => { return opt.value })]

        feedback.forEach((treatedBoard) => {
            if (treatedBoard.requestStatus.code === 0 && treatedBoard.requestStatus.message === "Command succeed")
                disabledOpts.push(treatedBoard.boardSn)
        })
        selectWrapper.setDisabledOptions(disabledOpts)
    }, [feedback])

    return (
        <Form
            title={"Enrol known boards"}
            response={"Enrolment ended"}
            fields={{ ...initialFields, sn: props.sn, type: props.type }}
            toggle={
                useCallback(() => { /* EnrolKnownBoards */
                    if (streamingRPC.current) { 
                        streamingRPC.current?.cancel() // Clean properly remaining stream
                    } 
                    const id = new GwPb.GwID(), arg = new GwPb.GwBoards();
                    id.setSn(sn);
                    id.setType(type);
                    arg.setId(id);
                    arg.setBoardsSnList(boards);

                    return new Promise(async (resolve, reject) => {
                        // Starts to stream to enrol known boards on a gateway.
                        const stream = await CLIENTS.geo.gateway.enrolKnownBoards(arg, dispatch)

                        stream.on('data', (response) => {
                            const data = response.toObject();

                            // Gateway disconnected behaviour => avoid to feed the feedback and reject the response
                            if (data?.requestStatus?.code === 9 && data?.requestStatus?.message?.toLowerCase()?.includes("Gateway is disconnected".toLowerCase())) {
                                reject({ ...data.requestStatus })
                                return
                            }

                            setFeedback((prevFeedback) => {
                                const updatedFeedback = [...prevFeedback, data];
                                
                                if (updatedFeedback.length >= arg.getBoardsSnList().length) {
                                    resolve();
                                }
                                return updatedFeedback;
                            });
                        });

                        stream.on('error', (error) => { 
                            stream.cancel()
                            reject(error)
                        });
                        streamingRPC.current = stream
                    })
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4"  style={{ maxHeight: "100%" }}>
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-start align-items-center">
                            <input id="gw_type" name="type" type="text" value={type}
                                className="form-control w-75"
                                readOnly="readonly"
                                required
                            /> 
                        </div>
                        <small id="gw_typeHelp" className="form-text text-muted">Gateway type</small>
                    </div>
                    <div className="col-sm-6">
                        <input id="gw_serial_number" name="sn" type="text" value={sn}
                            className="form-control w-75"
                            readOnly="readonly"
                            required
                        />
                        <small id="gw_serial_numberHelp" className="form-text text-muted">Gateway serial number</small>
                    </div>
                </div>

                <div className="d-flex align-items-start pt-3" style={{ maxHeight: "100%" }}>
                    <div className="col-sm-6 pr-2">
                        <div className="d-flex justify-content-start align-items-center">
                            <select multiple id="sample-select" data-silent-initial-value-set="true" data-search="true"
                                className="w-75"
                                name="boards"
                                required
                            >
                            </select><select multiple required onChange={() => {}} /* Transparent 'select' element used to display the validation pop-up on none of any selected board */
                                value={ boards.length > 0 ? ["selected"] : [] }
                                style={{ opacity:0, width: 0, height: 0, marginTop: "1%", marginLeft: "5%", position: "absolute", zIndex: 50 }} 
                            ><option value="selected"></option></select>
                            
                        </div>
                        <div className="align-items-center">
                            <small id="sample-selectHelp" className="form-text text-muted">Boards</small>
                        </div>
                        <button type="submit" className="btn btn-primary">OK</button>
                    </div>
                    <div className="col-sm-6" style={{ maxHeight: "100%", display: feedback?.length !== 0 ? "block" : "none" }}>
                        <div className="tab-content">
                            <div className="table-responsive align-items-baseline" rows="3">
                                <table id="action-table" valign="middle">
                                    <tbody>
                                        {
                                            feedback.map((row, i) => {
                                                return <tr key={ row.boardSn+i } id={ row.boardSn }>
                                                    <td>
                                                        <div id={"accordion_" + i} className="accordion accordion-flush px-1 w-100">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id={"flush-heading_" + i}>
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse_" + i} aria-expanded={i === 0 ? "true" : "false"} aria-controls={"flush-collapse_" + i}>
                                                                        { row.requestStatus.code === 0 
                                                                            ? <BsFillCheckCircleFill className="me-2" color="green" size="20"/> 
                                                                            : <BsFillXCircleFill className="me-2" color="red" size="20"/> 
                                                                        }
                                                                        <span className="text-uppercase fw-bold">{ row.boardSn }</span>
                                                                        <span className="bi bi-chevron-down ml-auto"></span>
                                                                    </button>
                                                                </h2>
                                                                <div id={"flush-collapse_" + i} className={"accordion-collapse collapse" + (i === 0 ? " show" : "")} aria-labelledby={"flush-heading_" + i} data-bs-parent={"#accordion_" + i}>
                                                                    <div className="pt-2 w-75" style={{ whiteSpace: "nowrap" }}>
                                                                        { row.requestStatus.message }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="align-items-center pt-0">
                            <small id="sample-selectHelp" className="form-text text-muted">Board(s) list</small>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default EnrolKnownBoards;