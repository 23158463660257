import * as userTypes from '../actions.types'

/**
 * @constant INITIAL_STATE
 * 
 * Initial user state structure.
 */
const INITIAL_STATE = {
    accessManaged: false,
    auth: {
        isAuthenticated: false,
        userId: "",
        username: "",
        email: "",
        displayName: "",
        // role: "",
        // accessToken: "", // keycloak.token
        // tokenExpiration: null, // tokenParsed.exp
    },
    permissions: {}
}

/**
 * @constant reducer
 * @param { initial user state } state
 * @param { action } action Actions are the only source of information for the store
 * @returns state
 * 
 * Build the user state.
 */
const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case userTypes.USER_LOGOUT_SUCCESS : { // Reset (logged out) user's informations
            sessionStorage.removeItem("permissions") // clean cached permissions
            return INITIAL_STATE
        } 
        case userTypes.USER_LOGIN_SUCCESS : { // Updates (logged in) user's informations 
            const client = action.payload
            const idTokenParsed = client.idTokenParsed

            return { ...state, accessManaged: true,
                auth: {
                    isAuthenticated: client.authenticated,
                    userId: client.subject,
                    username: idTokenParsed.preferred_username,
                    displayName: idTokenParsed.given_name + " " + idTokenParsed.family_name,
                    email: idTokenParsed.email,
                }
            }
        }
        case userTypes.USER_SCOPE_PERMISSIONS_SUCCESS : // Updates user's permissions 
            return { ...state, permissions: action.payload }
        default :
            return { ...state }
    }
}

export { reducer }