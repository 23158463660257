import AbstractModel from "./AbstractModel"

import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Mesh } from "@babylonjs/core/Meshes";
import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";

import '@babylonjs/loaders'


/**
 * @name BatteryModel
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
 class BatteryModel extends AbstractModel {
    dimensions = new Vector3(- 1, 1, 0.4)
    offsets = new Vector3(27.5, 88.25, - 2.5)

    buildInternalMesh(scene) {
        this.hookable = true

        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/battery/", "battery.obj", scene);
            
            const meshes = []
            const multiMaterial = new MultiMaterial(this.meshName, scene);

            for (let i = 0; i < data.meshes.length; i++) {
                if (i <= 15) {
                    data.meshes[i].dispose()
                } else {
                    let material/* : StandardMaterial */

                    if (data.meshes[i].name.includes("Panasonic_LR44")) {
                        material = scene.getMaterialByName("Panasonic_LR44");
                        if (material == null) {
                            material = new StandardMaterial("Panasonic_LR44")
                            material.specularTexture = new Texture("/models/battery/textures/Panasonic_LR44_Specular.png");
                            material.diffuseTexture = new Texture("/models/battery/textures/Panasonic_LR44_Diffuse.png");
                        }
                    } else if (data.meshes[i].name.includes("Plastic"))  {
                        material = scene.getMaterialByName("plasticTrans")
                        if (material == null) {
                            material = new StandardMaterial("plasticTrans");  // Platic texture with transparence
                            material.diffuseColor = new Color3(1, 1, 1)
                            material.alpha = 0.5;
                        }
                    } else {
                        material = scene.getMaterialByName("battery");
                        if (material == null) {
                            material = new StandardMaterial("battery")
                            material.specularTexture = new Texture("/models/battery/textures/LR44_X1_Specular.png");
                            material.emissiveTexture = new Texture("/models/battery/textures/LR44_X1_Normal.png");
                            material.diffuseTexture = new Texture("/models/battery/textures/LR44_X1_Diffuse.png");
                            material.bumpTexture = new Texture("/models/battery/textures/LR44_X1_bump.png");
                        }
                    }

                    // Set material to every sub-meshes
                    data.meshes[i].material = material;
                    multiMaterial.subMaterials.push(data.meshes[i].material)
                    
                    data.meshes[i].rotation = {x: Math.PI/2, y: Math.PI, z: 0}
                    meshes.push(data.meshes[i])
                }
            }
            this.mesh = Mesh.MergeMeshes(meshes, true, false, null, false, true)
            this.mesh.material = multiMaterial;
            this.mesh.alwaysSelectAsActiveMesh = true

            resolve({ mesh: this.mesh, hookable: this.hookable })
        })
    }
}

export default BatteryModel;

