import '@babylonjs/loaders'

import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";

import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";

import AbstractModel from "./AbstractModel"

/**
 * @name GaugeModel
 * @extends AbstractModel
 * 
 */
class GaugeModel extends AbstractModel {
    green = new Color3(0.63, 0.89, 0.27)
    orange  = new Color3(0.97, 0.77, 0.24)
    red = new Color3(0.97, 0.38, 0.24)

    dimensions = new Vector3(1, 1, 1);
    offsets = new Vector3(0, 80, 0);
    levelPercent = 0
    _emptyMesh = null;

    getColor() {
        let color = this.green
        if (this.levelPercent < (1/3) && this.levelPercent > 0.1) {
            color = this.orange
        }
        if (this.levelPercent <= 0.1) {
            color = this.red
        }
        return color
    }
    
    getMesh(scene) {
        let finalMesh;

        if (this._emptyMesh == null || this._emptyMesh?.isDisposed()) {
            this._emptyMesh = this.buildInternalMesh(scene, true)
            this._emptyMesh.visibility = 0
        }

        if (this.levelPercent > 0) {
            let levelMesh = this.buildInternalMesh(scene, false) // TODO faster !!!
            finalMesh = Mesh.MergeMeshes([this.getEmptyClone(), levelMesh], true, true, undefined, false, true);
            finalMesh.visibility = 1
        } else {
            finalMesh = Mesh.MergeMeshes([this.getEmptyClone()], true, true, undefined, false, true); // better clean of meshes and materials after this fake merge
        }

        return new Promise((resolve) => { resolve({mesh: finalMesh}) })
    }

    getEmptyClone() {
        let newMesh = this._emptyMesh.clone()
        let newMat = newMesh.material.subMaterials[0].clone()
        newMat.diffuseColor = this.getColor()
        newMesh.material.subMaterials[0] = newMat
        return newMesh
    }

    buildInternalMesh(scene, empty) {
        var material = new StandardMaterial(scene);
        material.alpha = 1;
        if (empty) {
            material.alpha = 0.2;
        }
        material.diffuseColor = this.getColor();

        var xR = 2; // bevel radius x direction
        var yR = 9; // bevel radius y direction
        var w = 10; // depth once rotated
        var h = 10;  // width once rotated

        const maxDepth = 60;
        var level = maxDepth * this.levelPercent;

        if (empty) {
            level = maxDepth;
        }

        var offsetY = -(maxDepth-level)/2;
        var depth = level; // height once rotated

        var n = 200; //increments around circle
        var a; //angle

        //Array of paths to construct extrusion
        var mainShape = [ ];

        mainShape.push(new Vector3(w, h - yR, 0));
        
        for (var i = 0; i < n; i++)	{
            a = i * Math.PI / (2 * n);
            mainShape.push(new Vector3(w - xR * (1 - Math.cos(a)), h - yR * (1 - Math.sin(a)), 0 ))
        }

        mainShape.push(new Vector3(w - xR, h, 0));
        mainShape.push(new Vector3(-w + xR, h, 0));
        
        for (var i = 0; i < n; i++)	{
            a = Math.PI / 2 + i * Math.PI / (2 * n);
            mainShape.push(new Vector3(-w + xR * ( 1 + Math.cos(a)), h - yR * (1 - Math.sin(a)), 0 ))
        }

        mainShape.push(new Vector3(-w, h - yR, 0));
        mainShape.push(new Vector3(-w, -h + yR, 0));

        for (var i = 0; i < n; i++)	{
            a = Math.PI + i * Math.PI / (2 * n);
            mainShape.push(new Vector3(-w + xR * ( 1 + Math.cos(a)), -h + yR * (1 + Math.sin(a)), 0 ))
        }

        mainShape.push(new Vector3(-w + xR, -h, 0));
        mainShape.push(new Vector3(w - xR, -h, 0));

        for (var i = 0; i < n; i++)	{
            a = 3 * Math.PI / 2 + i * Math.PI / (2 * n);
            mainShape.push(new Vector3(w - xR * ( 1 - Math.cos(a)), -h + yR * (1 + Math.sin(a)), 0 ))
        }

        mainShape.push(new Vector3(w, -h + yR, 0));

        mainShape.push(mainShape[0]);
        
        var mainPath = [
            new Vector3(0, 0, -depth / 2 + xR),
            new Vector3(0, 0, depth / 2 - xR)
        ];
        
        var mainBody = MeshBuilder.ExtrudeShape("1", {shape: mainShape, path: mainPath, 
            sideOrientation: Mesh.DOUBLESIDE, cap: Mesh.CAP_ALL}, scene);
        mainBody.convertToFlatShadedMesh();
        mainBody.material = material;

        // mainBody.position.z = mainBody.position.z + positioning.z;
        mainBody.position.y = offsetY; //mainBody.position.y + positioning.y + offsetY;
        // mainBody.position.x = mainBody.position.x + positioning.x;

        mainBody.rotation.z = 3 * Math.PI / 2;
        mainBody.rotation.x = Math.PI / 2;

        var endShape = [];
        endShape.push(new Vector3(xR / 2, -h + yR, 0));
        endShape.push(new Vector3(xR / 2, h - yR, 0));

        for (var i = 0; i < n; i++)	{
            a = Math.PI / 2 + i * Math.PI / (2 * n);
            endShape.push(new Vector3(xR / 2 + xR * Math.cos(a), h - yR * (1 - Math.sin(a)), 0))
        }

        endShape.push(new Vector3(-xR / 2, h - yR, 0));
        endShape.push(new Vector3(-xR / 2, -h + yR, 0));

        for (var i = 0; i < n + 1; i++)	{
            a = Math.PI + i * Math.PI / (2 * n);
            endShape.push(new Vector3(xR / 2 + xR * Math.cos(a),  -h + yR * (1 + Math.sin(a)), 0))
        }

        endShape.push(endShape[0]);

        var endPath = [
            new Vector3(-w + xR, 0, 0),
            new Vector3(w - xR, 0, 0)
        ]

        var endBody = MeshBuilder.ExtrudeShape("2", {shape: endShape, path: endPath, 
            sideOrientation: Mesh.DOUBLESIDE, cap: Mesh.CAP_ALL}, scene);
        endBody.convertToFlatShadedMesh();
        endBody.material = material;

        endBody.position.y = -0.5 * (depth - xR);

        //endBody.position.z += positioning.z;
        endBody.position.y += offsetY; //positioning.y + offsetY;
        //endBody.position.x += positioning.x;

        endBody.rotation.y = 3 * Math.PI / 2;
        endBody.rotation.x =  Math.PI / 2;

        if (level >= maxDepth) {
            var endBodyBack = endBody.clone("3");
            endBodyBack.material = material; 

            endBodyBack.position.y = 0.5 * (depth - xR);
            endBodyBack.position.y += offsetY //positioning.y + offsetY;

            endBodyBack.rotation.x =  3 * Math.PI / 2;
        }

        var fillerShape = [];
        for (var i = 0; i < n; i++)	{
            a = 3 * Math.PI / 2 + i * Math.PI / (2 * n);
            fillerShape.push(new Vector3(xR * Math.cos(a), -h + yR * (1 + Math.sin(a)), 0))
        }

        fillerShape.push(new Vector3(xR, h - yR, 0));
        fillerShape.push(new Vector3(xR, -h + yR, 0));

        for (var i = 0; i < n + 1; i++)	{
            a = i * Math.PI / (2 * n);
            fillerShape.push(new Vector3(xR * Math.cos(a),  h - yR * (1 - Math.sin(a)), 0))
        }
        
        var fillerRightBottom = MeshBuilder.CreateLathe("4", {shape: fillerShape, arc: 0.25}, scene);
        fillerRightBottom.convertToFlatShadedMesh();
        fillerRightBottom.material = material; 

        fillerRightBottom.position.y = -(depth / 2 - xR);
        fillerRightBottom.position.z = w - xR;

        fillerRightBottom.position.y += offsetY // positioning.y + offsetY;
     // fillerRightBottom.position.x +=  positioning.x;
     // fillerRightBottom.position.z +=  positioning.z

        fillerRightBottom.rotation.y = 3 * Math.PI / 2;
        fillerRightBottom.rotation.x = Math.PI / 2;
        
        var fillerLeftBottom = fillerRightBottom.clone("5");
        fillerLeftBottom.position.z = -w + xR;

     // fillerLeftBottom.position.z += positioning.z;

        fillerLeftBottom.rotation.y = Math.PI / 2;

        if (level >= maxDepth) {
            var fillerRigthFront = fillerRightBottom.clone("6");
            fillerRigthFront.position.y = depth / 2 - xR;

            fillerRigthFront.position.y += offsetY// positioning.y + offsetY;

            fillerRigthFront.rotation.z = 3* Math.PI / 2;
            fillerRigthFront.rotation.y = - Math.PI;
            fillerRigthFront.rotation.x = - Math.PI; 


            var fillerLeftFront = fillerLeftBottom.clone("7");
            fillerLeftFront.position.y = depth / 2 - xR;

            fillerLeftFront.position.y +=offsetY // positioning.y + offsetY;

            fillerLeftFront.rotation.z = 3 * Math.PI / 2;
            fillerLeftFront.rotation.y = Math.PI ;
            fillerLeftFront.rotation.x = 3 * Math.PI / 2; 
        }

        return Mesh.MergeMeshes([mainBody, endBody, endBodyBack, 
            fillerLeftBottom, fillerLeftFront, fillerRightBottom, fillerRigthFront], 
            true, true, undefined, false, true);
    }
}

export default GaugeModel;