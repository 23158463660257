import { BoardPb, ItemPb, GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { FurniturePb } from '@centiloc/centiloc-ops-api-inventory-grpc'
import * as timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb'

// Function to verify the consistency of value
const validEnv = (envVarName, expectedPattern) => {
    if (!expectedPattern.test(window[envVarName])) {
        console.error(`${envVarName} isn't properly set up. Inconsistent format. Got: "${window[envVarName]}"`);
        return false;
    }
    return true;
}

function outputLog(hosts, isError, message, arg) {
    const log = isError ? console.error : console.log;

    hosts.forEach((host) => {
        if (window.location.host.includes(host)) {
            if (arg) {
                log(message, arg);
            } else {
                log(message);
            }
        }
    });
}

function formatTime(momentum, convert = false) {
    return convert 
        ? new Date(momentum.seconds * 1000 + momentum.nanos / 1e6).toLocaleString()
        : new Date(momentum.seconds * 1000 + momentum.nanos / 1e6);
}

function formatFilters(payload, type) {
    const msg = new Object() // Will referred the message to filter and to paginate

    switch (type) {
        case 'item': /* Getting all Items */
            msg.filters = new ItemPb.ItemFilters();

            if (payload !== undefined && payload.filters !== undefined) {
                payload.filters.forEach(filter => {
                    const timestampFromDate = new timestamp_pb.Timestamp();
                    const itemFilter = new ItemPb.ItemFilter();

                    switch (filter.filter) {
                        case "UID": case "BOARDSN":
                            itemFilter.setText(filter.value);
                            break;
                        case "ITEMSTATUS":
                            itemFilter.setItemStatus(filter.value);
                            break;
                        case "MOMENTUM":
                            timestampFromDate.setSeconds(new Date(filter.value) / 1000);
                            timestampFromDate.setNanos((new Date(filter.value) % 1000) * 1e6);
                            
                            itemFilter.setDate(timestampFromDate);
                            break;
                        default:
                            console.error('Attempts to filter with an unknown field. Got : ', filter.filter);
                            break;
                    }

                    itemFilter.setType(ItemPb.ItemAttr[filter.filter]);
                    msg.filters.addFilters(itemFilter);
                });
            }

            msg.pagination = new ItemPb.ItemsPagination()
            break;
        case 'board': /* Getting all Boards */
            msg.filters = new BoardPb.BoardFilters();

            if (payload !== undefined && payload.filters !== undefined) {
                payload.filters.forEach(filter => {
                    const timestampFromDate = new timestamp_pb.Timestamp();
                    const boardFilter = new BoardPb.BoardFilter();

                    switch (filter.filter) {
                        case "SN":
                            boardFilter.setText(filter.value);
                            break;
                        case "BOARDSTATUS":
                            boardFilter.setBoardStatus(filter.value);
                            break;
                        case "LAST_EVENT": case "NO_ACTIVITY_AFTER": case "LAST_CONNECTION":
                            timestampFromDate.setSeconds(new Date(filter.value) / 1000);
                            timestampFromDate.setNanos((new Date(filter.value) % 1000) * 1e6);

                            boardFilter.setDate(timestampFromDate);
                            break;
                        case "GW":
                            const gwId = new GwPb.GwID();

                            if (filter.value?.hasOwnProperty("type") && filter.value?.hasOwnProperty("sn")) {
                                gwId.setType(filter.value.type);
                                gwId.setSn(filter.value.sn);
                            }

                            boardFilter.setGwId(gwId);
                            break;
                        default:
                            console.error('Attempts to filter with an unknown field. Got : ', filter.filter);
                            break;
                    }

                    boardFilter.setType(BoardPb.BoardAttr[filter.filter]);
                    msg.filters.addFilters(boardFilter);
                });
            }

            msg.pagination = new BoardPb.BoardsPagination()
            break;
        case 'furniture': /* Getting all Furnitures */
            msg.filters = new FurniturePb.FurnitureFilters()
            msg.pagination = new FurniturePb.FurnitureFilters.Pagination()
            break;
        case 'gateway': /* Getting all Gateways */
            msg.filters = new GwPb.GwFilters();

            if (payload !== undefined && payload.filters !== undefined) {
                payload.filters.forEach(filter => {
                    const timestampFromDate = new timestamp_pb.Timestamp();
                    const gwFilter = new GwPb.GwFilter();

                    switch (filter.filter) {
                        case 'ID':
                            const gwId = new GwPb.GwID();
                            gwId.setType(filter.value.type);
                            gwId.setSn(filter.value.sn);

                            gwFilter.setGwId(gwId);
                            break;
                        case 'GWSTATUS':
                            gwFilter.setGwStatus(filter.value);
                            break;
                        case "LAST_GW_CONNECTION":
                            timestampFromDate.setSeconds(new Date(filter.value) / 1000);
                            timestampFromDate.setNanos((new Date(filter.value) % 1000) * 1e6);

                            gwFilter.setDate(timestampFromDate);
                            break;
                        default:
                            console.error('Attempts to filter with an unknown field. Got : ', filter.filter);
                            break;
                    }

                    gwFilter.setType(GwPb.GwAttr[filter.filter]);
                    msg.filters.addFilters(gwFilter);
                });
            }

            msg.pagination = new GwPb.GwsPagination()
            break;
        default:
            break;
    }

    // Sorting the data
    if(payload.sort || payload.sort === 0) {
        if (isNaN(payload.sort)) {
            switch(type) {
            case 'item':
                msg.pagination.setSort(ItemPb.ItemAttr[payload.sort])
                break
            case 'board':
                msg.pagination.setSort(BoardPb.BoardAttr[payload.sort])
                break
            case 'furniture':
                msg.pagination.setSort(FurniturePb.FurnitureFilters.SortBy[payload.sort])
                break
            case 'gateway':
                msg.pagination.setSort(GwPb.GwAttr[payload.sort])
                break
            default: break
            }
        } else {
            msg.pagination.setSort(payload.sort)
        }
    }    

    // Limiting/sizing the data
    if (payload.size !== 0) {
        if (type === "gateway") {
            msg.pagination.setLimit(payload.size);
        } else {
            msg.pagination.setSize(payload.size);
        }
    }

    // Paging the data
    if (payload.page !== 0) {
        msg.pagination.setPage(payload.page);
    }

    // Ordering the data
    if (payload.isDescending && (payload.sort || payload.sort === 0)) {
        msg.pagination.setIsDescending(payload.isDescending);
    }

    msg.filters.setPage(msg.pagination)
    return msg.filters;
};

function formatAttributes(arg, type) {
    const translateStatus = (status, statusEnum) => {
        return Object.entries(statusEnum).find(([key, val]) => val === status)?.[0];
    };

    const formatConnection = (connection) => {
        if (connection?.hasOwnProperty("seconds") && connection?.hasOwnProperty("nanos")) {
            return new Date(connection.seconds * 1000 + connection.nanos / 1e6).toLocaleString();
        }
        return undefined;
    };

    switch (type) {
        case 'item':
            if (arg?.momentum) {
                arg.lastMomentum = arg.momentum;
                delete arg.momentum;
            }

            if (arg?.status || arg?.status === 0) {
                arg.status = translateStatus(arg.status, ItemPb.ItemStatus);
            } else if (!arg?.isIn) {
                arg.status = translateStatus("OUT", ItemPb.ItemStatus);
            } else if (arg?.isIn) {
                arg.status = translateStatus("IN", ItemPb.ItemStatus);
            }
            break;
        case 'board':
            arg.status = translateStatus(arg.status, BoardPb.BoardStatus);

            if (arg?.gwId?.sn?.length === 0 && arg?.gwId?.type?.length === 0) {
                arg.gwId = undefined;
            }
        break;
        case 'gateway':
            arg.status = translateStatus(arg.status, GwPb.GwStatus);
            break;
        default:
            break;
    }

    arg.lastConnection = formatConnection(arg.lastConnection);
    arg.lastDisconnection = formatConnection(arg.lastDisconnection);

    return { ...arg };
}
  
export {
    formatAttributes,
    formatFilters,
    formatTime,
    outputLog,
    validEnv,
};
