import React, { useEffect, useRef, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

function InputPassword(props) {

    // State variables to manage the password input and its visibility
    const [hide, setHide]                   = useState(true);
    const [passwordType, setPasswordType]   = useState("password");
    const [passwordInput, setPasswordInput] = useState(props.value);

    const inputRef = useRef(null); // Create a ref for the input element

    useEffect(() => {
        const cursorPosition = inputRef.current.value.length;

        if (cursorPosition !== 0) { // After toggling the password visibility, restore the cursor position
            inputRef.current.selectionStart = cursorPosition;
            inputRef.current.selectionEnd = cursorPosition;
            inputRef.current.focus();
        }
    }, [passwordType])

    // Function to handle changes in the password input
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);

        // Parent changes handler
        props.onChange(evnt)
    }

    // Function to toggle the password visibility
    const togglePassword = () =>{
        if(passwordType === "password") { // If the password is currently hidden, show it
            setPasswordType("text");
            setHide(false);
            return;
        }

        // If the password is currently shown, hide it
        setPasswordType("password");
        setHide(true)
    }

    return (
        <div className="d-flex">
            <input autoComplete="new-password" id={props.id} name={props.name} className={props.className}
                ref={inputRef}                  // Assign the ref to the input element
                type={passwordType}             // Input type controlled by the passwordType state
                value={passwordInput}           // Input value controlled by the passwordInput state
                onChange={handlePasswordChange} // Calls the handlePasswordChange function on input change
                required={props.required}
                />
            <span className="d-flex justify-content-around align-items-center" onClick={togglePassword}>
                { hide ? <AiOutlineEyeInvisible className="position-absolute me-5" size={25}></AiOutlineEyeInvisible> : <AiOutlineEye className="position-absolute me-5" size={25}></AiOutlineEye> }
            </span>
        </div>
    )
}

export default InputPassword;

