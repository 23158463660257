import AbstractModel from "./AbstractModel"

import '@babylonjs/loaders'
import { Mesh } from "@babylonjs/core/Meshes";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Vector3 } from "@babylonjs/core/Maths/math";


/**
 * @name SuperglueModel
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
class SuperglueModel extends AbstractModel {
    dimensions = new Vector3(- 100, 100, 100)
    offsets = new Vector3(- 14.5, - 156, - 10)

    buildInternalMesh(scene) {
        this.hookable = true
        
        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/superglue/", "superglue.obj", scene);

            data.meshes.forEach((mesh) => {
                mesh.rotation = { x: Math.PI*1.5, y: Math.PI/2, z: Math.PI/2 };
            })

            // Merge the imported meshes as a single one
            this.mesh = Mesh.MergeMeshes(data.meshes, true, false, null, false, true)

            resolve({ mesh: this.mesh, hookable: this.hookable })
        })
    }
}

export default SuperglueModel;
