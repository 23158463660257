import KC from "../../keycloak"
import * as types from "../actions.types"
import { default as CLIENTS } from "../../api/index"
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { Dispatch } from "redux"
import { Status, StatusCode } from 'grpc-web'

// Initiates a user sign-on action.
export const signOn = () => async (dispatch: Dispatch) => {
    dispatch({
        type: types.USER_LOGIN_SUCCESS,
        payload: KC.get(),
    });
}

// Initiates a user log-out action.
export const logOut = () => async (dispatch: Dispatch) => {
    KC.doLogout().then((data) => 
        dispatch({
            type: types.USER_LOGOUT_SUCCESS,
            payload: data
        })
    ).catch((err: Error) => 
    dispatch({
        type: types.USER_LOGOUT_FAILURE,
        error: err
    }))
}

// Retrieves and caches user scope permissions.
export const scopePermissions = (force: boolean) => async (dispatch: Dispatch) => {
    const permissions: Record<string, boolean> = JSON.parse(sessionStorage.getItem("permissions")) || {};

    if (sessionStorage.getItem("permissions") && !force) {
        dispatch({ type: types.USER_SCOPE_PERMISSIONS_SUCCESS, payload: permissions });
        return;
    }

    const deniedStatus: number[] = [
        StatusCode.PERMISSION_DENIED,
        StatusCode.UNAUTHENTICATED,
        StatusCode.UNIMPLEMENTED,
        StatusCode.UNAVAILABLE,
        StatusCode.UNKNOWN,
        StatusCode.DEADLINE_EXCEEDED,
        StatusCode.RESOURCE_EXHAUSTED,
        StatusCode.INTERNAL,
    ];

    // Process default (non-streaming) message
    const request = (api: string, service: string, message: string): Promise<void> => {
        const scope = `${api}.${service}.${message}`;
        
        return new Promise<void>((resolve, reject) => { // Handle default message with additional callbacks
            if (service === "board") {
                return CLIENTS[api][service][message](new Empty(), dispatch, resolve, reject, (err: Status) => {
                    permissions[scope] = !deniedStatus.includes(err?.code);
                    resolve();
                });
            }
            
            return CLIENTS[api][service][message](new Empty(), dispatch, (err: Status) => {
                permissions[scope] = !deniedStatus.includes(err?.code);
                resolve();
            });
        });

    };

    // Process individual streaming message
    const stream = async (api: string, service: string, message: string): Promise<void> => {
        return new Promise<void>((resolve) => {
            (async () => {
                const scope = `${api}.${service}.${message}`;

                try {
                    const streaming = await CLIENTS[api][service][message](new Empty(), dispatch);

                    // Handle errors during streaming
                    streaming.on('error', (err: Status) => {
                        permissions[scope] = !deniedStatus.includes(err?.code)
                        resolve()
                    });

                    // Then cancel stream to avoid leakage
                    setTimeout(() => streaming.cancel(), 5000);
                    resolve()
                } catch(e) {
                    console.error("Error while attempting to stream permission for '"+scope+"':", e)
                    resolve()
                }
            })();
        });
    };

    // Process individual message based on its type
    const processApiServiceMessage = async (api: string, service: string, message: string): Promise<void> => {
        try {
            switch (message) {
                case "streamItemsEvents": case "setURL": case "enrolKnownBoards": case "transfer": 
                    await stream(api, service, message);
                    break
                // @ts-expect-error: Fallthrough is intentional
                case "delete": 
                    if (message === "delete" && service === "tenant") {
                        await stream(api, service, message);
                        break
                    }
                // @ts-ignore: Fallthrough is intentional
                default:
                    await request(api, service, message);
            }
        } catch (err) {
            console.error("Error processing message:", err);
        }
    };

    // Process all messages for a specific service
    const processApiService = async (api: string, service: string): Promise<void> => {
        const messages = Object.getOwnPropertyNames(Reflect.getPrototypeOf(CLIENTS[api][service]));
        const messagePromises = messages
            .filter(message => !["constructor"].includes(message) && CLIENTS[api][service][message] instanceof Function)
            .map(message => processApiServiceMessage(api, service, message));

        await Promise.all(messagePromises);
    };

    // Process all services for a specific API
    const process = async (api: string): Promise<void> => {
        const services = Object.getOwnPropertyNames(CLIENTS[api]);
        const servicePromises = services.map(service => processApiService(api, service));

        await Promise.all(servicePromises);
    };

    try {
        // Wait for all API processes to complete
        await Promise.all(Object.getOwnPropertyNames(CLIENTS).map(api => process(api))).then(() =>
            dispatch({ type: types.USER_SCOPE_PERMISSIONS_SUCCESS, payload: permissions })
        );
    } catch (err) {
        dispatch({ type: types.USER_SCOPE_PERMISSIONS_FAILURE, error: err, payload: null });
    }
};