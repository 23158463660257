import React from "react";
import { useDispatch } from "react-redux"
import '../../assets/style/layout/table.css' // CSS

export default ({ data, link, getAll, filters, limitResult, visualContent, className, ...attributes }) => {
    switch(link.name) { // Handle the show of the first/last buttons according to the current `paging` value
    case "first":
        if ((!data.links?.previous || !data.links.previous.page) && !attributes.disabled) 
            attributes.disabled = true
        break;
    case "last":
        if ((!data.links?.next || !data.links.next.page) && !attributes.disabled) 
            attributes.disabled = true
        break;
    }

    if (attributes.disabled) { // Is the button relative to an impossible request ?
        return <button type="button" {...attributes} className={ className }>{visualContent}</button>
    }
            
    const dispatch = useDispatch();

    return <button type="button" name={ link.name } {...attributes} className={ className } onClick={
        () => dispatch(
            getAll({ /* GetAll - ask for pagination */
                filters: filters,
                size: limitResult,
                page: link.pagination.page.page,
                sort: data.pagination.sort,
                isDescending: link.pagination.page.isDescending,
            })
        )
    }>{ visualContent }</button>
}
