import { GrpcWebClientBase } from 'grpc-web'
import { TenantWeb } from '@centiloc/centiloc-ops-api-admin-grpc'
import { BoardWeb, GwWeb, ItemWeb, StreamingWeb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { FurnitureWeb } from '@centiloc/centiloc-ops-api-inventory-grpc'
import { validEnv } from "../shared/utils"

const API_URL = window.API_URL;

class ClientFactory { // Singleton pattern
    private static instance: ClientFactory;
    
    private tenantAdmin:        TenantWeb.TenantClient;
    private boardGeo:           BoardWeb.BoardClient;
    private gatewayGeo:         GwWeb.GatewayClient;
    private itemGeo:            ItemWeb.ItemClient;
    private streamGeo:          StreamingWeb.StreamingClient;
    private furnitureServiceInventory: FurnitureWeb.FurnitureServiceClient;

    private constructor() {
        if(API_URL && validEnv("API_URL", /^http(s)?:\/\/.*/)) {
            if (!ClientFactory.instance) { // If the instance doesn't exist, set it to the current instance
                try {
                    this.tenantAdmin                = new TenantWeb.TenantClient(API_URL, null, null);
                    this.boardGeo                   = new BoardWeb.BoardClient(API_URL, null, null);
                    this.gatewayGeo                 = new GwWeb.GatewayClient(API_URL, null, null);
                    this.itemGeo                    = new ItemWeb.ItemClient(API_URL, null, null);
                    this.streamGeo                  = new StreamingWeb.StreamingClient(API_URL, null, null);
                    this.furnitureServiceInventory  = new FurnitureWeb.FurnitureServiceClient(API_URL, null, null);
                } catch (err) {
                    console.error(err)
                }

                ClientFactory.instance = this;
            }
        } else {
            throw new Error(`Failed to load API definition. Inconvenient URL. Got: ${API_URL}`);
        }
    }

    public static getInstance(): ClientFactory {
        if (!this.instance) {
            this.instance = new ClientFactory();
        }
        return this.instance;
    }

    public getAdminTenant(): GrpcWebClientBase {
        return this.tenantAdmin;
    }

    public getBoardGeo(): GrpcWebClientBase {
        return this.boardGeo;
    }

    public getGatewayGeo(): GrpcWebClientBase {
        return this.gatewayGeo;
    }

    public getItemGeo(): GrpcWebClientBase {
        return this.itemGeo;
    }

    public getStreamGeo(): GrpcWebClientBase {
        return this.streamGeo;
    }
  
    public getFurnitureServiceInventory(): GrpcWebClientBase {
        return this.furnitureServiceInventory;
    }
}

export default ClientFactory.getInstance();
