import BatteryModel     from "./Battery"
import BeontagModel     from "./Beontag"
import BottleModel      from "./Bottle"
import EarphoneModel    from "./Earphone"
import EcocupModel      from "./Ecocup"
import GaugeModel       from "./Gauge";
import GlueModel        from "./Glue"
import HammerModel      from "./Hammer"
import PointerModel     from "./Pointer"
import PotModel         from "./Pot"
import RazorModel       from "./Razor"
import SuperglueModel   from "./Superglue"

import { RotatedBinModel, BinModel} from "./Bin"

import { Vector3 } from "@babylonjs/core/Maths/math";

class ModelFactory {
    constructor() {
        this.modelMap = {
            "battery":      BatteryModel,
            "beontag":      BeontagModel,
            "bin":          BinModel,
            "bottle":       BottleModel, // Fade animations broken
            "earphone":     EarphoneModel,
            "ecocup":       EcocupModel,
            "gauge":        GaugeModel,
            "glue":         GlueModel,
            "hammer":       HammerModel,
            "pot":          PotModel,
            "razor":        RazorModel,
            "superglue":    SuperglueModel,
        };
        
        // Set the instance property to this instance
        ModelFactory.instance = this;
    }

    static getInstance() {
        if (!ModelFactory.instance) {
            ModelFactory.instance = new ModelFactory();
        }
        return ModelFactory.instance;
    }

    getItemModel(item){
        return Promise.resolve(this.getModel(item.productReference, item.uid))
    }

    // getModel creates and return an instance of the specified model class
    getModel(ref, ...params) {
        const splitRefAttr = ref?.split("-")
        const splitRefName = splitRefAttr[0]?.split("_")

        let ModelClass = this.modelMap[splitRefName?.[0]];
        if (!ModelClass) {
            ref && console.log(`Model not found for customer reference: ${ref}`);
            return new PointerModel(...params);
        } else {
            if (splitRefAttr?.[0].startsWith("bin") && ref.includes("-rotated")) {
                model = new RotatedBinModel(splitRefName[0], ...params)
                model.setMeshName(uid)

                return model
            }

            // Identify which model import and display
            ModelClass = new ModelClass(splitRefAttr?.[0], ...params)

            // Specific rename models to allow highlight effects due to the model/sub-meshes design & naming
            if (["bottle"].includes(ref)) {
                ModelClass.setMeshName(params[0])
            }
        }

        return ModelClass;
    }
}

export default ModelFactory
