import { useCallback } from 'react';
import { useUpdateHProducts, useUpdateOptions } from "./views"

import ItemModel from '../../models/Item'

const useItemEventHandler = (hideClipboard) => {
    const updateHProducts   = useUpdateHProducts();
    const updateOptions     = useUpdateOptions();

    const itemEventHandler = useCallback((dispatch, props, retItem, serial, scene) => {
        let itemUI = props.itemsUI[retItem.uid];

        if (itemUI === undefined) {
            itemUI = new ItemModel(scene, retItem, retItem.fillRatio, serial);
            props.itemsUI[retItem.uid] = itemUI;

            props.setItemsUI(props.itemsUI);
        } else {
            if (itemUI.eventPending) {
                itemUI.eventsWaiting.push(retItem);
                return;
            }

            itemUI.eventPending = true;
            itemUI.isIn = retItem.isIn;
        }

        new Promise((resolve) => {
            if (itemUI.isIn) { // IN behaviour
                itemUI.createOrMoveItem(resolve, props.updateHPick, itemUI, retItem, serial, props.target === retItem.uid);

                if (props.target === retItem.uid) {
                    props.setPick(retItem.uid);
                    props.setIsOpen(true);
                }
                return;
            }

            if (itemUI != undefined) { // OUT behaviour
                hideClipboard(scene, retItem.uid, props.highlighter)

                itemUI.Delete()
                delete props.itemsUI[retItem.uid];

                props.setItemsUI(props.itemsUI)
            }
            return resolve()
        }).then(() => {
            const item = props.items.find((item) => item.uid === retItem.uid) || null

            if (!item) {
                props.items.push(retItem);
            } else {
                props.items.splice(props.items.indexOf(item), 1, retItem);
            }
            props.setItems([...props.items]);

            updateHProducts(scene, props.highlighter);
            updateOptions(props.items, props.itemsUI);

            if (itemUI.eventsWaiting.length > 0) {
                itemEventHandler(dispatch, props, itemUI.eventsWaiting[0], itemUI.serial, scene);

                // Removes the first waiting item's event
                itemUI.eventsWaiting.shift();
            }
            itemUI.eventPending = false;
        });
    }, [hideClipboard, updateHProducts, updateOptions]);

    return itemEventHandler;
};

export {
    useItemEventHandler,
};
