import { combineReducers } from 'redux';
import { reducer as BoardReducer }     from './reducers/board.reducer'
import { reducer as FurnitureReducer } from './reducers/furniture.reducer'
import { reducer as GatewayReducer }   from './reducers/gateway.reducer'
import { reducer as ItemReducer }      from './reducers/item.reducer'
import { reducer as UserReducer }      from './reducers/user.reducer'

const rootReducer = combineReducers({
    root: (state = {}, action) => {
        if (action.type) {
            switch (action.type) {
                case 'STORE_URL':
                    // In the context of web browsing, the referrer is the URL of the web page that a person was on before they clicked a link and landed on the current page
                    // The HTTP header field used to transmit the referrer information is also called the "Referer" header.
                    // Note that "referrer" is the correct spelling, but the HTTP header uses the alternative spelling "Referer" 
                    //  due to a historical misspelling in the original HTTP specification.
                    return { ...state, referer: action.payload };
                default:
                    return { ...state };
            }
        }
    },
    board:      BoardReducer,       // State managed for boards.
    furniture:  FurnitureReducer,   // State managed for furnitures.
    gateway:    GatewayReducer,     // State managed for gateways.
    item:       ItemReducer,        // State managed for items.
    user:       UserReducer,        // State managed for current user.
})

/** export type: so you can use the state type in other places
 *  RootState: name of the type
 *  ReturnType<>: returns the type of the return inside the <>
 *  typeof rootReducer: the complicated redux type of the reducer that we will let TypeScript decode.
 */
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;


