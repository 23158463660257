import { captureException, captureMessage, getClient, Scope, withScope, } from "@sentry/react";
import KeycloakService from "../keycloak";

// Define the metadata object with types.
interface Metadata {
    authorization?: string;
    deadline?: Date;
}

export default class ApiManager {
    private static _deadlineSec: number = -1;

    /**
     * Get the metadata for API requests.
     * @returns Metadata object with authorization and optional deadline.
     */
    static getMetadata(): Metadata {
        try {
            this._deadlineSec = this.getDeadlineSec();

            if (this._deadlineSec > 0) {
                const deadline = new Date();
                deadline.setSeconds(deadline.getSeconds() + this._deadlineSec);

                return {
                    authorization: 'Bearer ' + KeycloakService.getToken(),
                    deadline: deadline,
                };
            }

            return {
                authorization: 'Bearer ' + KeycloakService.getToken(),
            };
        } catch (err) {
            console.error("An unexpected error occurred on creating the metadata request header:", err);
        }
        
        return {};
    }

    /**
     * Make an API call with appropriate metadata.
     * @param method - The API method to call.
     * @param arg - Arguments for the API call.
     * @param callback - Callback function (optional).
     * @param dispatch - Dispatch function (optional).
     * @returns Result of the API call.
     */
    static callAPI<T>(
        method: (arg: any, metadata: Metadata, callback?: (err: any, response: any) => void) => T,
        arg: any,
        callback?: (err: any, response: any) => void,
        _?: (action: any) => void
    ): T {
        const call = (accessManaged: boolean) => {
            if (accessManaged) {
                KeycloakService.updateToken();
            }

            try {
                let metadata = this.getMetadata();

                if (callback) {
                    return method(arg, metadata, callback);
                } else {
                    return method(arg, metadata);
                }
            } catch (err) {
                console.error("Client failed to call the API: " + err);
            }

            return {} as T;
        };

        if (window.KEYCLOAK_URL?.length !== 0) {
            return call(true)
        } else {
            return call(false);
        }
    }

    /**
     * Handle API response or error, and optionally send to Sentry.
     * @param endpoint - Endpoint name. TODO: add the endpoint name to identify which request failed
     * @param response - API response.
     * @param err - Error object.
     * @param resolve - Resolve function for Promises (optional).
     * @param reject - Reject function for Promises (optional).
     * @param sentryScope - Sentry scope for error reporting (optional).
     */
    static handleResponseOrError<T>(response: T, err: Error | null, resolve?: (value: T) => void, reject?: (reason: Error) => void, sentryScope?: (scope: Scope) => void): void {
        if (!err) {
            resolve && resolve(response);
        } else {
            if (getClient()?.getOptions()?.enabled) {
                withScope(sentryScope); captureException(err);
            }
            reject && reject(err);
        }
    }

    /**
     * Get the streaming deadline in seconds.
     * @returns Deadline in seconds.
     */
    static getDeadlineSec(): number {
        if (this._deadlineSec >= 0) {
            return this._deadlineSec;
        }

        if (!isNaN(parseInt(window.STREAMING_DEADLINE))) {
            this._deadlineSec = parseInt(window.STREAMING_DEADLINE);
        } else {
            const msg = `STREAMING_DEADLINE is expected as a numerical value. Got: ${window.STREAMING_DEADLINE}`;
            
            // Reports bad event and send it to Sentry.
            if (getClient()?.getOptions()?.enabled) {
                captureMessage(msg);
            }
            console.error(msg);
        }
     
        return this._deadlineSec;
    }
}
