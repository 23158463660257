import React, { useEffect } from "react";
import { useDispatch } from "react-redux"
import { useLocation } from 'react-router-dom';
import Nav from "./Nav";
import Footer from "./Footer.js";
import '../../assets/style/layout/layout.css'

// Layout defines the default layout component of the application,
//    it simply accepts `children` as `props` and render them
//    to the DOM together 
const Layout = (props) => {
   const dispatch = useDispatch();
   const location = useLocation();

   // Prevents the bad routing on static site generation
   useEffect(() => {
      if (window.location.pathname.includes('index.html')) location.pathname = "/"
   }, [location])

   // Trig the URL changes to be stored as a "referer" 
   useEffect(() => {
         dispatch({ type: "STORE_URL", payload: location })
   }, [location]);

   return <div id="layout">{ props.children }</div>
}

// Defines of a barrel exports to handle the whole of layout components. 
// Exports all default components to being reachable by a single file import:
//    - will limits the code complexity,
//    - improved component auto-importing,
//    - benefits the ability to organize the application folder as we see fit
//    - improved IntelliSense (code editing features like intelligent code completion and quick access to documentation)
//    - single source of truth for all exports defined in the barrel
export { 
   Layout, // main layout compoent
   Footer, // footer banner
};