import * as types from "../actions.types";
import { formatAttributes } from "../../shared/utils";

/**
 * @function furnitureMiddleware()
 * @returns next(action)
 * 
 * Middleware used to convert the returned furnitures datasets before passing through the store.
 */
export function furnitureMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            switch(action.type) {
                case types.GET_FURNITURE_SUCCESS:
                    action.payload = formatAttributes(action.payload, "furniture");
                    if (action.payload.shelvesList?.length > 0) {
                        
                        action.payload.shelvesList.forEach(shelf => {  // TODO:
                            shelf.boardsList = shelf.boardsList.filter((board) => board.shelfSn === shelf.sn)
                        });
                    }
                    break;  
                case types.GET_ALL_FURNITURE_SUCCESS:
                    action.payload.furnituresList.forEach((furniture) => {
                        formatAttributes(furniture, "furniture")

                        furniture.shelvesList.forEach(shelf => { // TODO:
                            shelf.boardsList = shelf.boardsList.filter((board) => board.shelfSn === shelf.sn)
                        });
                    });

                    break;                
                default:
                    return next(action, dispatch)
            }

            return next(action); // You should always return next(action) in your middleware.
            // If you forget to return this the application will stop, and no other action will reach the reducer.
        }
    }
}