import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root.reducer'; // Import the RootState from your Redux store
import { logOut } from '../redux/actions/user.actions';
import { Dispatch } from 'redux';

interface UserContextProps {
    isAuthenticated: boolean;
    userId:          string;
    username:        string;
    displayName:     string;
    email:           string;
    // login: () => void;
    signOut: (dispatch: Dispatch) => Promise<void>;
}

interface AuthData {
    isAuthenticated: boolean;
    userId:          string;
    username:        string;
    displayName:     string,
    email:           string;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const auth = useSelector((state: RootState) => state.user.auth);

    const [user, setUser] = useState<AuthData>({
        isAuthenticated: auth.isAuthenticated,
        userId:          auth.userId,
        username:        auth.username,
        displayName:     auth.displayName,
        email:           auth.email,
    });

    // Update the user context when the authentication state changes in Redux
    useEffect(() => {
        setUser({
            isAuthenticated: auth.isAuthenticated,
            userId:          auth.userId,
            username:        auth.username,
            displayName:     auth.displayName,
            email:           auth.email,
        });
    }, [auth]);

    const contextValue: UserContextProps = {
        ...user,
        // login,
        signOut: logOut(),
    };

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export const useUserContext = (): UserContextProps => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }

    return context;
};