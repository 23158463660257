import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    type: "",
    continuousScanModeEnabled: false,
    full_scan_true: false
}

const SetContinuousScanMode = (props) => {
    const dispatch = useDispatch();

    const fields = {...initialFields, ...props}
    
    // Creates state form's fields
    const [{ sn, type, continuousScanModeEnabled, full_scan_true }, setFields] = useState(fields);
    
    // Trig the props' changes to update the form's fields
    useEffect(() => setFields(fields => ({ ...fields, ...props })), [props])

    // Changes the input value
    const handleChange = e => setFields(fields => ({ ...fields, [e.target.name]: e.target.checked }));

    return (
        <Form
            title={"Set Continuous Scan Mode"}
            setFields={setFields}
            toggle={
                useCallback(() => { /* Set Continuous scan mode */
                    const id = new GwPb.GwID();
                    id.setSn(sn)
                    id.setType(type)
            
                    const arg = new GwPb.GwContinuousScanMode();
                    arg.setStart(continuousScanModeEnabled)
                    arg.setId(id)
                    arg.setFullScanTrue(full_scan_true)
                    
                    return CLIENTS.geo.gateway.setContinuousScanMode(arg, null, null, dispatch)
                })
            }
            fields={fields}
        >
            <div className="form-group mx-4 my-4">
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-start align-items-center">
                            <input id="gw_type" name="type" type="text" value={type}
                                className="form-control w-75"
                                readOnly="readonly"
                                required
                            />
                        </div>
                        <small id="typeHelp" className="form-text text-muted">Type</small>
                    </div>
                    <div className="col-sm-6">
                        <input id="gw_serial_number" name="sn" type="text" value={sn}
                            className="form-control w-75"
                            readOnly="readonly"
                            required
                        />
                        <small id="serial_numberHelp" className="form-text text-muted">Serial number</small>
                    </div>
                </div>
                <div className="d-flex align-items-center pt-3">
                    <div className="d-flex flex-column col-sm-6">
                        <label id="continuous_scan_mode_enabled" className={`switch ${continuousScanModeEnabled ? 'on' : 'off'}`}>
                            <input name="continuousScanModeEnabled" type="checkbox" checked={continuousScanModeEnabled} onChange={handleChange} />
                            <div className="slider"></div>
                            <div className={`switch-label ${continuousScanModeEnabled ? 'on' : 'off'}`}>
                                {continuousScanModeEnabled ? 'ON' : 'OFF'}
                            </div>
                        </label>
                        <small id="continuous_scan_mode_enabledHelp" className="form-text text-muted text-wrap">Has to be started or stopped ?</small>
                    </div>
                    <div className="d-flex flex-column col-sm-6">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={ full_scan_true } id="full_scan_true" name="full_scan_true" onChange={handleChange} />
                        <small id="full_scan_trueHelp" className="form-text text-muted text-wrap">Send requests for getting a full scan ?</small>
                    </div>
                        
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default SetContinuousScanMode;