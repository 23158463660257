import '@babylonjs/loaders'

import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

/**
 * @name HammerModel
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
class HammerModel extends AbstractModel {
    offsets     = new Vector3(0, 17, 0);
    dimensions  = new Vector3(0.25, 0.25, 0.25);
    rotation    = new Vector3(-Math.PI  / 2, 0, 0)
    heightMm    = 30;

    buildInternalMesh(scene) {
        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/hammer/", "hammer.obj", scene);
            const meshes = data.meshes;

            const material = new StandardMaterial("hammer");
            material.bumpTexture = new Texture("/models/hammer/textures/martelo_bump.jpeg");
            material.specularTexture = new Texture("/models/hammer/textures/martelo_uv.jpeg");
            material.emissiveTexture = new Texture("/models/hammer/textures/martelo_normals_-_C_pia.jpeg");
            material.diffuseTexture = new Texture("/models/hammer/textures/martelo_dif.jpeg");
            
            meshes[0].material = material;
            this.mesh = meshes[0]

            resolve(this)
        })
    }
}

export default HammerModel;
