import React, { useState, useMemo, useLayoutEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { BoardPb, GwPb, ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc' // Api-geo protocol buffers
import { CSVLink } from "react-csv";
import { BsFillTrashFill } from 'react-icons/bs'; // React-Bootstrap icons
import _ from 'lodash'; // JavaScript utility library

const TableHeader = (props) => {
    const [selectedFilter, setSelectedFilter] = useState();
    
    // Hook to programmatically update query params (URI) in react-router.
    const [searchParams, setSearchParams] = useSearchParams();

    // Manages filters build-up (as DOM elements & current URL)
    const getDataFiltered = (event) => {
        if(event) event.preventDefault();
        
        // Is the expected filter already set ?
        let exists = false;

        props.filtersList.forEach((filter) => {
            let newFilter = new FormData(event.target).entries()

            // While filtering by a GW id
            if(newFilter.filter === "GW" && newFilter.filter === filter.filter) { 
                if (newFilter.gwSn === filter.value.sn && newFilter.gwType === filter.value.type) exists = true
            } else {
                if (_.isEqual(newFilter, filter)) exists = true
            }
        });

        // If the filter isn't already set => push the filter to the reference list
        if(!exists) { 
            if(["ID", "GW"].includes(Object.fromEntries(new FormData(event.target).entries()).filter) && document.querySelectorAll('[id=inputFilter]').length > 1) {
                props.filtersList.push({ filter : Object.fromEntries(new FormData(event.target).entries()).filter === "ID" ? "ID" : "GW", value: {} })
                
                let inputsFilter = document.querySelectorAll('[id=inputFilter]')
                for(let i = 0; i < inputsFilter.length; i++) {
                    inputsFilter[i].name === "gwSn" 
                        ? props.filtersList[props.filtersList.length -1].value["sn"] = inputsFilter[i].value 
                        : props.filtersList[props.filtersList.length -1].value["type"] = inputsFilter[i].value
                }
            } else {
                for(var pair of new FormData(event.target).entries()) {
                    if(pair[0] === "filter") {
                        props.filtersList.push({ filter : pair[1], value: null })
                    } else if (pair[0] === "value") {
                        props.filtersList[props.filtersList.length -1].value = pair[1]
                    }
                }
            }
        } 

        // Update the URL based on filters set
        if([...props.filtersList].length > 0) { 
            setSearchParams({filters: props.filtersList.map((filter) => { return (JSON.stringify(filter) )})})
        }
    }

    // Manages the filters deletion and update the reference list of them
    const removeFilters = useCallback((event) => {
        if(event) { 
            event.preventDefault();
            
            if(typeof event.srcElement !== "undefined") { // Filter the list of filters by equality and keep all others 
                props.setFiltersList( // Reset the filters list without after being cleaned of the one deleted
                    props.filtersList.filter(
                        (filter) => (event.srcElement.dataset.filter === "ID" || event.srcElement.dataset.filter === "GW" 
                            ? (filter.filter === event.srcElement.dataset.filter && filter.value.sn + " / " + filter.value.type === event.srcElement.dataset.value) !== true
                            : (filter.filter !== event.srcElement.dataset.filter && filter.value !== event.srcElement.dataset.value)
                )))
                
                // If filtering parameters are found from the current URL
                if (searchParams.getAll("filters")?.length) { 
                    const params = []
                    const splittedFilter = event.srcElement.dataset.value.split(" / ");

                    // Loop on filters & rebuild the params as a list of filters
                    for (const value of searchParams.getAll("filters")) {
                        if (splittedFilter.length > 1 && (value !== JSON.stringify({ filter: event.srcElement.dataset.filter, value: {sn: splittedFilter[0], type: splittedFilter[1]}}))) {
                            params.push(value);
                        } else if (splittedFilter.length < 2 && (value !== JSON.stringify({ filter: event.srcElement.dataset.filter, value: event.srcElement.dataset.value }))) {
                            params.push(value);
                        }                     
                    }

                    // Update the current URL depending on the filters set
                    setSearchParams({"filters": params})
                }
            } else { 
                setSearchParams(searchParams.delete("filters")); // delete all filters from the URL as params
                props.setFiltersList([]) // and reset the reference list state as empty
            }
        }
    }, [props.filtersList, props.setFiltersList, searchParams, setSearchParams]);

    // Feed the 'select' DOM element, which is used, used to request with filters 
    const ListFilters = useMemo(() => {
        const options = []
        props.filters.forEach(opt => options.push(<option key={ opt.value } value={ opt.value }>{ opt.Name }</option>))

        return (
            <select id="filter" name="filter" className="form-select w-auto" onChange={(e) => setSelectedFilter(e.target.value) } defaultValue={ "" } required>
                <option value="" disabled>Filter...</option>
                { options }
            </select>
        )
    }, [props.filters])

    // Recomputes the filter form depending on the selected filter type 
    const FormFilter = useMemo(() => {
        const filter = props.filters.find((filter) => filter.value === selectedFilter && filter.type === "status");
        
        if (filter) {
            let statusList = [];
            switch (filter.value) {
                case "BOARDSTATUS":
                    statusList = BoardPb.BoardStatus;
                    break;
                case "GWSTATUS":
                    statusList = GwPb.GwStatus;
                    break;
                case "ITEMSTATUS":
                    statusList = ItemPb.ItemStatus;
                    break;
                default:
                    console.error("Unexpected building of status list for: " + filter.value);
                    break;
            }
        
            return (
                <select name="value" className="form-select w-auto" required>
                    {Object.entries(statusList).map((status) => (
                        <option key={status[1]} value={status[1]}>
                            {status[0]}
                        </option>
                    ))}
                </select>
            );
          } else {
            switch (selectedFilter) {
                case "SN": case "UID": case "BOARDSN":
                    return <input id="inputFilter" name="value" type="text" className="form-control w-auto" required />;
                case "LAST_EVENT": case "NO_ACTIVITY_AFTER": case "LAST_CONNECTION": case "LAST_GW_CONNECTION": case "MOMENTUM":
                    return <input id="inputFilter" name="value" type="datetime-local" className="form-control w-auto" required />;
                case "ID": case "GW":
                    return <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-text fw-bold">SN / Type: </span>
                            <input id="inputFilter" name="gwSn" placeholder="Serial number..." type="text"
                                className="form-control"
                                required
                            />
                            <input id="inputFilter" name="gwType" placeholder="Type..." type="text"
                                className="form-control"
                                required
                            />
                        </div>
                    </div>;
                default:
                    return null;
            }
        }
    }, [props.filters, selectedFilter])

    // Used to handle the displayed filters badges concording to those set & saved.
    useLayoutEffect(() => {
        let list = document.createElement('div')
        list.setAttribute('id', 'filters-list'); list.setAttribute('class', 'pt-3 pb-2');

        if(props.filtersList.length > 0) {
            props.filtersList.forEach(filter => {
                let badge = document.createElement('button') // Badge (node element) creation
                badge.setAttribute('type', 'button'); badge.setAttribute('class', filter.filter + ' btn btn-primary me-2 mb-2');

                // Badge heading filter
                let content = filter.filter === 'BOARDSTATUS' || filter.filter === 'GWSTATUS'  || filter.filter === 'ITEMSTATUS' 
                    ? '<b class="text-uppercase">status</b>'
                    : '<b class="text-uppercase">' + filter.filter.replace(/([a-z])([A-Z])/g, "$1 $2") + '</b>'

                var contentValue // Badge content value
                var dataValue = filter.filter === "ID" || filter.filter === "GW" ? filter.value.sn + " / " + filter.value.type : filter.value 

                if(filter.filter === 'BOARDSTATUS' || filter.filter === 'GWSTATUS' || filter.filter === 'ITEMSTATUS') {
                    switch(props.type) {
                        case "board" : // Retrieves the enum board status key text by its value
                            contentValue = Object.keys(BoardPb.BoardStatus).find(key => BoardPb.BoardStatus[key] === parseInt(filter.value));
                            break;
                        case "gateway" : // Retrieves the enum gateway status key text by its value
                            contentValue = Object.keys(GwPb.GwStatus).find(key => GwPb.GwStatus[key] === parseInt(filter.value));
                            break;
                        case "item" : // Retrieves the enum item status key text by its value
                            contentValue = Object.keys(ItemPb.ItemStatus).find(key => ItemPb.ItemStatus[key] === parseInt(filter.value));
                            break;
                        default:
                            console.error("Expected 'board', 'gateway' or 'item' as value but got :", props.type)
                    }
                } else contentValue = dataValue

                // Badge content
                badge.innerHTML = content + "<span class='badge bg-secondary ms-2 mx-2'>" + contentValue + "</span>"
                    + "<svg viewBox='0 0 20 20' class='ico-close' data-filter='"+ filter.filter +"' data-value='"+ dataValue +"' ><path d='M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z' data-filter='"
                    + filter.filter +"' data-value='"+ dataValue +"' ></path></svg>"
                
                list.appendChild(badge) // Then append each created badge to the DOM
            });
        }

        // Replace the oldest content of the filters list
        let defaultList = document.getElementById('filters-list')
        if(defaultList) { 
            defaultList.parentNode.replaceChild(list, defaultList)
        }

        // Then add actions to the added DOM elements
        let crosses = document.getElementsByClassName('ico-close');
        if(crosses)
            for (var i = 0; i < crosses.length; i++) {
                crosses[i].onclick = (e) => { removeFilters(e) } // Add the removable function to its concerned filter
            }

        if(document.getElementById("form-filter")) // Reset the form
            document.getElementById("form-filter").reset()

        setSelectedFilter("") // And finally reset the set selected filter value
    }, [props, removeFilters])

    return (
        <div className="row">
            { /* Custom filters (form, list of filters as badges, vanilla react table search system) */
                props.filters.length > 0 && <div className="col d-flex justify-content-between align-items-baseline mx-2">
                    <div className="custom-filters">
                        <form id="form-filter" className="d-flex justify-content-start align-items-center pt-4" onSubmit={getDataFiltered}>
                            
                            {/* Built filters list */}
                            { ListFilters }

                            {/* Rendered input filter */}
                            { FormFilter }

                            {/* Submit button for filtering */}
                            <button type="submit" className="submit-filters btn btn-primary">+</button>

                            {/* Delete button to remove all filters */}
                            { props.filtersList.length > 0 
                                ? <button type="button" className="remove-filters btn btn-danger" onClick={(e) => { removeFilters(e) }}>
                                    <BsFillTrashFill title="Remove" size={ 13 } style={{ cursor: "pointer" }} /></button> 
                                : <></> 
                            }
                        </form>

                        {/* Currently applied filters list */}
                        <div id="filters-list" className="pt-3 pb-2"></div>
                    </div>
                </div>
            }
            
            <div className="col mx-3 d-flex flex-row-reverse align-items-end">
                <button type="button" className="row btn btn-outline-secondary mb-3 form-control w-auto" onClick={props.buildDataCsv}>Export to CSV</button>
                <CSVLink ref={props.csvLink} data={props.dataCsv} headers={props.getHeadersCsv()} filename={'centiloc_results.csv'} target="_blank" />
            </div>
        </div>
    )
}

export default TableHeader;
