import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { TenantPb } from '@centiloc/centiloc-ops-api-admin-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'


const initialFields = {
    uid : "",
    newTenant : ""
}

const UpdateTenant = (props) => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ uid, newTenant }, setFields] = useState({...initialFields, uid: props.uid});
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, uid: props.uid }))
    }, [props.uid])

    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: value }));
    };

    return (
        <Form
            title={"Update item tenant"}
            response={"The tenant has been correctly changed"}
            fields={{ ...initialFields, uid: props.uid }}
            toggle={
                useCallback(() => { /* UpdateTenant */
                    const itemTenant = new TenantPb.ItemTenant();
                    itemTenant.setUid(uid)
                    itemTenant.setNewTenant(newTenant) // new owner of the item
                    
                    // Sends a request to update the item's tenant.
                    return new Promise((resolve, reject) => CLIENTS.admin.tenant.updateItem(itemTenant, dispatch, resolve, reject))
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="d-flex justify-content-start align-items-center">
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-start align-items-center">
                            <input name="uid" type="text" value={uid}
                                onChange={handleChange}
                                className="form-control w-75"
                                readOnly="readonly"
                                required 
                            />
                        </div>

                        <small id="uidHelp" className="form-text text-muted">Item UID.</small>
                    </div>
                    <div className="col-sm-6">
                        <input id="tenant" name="newTenant" type="text" value={newTenant}
                            onChange={handleChange}
                            className="form-control w-75" 
                            required
                        />
                        <small id="tenantHelp" className="form-text text-muted">New item tenant.</small>
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default UpdateTenant;