import * as types from "../actions.types";
import { formatAttributes } from "../../shared/utils";

import { ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc'

/**
 * @function itemMiddleware()
 * @returns next(action)
 * 
 * Middleware used to convert the returned items datasets before passing through the store.
 */
export function itemMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            switch(action.type) {
                case types.GET_ITEM_SUCCESS:
                    action.payload = formatAttributes(action.payload, "item");
                    break;  
                case types.GET_ALL_ITEM_SUCCESS:

                        // Loop on returned items to store their status as a status text
                        action.payload.itemsList.forEach((item) => { // Converts the status value as string
                            item.status = Object.entries(ItemPb.ItemStatus).find(([key, val]) => val === item.status)?.[0]
                        })
                    break;
                default:
                    return next(action, dispatch)
            }
            return next(action); // You should always return next(action) in your middleware.
            // If you forget to return this the application will stop, and no other action will reach the reducer.
        }
    }
}