import { default as Board } from "./geo/board"
import { default as FurnitureService } from "./inventory/FurnitureService"
import { default as Gateway } from "./geo/Gateway"
import { default as Item } from "./geo/Item"
import { default as Stream } from "./geo/stream"
import { default as Tenant } from "./admin/Tenant"

// APIs' barrel features, way to rollup exports,
//  from several modules into a single convenient one
//  which is itself a module file.
export default {
    admin:      { tenant: Tenant },
    geo:        { board: new Board(), gateway: Gateway, item: Item, stream: new Stream() },
    inventory:  { furniture: FurnitureService },
}