import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { Color3 } from "@babylonjs/core/Maths/math.color"
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Vector3 } from "@babylonjs/core/Maths/math";

// Panel (for hook) three-dimensional model.
export default class Panel {
    _mesh;
    _scene;
    _boards;
    _furniture;

    constructor(scene, boards, furniture) {
        this._scene     = scene
        this._boards    = boards
        this._furniture = furniture
    }

    async create() {
        if (this._mesh == null || this._mesh?.isDisposed()) {
            await new Promise(async(resolve) => {
                const data = await SceneLoader.ImportMeshAsync("", "/models/retail/", "panel.gltf", this._scene)
                for (let i = 0; i < data.meshes.length; i++) {
                    if (i > 2) {
                        data.meshes[i].dispose()
                    } else {
                        data.meshes[i].name = "panel"
                        data.meshes[i].isPickable = false
                        data.meshes[i].alwaysSelectAsActiveMesh = false
                    }
                }

                // === init retail panel setup =======================================
                data.meshes[0].scaling  = new Vector3(100, 450, 330)
                data.meshes[0].rotation = new Vector3(0, Math.PI/2, 0)

                resolve(data.meshes[0])
            }).then((panel) => {
                this._mesh = panel

                // === init board(s) setup =======================================
                const sizesP = this._mesh.getHierarchyBoundingVectors()
                const sizes = new Vector3(sizesP.max.x - sizesP.min.x, sizesP.max.y - sizesP.min.y, sizesP.max.z - sizesP.min.z)

                // -- texture
                const material = new StandardMaterial("board_material", this._scene)
                material.diffuseColor = Color3.Black()

                // -- positioning # the bottom left corner should be placed at the scene's origin location
                this._mesh.position = new Vector3(sizes.x / 1.8, sizes.y / 2, 0)

                // -- action manager
                const actionManager = new ActionManager(this._scene);
                actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev) { /* useful to pick the board and put a pointer cursor  */ }));

                // -- meshes (boxes)
                const shelvesCount = this._furniture.shelvesList.length;
                for (let i = 0; i < shelvesCount; i++) { /* dimensions of real-world object:  20||30cm(width) 6mm(height) 26mm(depth) */
                    const shelf     = this._furniture.shelvesList[i];
                    const curB      = this._boards.find((board) => board.serial == shelf.boardsList.find((b) => b.shelfSn == shelf.sn).serial);            

                    // TOCLEAR: As, for now, shelvesList.board does not return dimensions through inventory
                    curB.dimensionsMmList = [300]

                    const meshB     = MeshBuilder.CreateBox(curB.serial, { width: curB.dimensionsMmList[0], height: 3, depth: 30 }, this._scene);
                    const sizesB    = meshB.getHierarchyBoundingVectors();
                
                    // Pimped mesh properties
                    meshB.position.x                = this._mesh.position.x - sizes.x / 2 + (sizes.x / shelvesCount) * (i + (1 / shelvesCount));
                    meshB.position.y                = (sizes.y / 19) * 13 - 25;
                    meshB.position.z                = -(sizesB.max.x - sizesB.min.x) * 0.5;
                    meshB.rotation.y                = Math.PI / 2; 
                    meshB.material                  = material;      // texture(s)
                    meshB.isPickable                = true;          // interactive mesh
                    meshB.actionManager             = actionManager; // action(s) manager
                    meshB.alwaysSelectAsActiveMesh  = true;
                
                    // Finally add every newly instantiated board(s) 
                    this._scene.addMesh(meshB);
                }
            })

            return this._mesh
        }
    }
}