import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HighlightLayer } from "@babylonjs/core/Layers/highlightLayer";
import { Scene } from "@babylonjs/core/scene";
import { useHideClipboard, useUpdateHPick, useUpdateHProducts } from "../shared/hooks/views";
import { useItemEventHandler } from "../shared/hooks/item";
import { formatTime, outputLog } from "../shared/utils";
import { BoardPb, StreamingPb } from '@centiloc/centiloc-ops-api-geo-grpc';
import { FurniturePb } from  '@centiloc/centiloc-ops-api-inventory-grpc'
import { default as CLIENTS } from "../api/index";
import { default as ApiManager } from "../api/ApiManager";
import * as gRPC from 'grpc-web';
import InteractiveScene from "../components/InteractiveScene";
import BModel from "../models/Board";
import FModel from "../models/Furniture";
import PModel from "../models/Panel";
import SModel from "../models/Shelf";

import "../assets/style/scene.css";

interface DMOProps {
    setStreamError: React.Dispatch<React.SetStateAction<{ show: boolean; setupError: boolean; title: string; message: string }>>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setPick: React.Dispatch<React.SetStateAction<string>>;
    blister: boolean;
    board: BoardPb.BoardData;
    furniture: FurniturePb.Furniture;
}

// DMO renders an interacive scene(view) for Centiloc Active Detection Surface(s) within a furniture, 
//  as a hook (blister) or displayed singly.
const DMO: React.FC<DMOProps> = (props) => {
    const hideClipboard     = useHideClipboard(props.setIsOpen);
    const itemEventHandler  = useItemEventHandler(hideClipboard);
    const updateHPick       = useUpdateHPick();
    const updateHProducts   = useUpdateHProducts();

    // Returns the reference to the dispatch function from the redux store. May useful to dispatch actions
    const dispatch = useDispatch();

    // Returns a function that lets you navigate programmatically, able to redirect, refresh, ...
    const navigate = useNavigate();

    const streamRef = useRef<{ itemsEvents: any; moveOptions: StreamingPb.ItemMoveOptions; startAt: number | null; }>({ 
        itemsEvents: null,
        moveOptions: new StreamingPb.ItemMoveOptions(),
        startAt: null 
    });

    const [itemsUI, setItemsUI] = useState<any[]>([]);

    useEffect(() => {
        const cleanup = () => {
            if (streamRef.current.itemsEvents) {
                streamRef.current.itemsEvents.cancel();
            }

            const canvas = document.getElementById("my-canvas");

            if (canvas) {
                canvas.removeEventListener("click", null);
                canvas.removeEventListener("dblclick", null);
            }
        };

        const timeoutId = setTimeout(() => {
            if (streamRef.current.itemsEvents) {
                outputLog(["localhost", ".dev"], false, "Streaming cancelled by setTimeout");

                // Cancel subscription
                streamRef.current.itemsEvents?.cancel();
            }
            props.setStreamError({ show: true, setupError: false, title: "Session Timeout", message: "You've been timed out." });
        }, ApiManager.getDeadlineSec() * 1000);

        return () => {
            clearTimeout(timeoutId);
            cleanup();
        };
    }, [props.setStreamError]);

    // Arrange boards list according to each boards on shelf·ves or the unique one
    const boards = [ ...((props.furniture?.shelvesList || [])
        .flatMap(shelf => shelf.boardsList
            .filter(board => board.shelfSn === shelf.sn)
            .map(board => board)
        )),
        props.board
    ].filter(board => board)

    const width: number = !props.blister 
        ? props.furniture 
            ? Math.max(...props.furniture.shelvesList.map((shelf) => shelf.dimension.x)) 
            : props.board.dimensionsMmList[0]
        : 300

    const depth: number = !props.blister 
        ? props.furniture 
            ? Math.max(...props.furniture.shelvesList.map((shelf) => shelf.dimension.y)) 
            : props.board.dimensionsMmList[1]
        : 50
    const shelfNb         = props.furniture?.shelvesList?.length ?? 1;
    const furnitureHeight = shelfNb * depth;

    const onSceneReady = useCallback(async (scene: Scene) => {
        // Canvas (scene) reference through the DOM          // Layer used to highlight a picked mesh
        const canvas = document.getElementById("my-canvas"), HL = new HighlightLayer("HL", scene);
 
        // Cancel stream if init before re-request a newer and reset its state representation
        if (streamRef.current.itemsEvents) {
            streamRef.current.itemsEvents?.cancel()
        }
        
        // Starts to collect the items events
        boards.forEach(board => streamRef.current.moveOptions.addBoardSns(board.sn))
        streamRef.current.itemsEvents = await CLIENTS.geo.stream.streamItemsEvents(streamRef.current.moveOptions, dispatch)
        streamRef.current.startAt = Date.now()

        streamRef.current.itemsEvents.on('data', (response: StreamingPb.ItemMoveEvent) => {
            if (response.toObject()?.uid !== "") { // Ensure that's not the `keepAlive` event
                outputLog(["localhost", ".dev"], false, "Received item event (at : "+formatTime(response.toObject().momentum, true)+" ) : ", response.toObject())
                
                // Received event
                const retItem = response.toObject();
                
                // Received event handler
                if(!props.blister && retItem.shelfSn?.length > 0 && props.furniture?.shelvesList?.find((shelf) => shelf.id.shelfSn === retItem.shelfSn)) {
                    itemEventHandler(
                        dispatch,
                        {...props,
                            itemsUI: itemsUI,
                            setItemsUI: setItemsUI,
                            highlighter: HL,
                            updateHPick: updateHPick},
                        {...retItem, 
                            posMmList: retItem.posOnShelfMmList},
                        retItem.shelfSn,
                        scene);
                } else if (props.blister || props.board) {
                    itemEventHandler(
                        dispatch,
                        {...props,
                            itemsUI: itemsUI,
                            setItemsUI: setItemsUI,
                            highlighter: HL,
                            updateHPick: updateHPick},
                        {...retItem,
                            posMmList:retItem.posMmList},
                        retItem.boardSn,
                        scene);
                } else {
                    console.warn("An event has been skipped : ", retItem)
                }
            }
        })
        outputLog(["localhost", ".dev"], false, "Streaming started")

        streamRef.current.itemsEvents.on('error', (error) => { // on stream received error listener
            props.setStreamError({ show: true, setupError: false, title: "Session Timeout", message: "You've been timed out." }) // Set to show the modal error
            
            const deadline = ApiManager.getDeadlineSec()
            if (error.code === gRPC.StatusCode.DEADLINE_EXCEEDED || (deadline > 0 && (Date.now() - streamRef.current.startAt)/1000 >= deadline)) {
                outputLog(["localhost", ".dev"], false, "Streaming cancelled : ", error)
            } else {
                console.error("Streaming on error : ", error)
            }

            // Streaming cancellation - cleans the ressources used before leaving
            streamRef.current.itemsEvents.cancel()
        })

        // Build mesh(es)
        if (props.blister) { // For Blister
            await new PModel(scene, boards, props.furniture).create()
        } else {
            if (props.furniture) { // For shelf·ves
                for(const shelf of props.furniture.shelvesList) {
                    await new SModel(scene, shelf, shelfNb, furnitureHeight).create()
                }
                
                // For furniture
                new FModel(scene, width, furnitureHeight, depth).create()
            } else {
                await new BModel(scene, props.board).create()
            }
        }

        /* Get Items */
        const callback = async(err, response) => {
            if(!err) {
                outputLog(["localhost", ".dev"], false, "Board items got", response?.toObject()?.itemsList)

                // Received content handler
                response.toObject().itemsList.forEach((retItem: any) => {
                    if(!props.blister && retItem.shelfSn?.length > 0 && props.furniture?.shelvesList?.find((shelf) => shelf.id.shelfSn === retItem.shelfSn)) {
                        itemEventHandler(
                            dispatch,
                            {...props,
                                itemsUI: itemsUI,
                                setItemsUI: setItemsUI,
                                highlighter: HL,
                                updateHPick: updateHPick},
                            {...retItem, 
                                posMmList: retItem.posOnShelfMmList},
                            retItem.shelfSn,
                            scene);
                    } else if (props.blister || props.board) {
                        itemEventHandler(
                            dispatch,
                            {...props,
                                itemsUI: itemsUI,
                                setItemsUI: setItemsUI,
                                highlighter: HL,
                                updateHPick: updateHPick},
                            {...retItem,
                                posMmList:retItem.posMmList},
                            retItem.boardSn,
                            scene);
                    } else {
                        console.warn("An event has been skipped : ", retItem)
                    }
                });
            } else {
                console.error("Failed on getting the list of all the items `IN`", err)
                navigate(0) // Reload current page
            }
        }

        // GET surface(s)'(s) content
        if (props.furniture) {
            const id = new FurniturePb.FurnitureID();
            id.setSn(props.furniture.id.sn)

            // GET Items by furniture 
            CLIENTS.inventory.furniture.getItems(id, dispatch, callback)
        } else {
            const page =  new BoardPb.ItemsPagination()
            page.setSize(1000)

            const boardsIDWithPagination = new BoardPb.BoardsIDWithPagination();
            boardsIDWithPagination.setPage(page)
            boardsIDWithPagination.addSns(props.board.sn)
            
            // GET Items by board 
            CLIENTS.geo.board.getItems(boardsIDWithPagination, dispatch, null, null, callback)
        }

        // Build event listeners for this specific scene
        if (canvas) {
            canvas.addEventListener("click", (e: MouseEvent) => click(scene, HL, e));
            canvas.addEventListener("dblclick", dblclick.bind(null, scene), false);
        }
        document.querySelector('#sample-select').addEventListener('change', updateHProducts?.bind(null, scene, HL));
    }, [props, streamRef]);

    const click = (scene: any, HL: HighlightLayer, e: MouseEvent) => {
        if (e) e.preventDefault()
        if (scene == null) return

        const itemName = scene.pick(scene.pointerX, scene.pointerY)?.pickedMesh?.name
        if (itemName) {
            if (itemName == "box") { // not highlight box
                return
            }

            if (!hideClipboard(scene, itemName, HL)) {
                updateHPick(scene, itemName, itemsUI[itemName], HL)
                props.setPick(itemName);
                props.setIsOpen(true)
            }
            updateHProducts(scene, HL)
        }
    };

    const dblclick = (scene: any, e: React.MouseEvent) => {
        e?.preventDefault()

        if (scene == null) return

        const meshName = scene.pick(scene.pointerX, scene.pointerY)?.pickedMesh?.name;
        if (meshName) {
            if (boards.find((b) => b.hasOwnProperty("serial") ? meshName === b.serial : meshName === b.sn)) { // Redirects through the items filtered
                navigate("/board?filters=" + encodeURIComponent(JSON.stringify({ filter: "SN", value: meshName })))
            }

            if (itemsUI[meshName]) { // Redirects through the items filtered
                navigate("/item?filters=" + encodeURIComponent(JSON.stringify({ filter: "UID", value: meshName })))
            } 
        }
    };

    return (
        <InteractiveScene onSceneReady={onSceneReady}
            width ={width}
            height={props.blister 
                ? furnitureHeight
                : props.furniture ? furnitureHeight / shelfNb : depth * 1.5}
            depth={depth}
            data ={props.blister 
                ? boards
                : props.furniture ? props.furniture.shelvesList : props.board}
            blister={props.blister}
        />
    );
};

export default DMO;
