import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    dimX : "",
    dimY : "",
    marginX : "",
    marginY : "",
    noGeoloc : false,
}

const Create = () => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ sn, dimX, dimY, marginX, marginY, noGeoloc }, setFields] = useState(initialFields);

    // Changes the input value
    const handleChange = e => {
        const { name, value, checked } = e.target;
        setFields(fields => ({ ...fields, [name]: name == "noGeoloc" ? checked : value }));
    };

    return (
        <Form
            title={"Create"}
            response={"The board has been created successfully"}
            fields={initialFields}
            toggle={
                useCallback(() => { // Creates a new board.
                    const boardCreation = new BoardPb.BoardCreation();
                    boardCreation.setSn(sn)

                    let dimMm = []
                    if (dimX > 0 && dimY > 0) {
                        dimMm = [parseInt(dimX), parseInt(dimY)]
                    }
                    boardCreation.setDimensionsMmList(dimMm)

                    let marginsMm = []
                    if (marginX > 0 && marginY > 0) {
                        marginsMm = [parseInt(marginX), parseInt(marginY)]
                    }
                    boardCreation.setMarginsMmList(marginsMm)

                    boardCreation.setNoGeoloc(noGeoloc)

                    return new Promise((resolve, reject) => {
                        CLIENTS.geo.board.create(boardCreation, dispatch, resolve, reject /* EDIT: nest a GetAll() | dispatch */);
                    })
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-6">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="sn" type="text" value={sn}
                            onChange={handleChange}
                            className="form-control w-75"
                            required
                        />
                    </div>
                    <small id="serial_numberHelp" className="form-text text-muted">Board serial number</small>
                </div>

                {/* Dimensions list */}
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <input name="dimX" id="dimX" type="number" min="0" step="50" value={dimX} required={!dimY || dimY.length === 0 || isNaN(dimY) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="dimXHelp" className="form-text text-muted">Width (mm)</small>
                    </div>
                    <div className="col-sm-6">
                        <input name="dimY" id="dimY" type="number" min="0" step="50" value={dimY} required={!dimX || dimX.length === 0 || isNaN(dimX) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="dimYHelp" className="form-text text-muted">Height (mm)</small>
                    </div>
                </div>

                {/* Margins list */}
                <div className="d-flex align-items-center pt-3">
                    <div className="col-sm-6">
                        <input name="marginX" id="marginX" type="number" min="0" step="1" value={marginX} required={!marginY || marginY.length === 0 || isNaN(marginY) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="marginXHelp" className="form-text text-muted">Marge X (mm)</small>
                    </div>
                    <div className="col-sm-6">
                        <input name="marginY" id="marginY" type="number" min="0" step="1" value={marginY} required={!marginX || marginX.length === 0 || isNaN(marginX) ? false : true}
                            onChange={handleChange}
                            className="form-control w-75"
                        />
                        <small id="marginYHelp" className="form-text text-muted">Marge Y (mm)</small>
                    </div>
                </div>

                {/* Geolocation */}
                <div className="form-check pt-3">
                    <input id="no-geoloc" name="noGeoloc" type="checkbox" value={noGeoloc} 
                        checked={noGeoloc} 
                        onChange={handleChange}
                        className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="no-geoloc">Disable geolocation ?</label>
                </div>
                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default Create;