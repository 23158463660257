import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Link } from "react-router-dom";
import { StatusPill } from "../containers/Table";
import { CreateForm, PingForm, UnenrolBoardsForm, EnrolKnownBoardsForm, FindAndEnrolBoardForm, SetContinuousScanMode } from "../containers/forms/gateway";
import { default as Gangplank } from "../components/Gangplank.jsx";
import { getAllGateway } from "../redux/actions/gateway.actions"

export default () => {
    const dispatch = useDispatch();

    /** 
     * @constant gateways
     * @function useSelector()
     * 
     * Allows you to extract data from the Redux store state
     */
    const gateways = useSelector(state => state.gateway);
    const permissions = useSelector(state => state.user.permissions, shallowEqual);

    /**
     * @function useState()
     * 
     * The useState hook allows us to have state variables in the JSX functional component.
     * It takes one argument which is the initial state and returns a state value and a function to update it.
     */
    const [actions, setActions] = useState([]);

    useEffect(() => {
        const actionsList = []
        
        if(permissions["geo.gateway.ping"] === true) actionsList.push({ Label: "Ping", value: "ping", form: (sn, type) => { 
            return <PingForm sn={ sn } type={ type } />
        }})
        if(permissions["geo.gateway.unenrolBoards"] === true) actionsList.push({ Label: "Unenrol boards", value: "unenrolBoards", exceptions: [{ attribute: "status", value: "GW_DISCONNECTED" }], form: (sn, type) => { 
            return <UnenrolBoardsForm sn={ sn } type={ type } />
        }})
        if(permissions["geo.gateway.enrolKnownBoards"] === true) actionsList.push({ Label: "Enrol known boards", value: "enrolKnownBoards", exceptions: [{ attribute: "status", value: "GW_DISCONNECTED" }], form: (sn, type) => { 
            return <EnrolKnownBoardsForm sn={ sn } type={ type } />
        }})
        if(permissions["geo.gateway.findAndEnrolBoard"] === true) actionsList.push({ Label: "Find and enrol board", value: "findAndEnrolBoard", exceptions: [{ attribute: "status", value: "GW_DISCONNECTED" }], form: (sn, type) => { 
            return <FindAndEnrolBoardForm sn={ sn } type={ type } />
        }})
        if(permissions["geo.gateway.setContinuousScanMode"] === true) actionsList.push({ Label: "Set continuous scan mode", value: "setContinuousScanMode", form: (sn, type, continuousScanModeEnabled) => { 
            return <SetContinuousScanMode sn={ sn } type={ type } continuousScanModeEnabled={ continuousScanModeEnabled } />
        }})

        if (JSON.stringify(actions) !== JSON.stringify(actionsList)) {
            setActions(actionsList)
        }
    }, [dispatch, permissions, actions])
    
    return <Gangplank type={ "gateway" } getAll={ getAllGateway }
        payload={ gateways }
        columns={ columns }
        filters={ filters }
        actions={ actions }
        createForm={ <CreateForm /> }
    />
}

/**
 * @constant filters
 * Data defining a filter for gateways
 */
const filters = [
    { Name: "ID", type: "text", value: "ID" },
    { Name: "Status", type: "status", value: "GWSTATUS" },
    { Name: "Last connection", type: "date", value: "LAST_GW_CONNECTION" }
]

/**
 * @constant columns
 * Dataset of the table columns content settings
 */
const columns = [
    { Header: "SN/Type", value: "ID", isSortable: true, accessor: "id",
        Cell: (props) => ( // Mandatory to handle the multiple string values according to the gateway's Id
            props.cell.row.original.id !== undefined ? props.cell.row.original.id.sn + "/" + props.cell.row.original.id.type : ""
        )
    },
    { Header: "Status", accessor: "status", value: "GWSTATUS", isSortable: true, Cell: StatusPill },
    { Header: "Last connection", accessor: "lastConnection", value: "LAST_GW_CONNECTION", isSortable: true },
    { Header: "Boards", accessor: "boardsList.length", isSortable: false, 
        Cell: (props) => ( // Link redirecting to "/board" and filter the table by gateway ID
            <Link to={ "/board?filters="+ encodeURIComponent(JSON.stringify({ filter: "GW", value: props.cell.row.original.id })) } style={{ cursor: "pointer" }}>see boards</Link>
        ) 
    }
]