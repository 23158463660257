import * as BABYLON from "@babylonjs/core";

 class Board {
    _scene;
    _board;
     
    mesh;
    constructor(scene, board) {
        this._scene = scene
        this._board = board
    }

    create() {
        const width  = this._board.dimensionsMmList[0]
        const height = width / 25
        const depth  = this._board.dimensionsMmList[1]

        // Create board (board as a box) & settings
        const board = BABYLON.MeshBuilder.CreateBox(this._board.sn, { width: width, height: height, depth: depth }, this._scene)
        board.material = new BABYLON.StandardMaterial("materia", this._scene) 
        board.material.diffuseColor = BABYLON.Color3.Black()

        // Set the shelf's boards list as metadata to the mesh
        board.metadata = {...this._board, dimension: {x: this._board.dimensionsMmList[0], y: this._board.dimensionsMmList[1], z: 0}}

        board.position.x = width  / 2
        board.position.y = height / 2
        board.position.z = depth  / 2

        board.isPickable = true;
        board.actionManager = new BABYLON.ActionManager(this._scene);
        board.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function(ev) { /* useful to pick the board and put a pointer cursor  */ }));

        return this._scene.addMesh(board)
    }
}

export default Board;
