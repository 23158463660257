import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { outputLog } from "../../../shared/utils"
import { default as CLIENTS } from "../../../api/index"

import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form, InputPassword } from "../../../components/form/index"

import '../../../assets/style/layout/form.css'

const initialFields = {
    sn: "",
    version: "",
    mqttURL: "",
    ignore: false,
    qos: 0,
    username: "",
    pwd: "",
    jsonConfig: ""
}

const MqttConfig = (props) => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ sn, version, mqttURL, ignore, qos, username, pwd, jsonConfig }, setFields] = useState({...initialFields, sn: props.sn, version: props.fwVersion});
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn, version: props.fwVersion }))
    }, [props.sn])
    
    // Sets up an initial state to manage the HTML form input values and visual tools depending on its lifecycle state
    const [goto, setGoto]     = useState([]);
    const [config, setConfig] = useState();
    const [focus, setFocus]   = useState(null);

    useEffect(() => {
        if (version !== undefined && version.length !== 0 && 
            Number(version.slice(1).substring(0, version.indexOf('.')-1)) < 5) { // LEGACY
            fetch('json/mqtt_configs_legacy.json').then(response => {
                response.json().then(data => {
                    setConfig(data)
                })
            })
        } else {
            fetch('json/mqtt_configs.json').then(response => {
                response.json().then(data => {
                    setConfig(data)
                })
            })
        }
    }, [])

    useEffect(() => {
        if (config == undefined) {
            return
        }
        
        if (window.CLOUD_ENV != undefined && window.CLOUD_ENV.length != 0) {
            const isCentiloc = window.KEYCLOAK_REALM !== undefined && window.KEYCLOAK_REALM == "centiloc"
            const tmpGoTo = []
            switch (window.CLOUD_ENV) {
                case 'dev':
                    if (isValidConf(config.staging)) {
                        tmpGoTo.push('STAGING')
                    }
                    if (isValidConf(config.prod)) {
                        tmpGoTo.push('PROD')
                    }
                    // if (isValidConf(config.labo)) {
                    //     tmpGoTo.push('LABO')
                    // }
                    // if (isValidConf(config.root)) {
                    //     tmpGoTo.push('ROOT')
                    // }
                    break;
                case 'staging':
                    if (isValidConf(config.dev) && isCentiloc) {
                        tmpGoTo.push('DEV')
                    }
                    if (isValidConf(config.prod)) {
                        tmpGoTo.push('PROD')
                    }
                    break;
                case 'prod':
                    if (isValidConf(config.dev) && isCentiloc) {
                        tmpGoTo.push('DEV')
                    }
                    if (isValidConf(config.staging)) {
                        tmpGoTo.push('STAGING')
                    }
                    break;
                // case 'root':
                //     if (isValidConf(config.dev) && isCentiloc) {
                //         goto.push('DEV')
                //     }
                //     if (isValidConf(config.labo)) {
                //         goto.push('LABO')
                //     }
                //     if (isValidConf(config.staging)) {
                //         goto.push('STAGING')
                //     }
                //     if (isValidConf(config.prod)) {
                //         goto.push('PROD')
                //     }
                //     break;
                // case 'labo':
                //     if (isValidConf(config.dev) && isCentiloc) {
                //         goto.push('DEV')
                //     }
                //     if (isValidConf(config.root)) {
                //         goto.push('ROOT')
                //     }
                //     break;
                default:
                    logByHost(["localhost", ".dev"], true, "Unexpected cloud environment variable: " + window.CLOUD_ENV)
            }
            setGoto(tmpGoTo)
        } else {
            outputLog(["localhost", ".dev"], false, "No cloud environment variable given, disable 'go to' buttons")
        }
    }, [config])

    // Changes the input value
    const handleChange = e => {
        const { name, value, checked } = e.target;

        setFields(fields => ({ 
            ...fields,
            [name]: 
                name === "qos" ? Number(value) 
                : name === "ignore" ? checked 
                : value
        }));
    };

    const isValidConf = (conf) => {
        return (conf != undefined && conf.url != undefined && conf.url.length > 0)
    }

    let url = "";
    let noTrust = false;
    let qoS = 0; // level 2
    let userName = "";
    let passwd = "";
    const setVarFromJson = (conf) => {
        url = conf.url;
        
        if (conf.username !== undefined) {
            userName = conf.username;
        }
        if (conf.password !== undefined) {
            passwd = conf.password;
        }
        if (conf.ignore !== undefined) {
            noTrust = conf.ignore;
        }
        if (conf.qos !== undefined) {
            qoS = conf.qos;
        }
    }

    return (
        <Form
            title={"Set Mqtt URL"}
            fields={{...initialFields, sn: props.sn}}
            toggle={
                useCallback((event) => {
                    const submitButtonValue = event.nativeEvent.submitter.value;
                
                    return new Promise((resolve, reject) => { /* MqttURL */
                        if (submitButtonValue === 'form') { // Basic form
                            url = mqttURL
                            noTrust = ignore
                            qoS = qos
                            userName = username
                            passwd = pwd
                        } else if (submitButtonValue === 'json') { // JSON input
                            try {
                                setVarFromJson(JSON.parse(jsonConfig)) // Update var from JSON
                    
                                if (url == undefined || url?.length == 0) {
                                    reject({message: "Invalid JSON format"})
                                }
                            } catch(e) {
                                reject({err: e, message: "Invalid JSON format"})
                            }
                        } else { // GoTo
                            const env = submitButtonValue.toLowerCase()
                    
                            try {
                                setVarFromJson(config[env])
                            } catch(e) {
                                reject({err: e, message: "Internal error, contact Centiloc support"})
                                
                                if (["dev", "staging", "prod", "labo", "root"].includes(env)) {
                                    console.error("Bad JSON configuration for " + env + " environment")
                                } else {
                                    console.error("JSON configuration for " + env + " environment")
                                }
                            }
                        }
            
                        const arg = new BoardPb.BoardMqttURL();
                        arg.setSn(sn)
                        arg.setUrl(url)
                        arg.setIgnoreServerTlsVerification(noTrust)
                        arg.setQos(qoS)
                        arg.setUsername(userName)
                        arg.setPwd(passwd)
                        
                        // SetMqttURL sends a request to the board for setting the MQTT broker URL.
                        CLIENTS.geo.board.setMqttURL(arg, dispatch, resolve, reject)
                    })
                })
            }
            setFields={setFields}
            setFocus={setFocus}
            goto={goto}
            // reboot={true}
        >
            <fieldset>
                <div className="form-group mx-4 mt-3 mb-2">
                    <div className="col-sm-10">
                        <div className="d-flex justify-content-start align-items-center">
                            <input name="sn" type="text" value={sn}
                                onChange={handleChange}
                                className="form-control w-auto"
                                readOnly="readonly"
                                required={focus === "formConfig" ? true : false}
                            />
                        </div>
                        <small id="serial_numberHelp" className="form-text text-muted">Board serial number</small>
                    </div>
                </div>
                <div className="d-flex align-items-center form-group mx-4 mt-3 mb-2">
                    <div className="col-sm-10">
                        <input name="mqttURL" type="text" placeholder="mqtt(s)://domain:port" value={mqttURL}
                            onChange={handleChange}
                            className="form-control w-100"
                            required={focus === "formConfig" ? true : false}
                        />
                    <small id="mqtt_URLHelp" className="form-text text-muted">MQTT broker URL</small>
                    </div>
                </div>
                {version !== undefined && version.length !== 0 && Number(version.slice(1).substring(0, version.indexOf('.')-1)) >= 5 ?
                    <>
                        <div className="form-check mx-4 mt-3 mb-2">
                            <input name="ignore" id="ignore" type="checkbox" value={ignore}
                                className="form-check-input mt-2"
                                onChange={handleChange}
                            />
                            <label id="ignoreHelp" htmlFor="ignore" className="form-text text-muted">Ignore server verification (only for TLS)</label>
                        </div>
                        <div className="d-flex align-items-center form-group mx-4 mt-3 mb-2">
                            <div className="col-sm-10">
                                <select name="qos" type="text" value={qos}
                                    className="form-control w-100"
                                    onChange={handleChange}
                                >
                                    { Object.entries(BoardPb.QoSLevel).map(([key, val]) => ( <option key={val} value={val}>{key}</option> )) }
                                </select>
                                <small id="qosHelp" className="form-text text-muted">QoS</small>
                            </div>
                        </div>
                    </>
                : null}
                <div className="d-flex align-items-center form-group mx-4 mt-3 mb-2">
                    <div className="col-sm-10">
                        <input name="username" type="text" autoComplete="username" value={username}
                            onChange={handleChange}
                            className="form-control w-100"
                            required={focus === "formConfig" ? true : false}
                        />
                    <small id="userHelp" className="form-text text-muted">Username</small>
                    </div>
                </div>
                <div className="d-flex align-items-center form-group mx-4 mt-3 mb-2">
                    <div className="col-sm-10">
                        <InputPassword value={pwd} id="password" name="pwd" 
                            onChange={handleChange}
                            className="form-control"
                            required={focus === "formConfig" ? true : false}
                        />
                        <small id="pwdHelp" className="form-text text-muted">Password</small>
                    </div>
                </div>
                <button type="submit" value="form" className="mx-4 mb-2 btn btn-primary" onClick={() => setFocus("formConfig")}>OK</button>
            </fieldset>

            <div className="form-group mt-2">
                <div className="d-flex justify-content-center">OR set by JSON</div>
            </div>

            <fieldset>
                <div className="form-group mx-4 mt-2 mb-2">
                    <div className="col-sm-12">
                        <textarea name="jsonConfig" rows="6" cols="100" value={jsonConfig}
                            onChange={handleChange}
                            className="form-control w-100"
                            required={focus === "jsonConfig" ? true : false}
                        />
                        <small id="configHelp" className="form-text text-muted">JSON configuration</small>
                    </div>
                </div>
                <button type="submit" value="json" className="mx-4 mb-2 btn btn-primary" onClick={() => setFocus("jsonConfig")}>OK</button>
            </fieldset>
        </Form>
    );
}

export default MqttConfig;