import '@babylonjs/loaders'

import { Color3 } from "@babylonjs/core/Maths/math.color"
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

/**
 * @name EcocupModel
 * @extends AbstractModel
 * 
 * Model of meshes as items as pots, linked tools & static functionnalities representing its movement events
 */
class EcocupModel extends AbstractModel {
    dimensions = new Vector3(31.75, 40, 31.75);
    heightMm = 90

    buildInternalMesh(scene) {
        return new Promise(async(resolve) => {
            const data = await SceneLoader.ImportMeshAsync("", "/models/ecocup/", "ecocup.obj", scene);
            const meshes = data.meshes;

            const material = new PBRMaterial("ecocup");
            material.diffuseColor = new Color3(1, 1, 1)
            material.reflectionTexture = new Texture('/models/ecocup/glass-texture.png');
            material.reflectivityColor = new Color3(0.1, 0.1, 0.1);
            material.albedoColor = new Color3(0.95, 0.95, 0.95);

            meshes[1].material = material;

            meshes[2].dispose()
            meshes[3].dispose() // Remove the lid from the cup

            this.mesh = meshes[1]

            resolve(this)
        })
    }
}

export default EcocupModel;
