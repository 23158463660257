import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

import '@babylonjs/loaders'

abstract class AbstractModel {
    mesh:       AbstractMesh | null;
    name:       string;
    meshName:   string;
    dimensions  = new Vector3(0, 0, 0);
    rotation    = new Vector3(0, 0, 0);
    offsets     = new Vector3(0, 0, 0);
    heightMm    = 0;
    hookable    = false

    constructor(name: string) {
        this.mesh       = null
        this.name       = name
        this.meshName   = ""
    }

    setMeshName(meshName: string) {
        this.meshName = meshName
    }

    getMesh(scene: Scene){
        if (this.mesh == null || this.mesh?.isDisposed()) {
            return this.buildInternalMesh(scene)
        }

        return Promise.resolve({mesh: this.mesh.clone(this.name, null, false), hookable: this.hookable })
    }
    
    abstract buildInternalMesh(scene: Scene): Promise<{}>;
}

export default AbstractModel;