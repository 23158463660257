import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../redux/root.reducer'; // Import the RootState from your Redux store
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaBars } from 'react-icons/fa';
import { getFurniture } from "../redux/actions/furniture.actions";
import { RenderProvider, useRenderContext } from "../contexts/RenderContext";
import { UserProvider } from "../contexts/UserContext";
import Nav from "../components/layouts/Nav"
import Profile from "../components/Profile";
import CentilocLogo from '../assets/img/logo-centiloc.svg'
import BlisterLogo from '../assets/img/warehouse.png'

import '../assets/style/layout/header.css'

interface HeaderProps {
    accessManaged: boolean;
}

// Implementation of the navigation view, as a sidebar, between pages
const Header: React.FC<HeaderProps> = ({ accessManaged }) => {
    const dispatch = useDispatch()

    const [sidebar, setSidebar] = useState(false);

    // Set the reverse value used to show the sidebar
    const handleSidebarToggle = () => setSidebar(!sidebar);
    
    return (
        <header>
            <IconContext.Provider value={{ color: '#000' }}>
                <div className='headbar'>
                    <span className={sidebar ? 'headbar-container active' : 'headbar-container'}>
                        <FaBars className='sidebar-toggler' size={35} onClick={ handleSidebarToggle } />

                        {/* CENTILOC LOGO */}
                        <div className={ sidebar ? 'headbar-logo active' : 'headbar-logo' }>
                            <Link to={ "/" } onClick={() => setSidebar(false)}>
                                <img src={ CentilocLogo } alt="main-logo"></img>
                            </Link>
                        </div>
                    </span>

                    {/* <div className="d-flex justify-content-end align-items-center bottom-0 top-0 end-0"> */}
                    <div className="headbar-container">
                        <RenderProvider> {/* Hook demo furniture */}
                            <HeaderFurniture dispatch={ dispatch } />
                        </RenderProvider>
                        
                        <UserProvider> {/* User profile */}
                            <Profile dispatch={ dispatch } accessManaged={ accessManaged } />
                        </UserProvider>
                    </div>
                </div>

                {/* Naigation component */}
                <Nav sidebar={ sidebar } handleSidebarToggle={ handleSidebarToggle }/>
            </ IconContext.Provider>
        </header>
    )
}

const sn = "DemoHook"
const HeaderFurniture: React.FC<{ dispatch: ThunkDispatch<RootState, any, any> }>= ({ dispatch }) => {

    // Allows you to extract data from the Redux store state, using a selector function
    // In this case, it retrieves the user's permission and extracts the demo furniture
    const permissions = useSelector((state: RootState) => state.user.permissions, shallowEqual);
    const furniture   = useSelector((state: RootState) => state.furniture.list.find(furniture => furniture.id?.sn === sn));

    const { isTrial, setTrialStatus } = useRenderContext();

    // Initial demo furniture recovering (if allowed) plus update the 'render' context according to what's received
    useEffect(() => {
        if (permissions["inventory.furniture.get"] && typeof furniture == "undefined") {
            dispatch(getFurniture(sn));
        } else if (furniture) {
            setTrialStatus(true)
        }
    }, [dispatch, permissions, furniture])

    return (
        isTrial && <div className="box px-2">
            <div className="first-in-box">
                <span>Demo blister</span>
            </div>
            <div className="second-in-box">
                <Link className="row justify-content-center" to={"/demo?filters=" + encodeURIComponent(JSON.stringify({ furniture_sn: sn }))}>
                    <img src={ BlisterLogo } style={{ width: '80%', height: '80%', transform: 'scaleX(-1)' }} alt="blister"/>
                </Link>
            </div>
        </div>

    )
}

export default Header