import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BiTransferAlt } from 'react-icons/bi';
import { FiDelete } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
import { ProgressBar } from 'react-bootstrap';
import { getClient, captureException } from "@sentry/react";
import { TenantPb } from '@centiloc/centiloc-ops-api-admin-grpc'
import { default as CLIENTS } from "../api/index"

import { Layout } from "../components/layouts/Layout.js";
import '../assets/style/tenant.css'

export default () => {

    /** 
     * @function useState()
     * The useState hook allows us to have state variables in the JSX functional component.
     * It takes one argument which is the initial state and returns a state value and a function to update it.
     * 
     * Used to handle the dissociation of the forms by focus, values & ability (disable or not) fields, the progress of streamed actions
     */
    
    /** Transfer dataset */ 
    const [activeTransfer, setActiveTransfer] = useState(true);
    const [progressTransfer, setProgressTransfer] = useState(0);
    const [transferInProgress, setTransferInProgress] = useState(false);
    const [toReplace, setToReplace] = useState("");
    const [newTenant, setNewTenant] = useState("");

    /** Delete dataset */
    const [activeDelete, setActiveDelete] = useState(false);
    const [progressDelete, setProgressDelete] = useState(0);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [handleAuthentication, setHandleAuthentication] = useState(false);
    
    const dispatch = useDispatch();
    
    const handleSubmit = (event) => {
        if(event) {
            event.preventDefault();
        }
    
        // Initialization of the gRPC request as Transfer or Delete (determined by the form submited)
        try {
            if(event.target.id === "transfer-tenant") { // Transfer stream request
                const tenantTransfer = new TenantPb.TenantTransfer();
                tenantTransfer.setOldTenant(toReplace)
                tenantTransfer.setNewTenant(newTenant)

                const streamTransfer = CLIENTS.admin.tenant.transfer(tenantTransfer, dispatch)

                setTransferInProgress(true)
                setProgressTransfer(1)
    
                // Listening “data” events in the call object to see any messages in the flow from the server.
                // Defines the progress of the transfer
                streamTransfer.on('data', (response) => {
                    setProgressTransfer(response.array[0])
                })
        
                streamTransfer.on('error', (error) => {
                    console.error("streamTransfer on.error :", error)
        
                    streamTransfer.cancel() // Force the closing stream
                })
            }

            if(event.target.id === "delete-tenant") { // Delete stream request
                const deleteTransfer = new TenantPb.TenantDeletion();
                deleteTransfer.setTenant(toDelete)
                deleteTransfer.setDeleteFromAuth(handleAuthentication)

                const streamDelete = CLIENTS.admin.tenant.delete(deleteTransfer, dispatch)
                
                setDeleteInProgress(true)
                setProgressDelete(1)
    
                // Listening “data” events in the call object to see any messages in the flow from the server.
                // Defines the progress of the delete
                streamDelete.on('data', (response) => {
                    setProgressDelete(response.array[0])
                })
        
                streamDelete.on('error', (error) => {
                    console.error("streamDelete on.error :", error)
        
                    streamDelete.cancel() // Force the closing stream
                })
            }
        } catch(err) {
            console.error(err);

            if(getClient()?.getOptions()?.enabled)
                captureException(err)
        }
    }

    return (
        <Layout>
            <div id="back-tenant" className="d-flex justify-content-center">
                <div id="tenant-content" className="container shadow">
                    <div className="row justify-content-md-center">
                        <div className="form-header col-md-8 py-5">
                            <p className="text-align-justify">This view allows to modify the <b>tenant of any data.</b></p>
                            <p>To manage tenants and users informations / access, please go to <a href="#">Keycloak</a>.</p> {/* TODO URL Keycloak */}
                        </div>
                        <div className="col-md-8 pt-2">

                            {/* Transfer form */}
                            <form id="transfer-tenant" className="form-speech" onSubmit={(e) => handleSubmit(e) } onClick={() => { setActiveTransfer(true); setActiveDelete(false) } }>
                                <h3 className="d-flex align-items-center text-uppercase fw-bold col-sm-12 ps-3 py-1"><BiTransferAlt />Transfer</h3> <hr/>

                                <fieldset className={ "d-flex align-items-center justify-content-center flex-column " + ( activeTransfer ? "active-fieldset" : "inert-fieldset" )}>
                                    <p className="form-speech-text">
                                        <span className="title-row">Transfer data from </span>
                                        <input name="tenant_to_replace" type="text" className="form-control w-auto" placeholder="Tenant to replace" 
                                            value={ toReplace }
                                            disabled={ !activeTransfer ? true : false }
                                            autoFocus={ !activeTransfer ? true : false }
                                            onChange={e => setToReplace(e.target.value)}
                                            required
                                        /> to <input name="new_tenant" type="text" className="form-control w-auto" placeholder="New tenant" 
                                            value={ newTenant }
                                            disabled={ !activeTransfer ? true : false }
                                            onChange={e => setNewTenant(e.target.value)}
                                            required
                                        />

                                        {/* Submit transfer */}
                                        <button type="submit" className={ "pb-2 mx-3 submit-filters btn " + ( !activeTransfer ? "btn-light" : "btn-primary" )}><FaCheck /></button>
                                    </p>
                                </fieldset>
                            </form>

                            {/* Transfer progress bar */}
                            <div className="transfer-progress-bar progressBar my-5" style={{ visibility : !transferInProgress ? "hidden" : "visible" }} >
                                <div className="form-title d-flex align-items-center bg-secondary bg-opacity-10 col-sm-12">
                                    <span className="text-uppercase fw-bold mx-4">Progress :</span>
                                </div>
                                <div className="progress-content col-sm-12 px-5 pt-3 pb-2">
                                    <div className="d-flex justify-content-between">
                                        <small><b className="text-uppercase">Replaced :</b> { toReplace }</small>
                                        <small><b className="text-uppercase">Newest :</b> { newTenant }</small>
                                    </div>
                                    <ProgressBar now={progressTransfer} label={`${progressTransfer}%`} animated={ progressTransfer < 100 ? true : false }/>
                                </div>
                            </div>

                            {/* Delete form */}
                            <form id="delete-tenant" className="form-speech pt-4" onSubmit={(e) => handleSubmit(e) } onClick={() =>  { setActiveTransfer(false); setActiveDelete(true) } }>
                                <h3 className="d-flex align-items-center text-uppercase fw-bold col-sm-12 ps-3 py-1"><FiDelete />Delete</h3> <hr/>

                                <fieldset className={ "d-flex align-items-center justify-content-center flex-row " + ( activeDelete ? "active-fieldset" : "inert-fieldset" )}>
                                    <p className="form-speech-text"><span className="title-row">Delete </span>
                                        <input name="tenant_to_delete" type="text" className="form-control w-auto" placeholder="Tenant" 
                                            value={ toDelete }
                                            disabled={ !activeDelete ? true : false }
                                            autoFocus={ !activeDelete ? true : false }
                                            onChange={e => setToDelete(e.target.value)}
                                            required
                                        /> 's data and from authentication system too 
                                            <div className="form-check form-switch float-end ps-5 pt-2">
                                                <input name="auth_to_delete" className="form-check-input form-control w-auto" type="checkbox" 
                                                    defaultChecked={ handleAuthentication } 
                                                    onChange={e => setHandleAuthentication(e.target.checked)}
                                                    disabled={ !activeDelete ? true : false }
                                                />
                                            </div>
                                    </p>

                                    {/* Submit delete */}
                                    <button type="submit" className={"submit-filters btn " + ( !activeDelete ? "btn-light" : "btn-primary" )}><FaCheck /></button>
                                </fieldset>
                            </form>

                            {/* Delete progress bar */}
                            <div className="delete-progress-bar progressBar mt-5" style={{ visibility : !deleteInProgress ? "hidden" : "visible" }} >
                                <div className="form-title d-flex align-items-center bg-secondary bg-opacity-10 col-sm-12">
                                    <span className="text-uppercase fw-bold mx-4">Progress :</span>
                                </div>
                                <div className="progress-content col-sm-12 px-5 pt-3 pb-2 ">
                                    <div className="d-flex justify-content-end">
                                        <small><b className="text-uppercase">Deleted :</b> { toDelete }</small>
                                    </div>
                                    <ProgressBar now={progressDelete} label={`${progressDelete}%`} animated={ progressDelete < 100 ? true : false } />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}