import * as boardTypes from '../actions.types'

/**
 * @constant INITIAL_STATE
 * 
 * Initial boards state structure.
 */
const INITIAL_STATE = {
    list: [],
    links: {first: undefined, previous: undefined, next: undefined, last: undefined},
    pagination: {size: 25, page: 1, desc: false, sort: 0},
    count: 0
}

/**
 * @constant reducer
 * @param { initial board state } state
 * @param { action } action Actions are the only source of information for the store
 * @returns state
 * 
 * Build the boards state.
 */
const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {

        /** BOARDS ACTIONS **/
        case boardTypes.CREATE_BOARD_SUCCESS : // Succeed Board.create action result
            if(!state.list.find(board => board.sn === action.payload.sn)) {
                return {
                    ...state, list: [{ sn: action.payload.sn, status: "DISCONNECTED", dimensionsMmList: action.payload.dimensionsMmList, marginsMmList: action.payload.marginsMmList }].concat(state.list)
                }
            } else {
                console.error('Inconsistency data, try to dupplicate an entry already stored with the serial number : ' + action.payload.sn)
            }
            break;
        case boardTypes.UPDATE_BOARD_SUCCESS : // Succeed Board.update action result
            if(state.list.find(board => board.sn === action.payload.sn)) { // Concatenate the previous state with the returned payload and replace same occurencies by the new returned dataset 
                return {
                    ...state, list: [...state.list.filter((b) => b.sn !== action.payload.sn), action.payload]
                }
            }
            break;
        case boardTypes.DELETE_BOARD_SUCCESS : // Succeed Board.delete action result
            return {
                ...state, list : state.list.filter((board) => board.sn !== action.payload.sn)
            }
        case boardTypes.GET_BOARD_SUCCESS : // Succeed Board.get action result
            if(!state.list.find(board => board.sn === action.payload.sn)) { // While none dupplication, juste concatenate the store with the returned payload
                return {
                    ...state, list: state.list.concat(action.payload)
                }
            } else { // Concatenate the previous state with the returned payload and replace same occurencies by the new returned dataset 
                return {
                    ...state, list: [...state.list.filter((b) => b.sn !== action.payload.sn), action.payload]
                }
            }
        case boardTypes.GET_ALL_BOARD_SUCCESS : // Succeed Board.getAll action result
            return {
                list: action.payload.boardsList,
                links: { ...state.links, ...action.payload.links },
                pagination: { ...state.pagination, ...action.payload.pagination },
                count: !isNaN(action.payload.count) ? action.payload.count : 0
            }
        default :
            return { ...state }
    }
}

export { reducer }