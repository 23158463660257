import React from 'react';
import { Modal } from "react-bootstrap";

interface CustomModalProps {
    show:     boolean;
    title:    string;
    message:  string;
    footer?: React.ReactNode[];
}

const CustomModal: React.FC<CustomModalProps> = ({ show, title, message, footer }) => (
    <Modal show={show} backdrop="static">
        <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="alert">
                { message }
            </div>
        </Modal.Body>
        { footer }
    </Modal>
);

export default CustomModal;
