import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';

import configureAppStore from "./src/redux/store/config"; // Custom configured Redux store
import Sentry from './src/sentry';
import KC from "./src/keycloak"
import App from './src/App';

import { validEnv } from './src/shared/utils';
import 'bootstrap/dist/css/bootstrap.min.css';

// Store setup ready to use to pass to the whole React application
const store = configureAppStore();

const AppProvider = (accessManaged: boolean) => createRoot(document.getElementById('root')).render(
    <Provider store={store}> {/* Makes the Redux store to any nested components that need to access the Redux store. */}
        {/* <React.StrictMode> {/* Tool for highlighting potential problems in the application */}
            <App accessManaged={accessManaged} />
        {/* </React.StrictMode> */}
    </Provider>
);

// Conditional Sentry configuration & initialisation (Error tracker)
if ((window as any).SENTRY_URL && validEnv("SENTRY_URL", /^http(s)?:\/\/.*/)) {
    Sentry.initSentry(); // Initialization of Sentry before rendering our app.
}

// Conditional Keycloak configuration & setup (Authentication system)
if ((window as any).KEYCLOAK_URL && validEnv("KEYCLOAK_URL", /^http(s)?:\/\/.*/)) {
    KC.initKeycloak(AppProvider); // Initialize Keycloak when rendering the React.
} else {
    AppProvider(false)
    console.log('ON PREMISE setup');
}