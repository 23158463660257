import * as types from '../../redux/actions.types'
import { BoardWeb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { getClient, captureException } from "@sentry/react";
import { default as ApiManager } from '../ApiManager'

/**
 * @name Board
 * @extends ApiManager
 * 
 * Client of Centiloc's API Geo over Board service.
 */
export default class Board extends ApiManager {
    constructor() {
        super(); // Mandatory with extended classes

        try {
            this.client = new BoardWeb.BoardClient(window.API_URL, null, null) // Board is the service used to manage boards.

            if(typeof this.client !== "object") {
                throw new Error("Service unavailable. Failed to create the `board` client")
            }
        } catch(err) {
            console.error(err);

            if(getClient()?.getOptions()?.enabled)
                captureException(err)

            throw new Error("Service Unavailable Exception : Board service (api-geo) not ready to handle the request.", err)
        }
    }

    /**
     * @function createBoard()
     * @returns board || error
     * 
     * createBoard creates a new board.
     */
    create(boardCreation, dispatch, resolve, reject, callback=null) { 
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                   type:    !err ? types.CREATE_BOARD_SUCCESS : types.CREATE_BOARD_FAILURE,                                                           // Action type (state)
                   payload: !err ? {sn : boardCreation.getSn(), dimensionsMmList : boardCreation.getDimensionsMmList(), marginsMmList : boardCreation.getMarginsMmList(), response : response.toObject()} : null, // Action payload (dataset)
                   error:   !err ? null : err                                                                                                                   // Error
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => { // Adds and reports an additional dataset  within the log
                    scope.setExtra("board_sn", boardCreation.getSn())
                    scope.setExtra("no_geoloc", boardCreation.getNoGeoloc())
                    scope.setExtra("dimensions_mm", (
                        boardCreation.getDimensionsMmList()[0].length > 0 && boardCreation.getDimensionsMmList()[1].length > 0)
                            ? boardCreation.getDimensionsMmList() 
                            : "unset"
                    )
                    scope.setExtra("margins_mm", (
                        boardCreation.getMarginsMmList()[0].length > 0 && boardCreation.getMarginsMmList()[1].length > 0)
                            ? boardCreation.getMarginsMmList() 
                            : "unset"
                    )
                })
            }
        }
        return this.constructor.callAPI(this.client.create.bind(this.client), boardCreation, callback, dispatch)
    }

    /**
     * @function get()
     * @returns board || error
     * 
     * Get returns board information.
     */
    get(boardId, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.GET_BOARD_SUCCESS : types.GET_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardId.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.get.bind(this.client), boardId, callback, dispatch)
    }

    /**
     * @function update()
     * @returns board || error
     * 
     * Update updates an existing board.
     */
    update(boardUpdate, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.UPDATE_BOARD_SUCCESS : types.UPDATE_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardUpdate.getSn())
                    scope.setExtra("dimensions_mm", boardUpdate.getDimensionsMmList())
                    scope.setExtra("margins_mm", boardUpdate.getMarginsMmList())
                    scope.setExtra("no_geoloc", boardUpdate.getNoGeoloc())
                })
            }
        }
        return this.constructor.callAPI(this.client.update.bind(this.client), boardUpdate, callback, dispatch)
    }

    /**
     * @function deleteBoard()
     * @returns empty || error
     * 
     * deleteBoard deletes a board.
     */
    delete(boardId, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.DELETE_BOARD_SUCCESS : types.DELETE_BOARD_FAILURE,
                    payload: !err ? {sn : boardId.getSn(), response : response.toObject()} : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardId.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.delete.bind(this.client), boardId, callback, dispatch)
    }

    /**
     * @function getAll()
     * @returns []board || error
     * 
     * GetAll returns all boards identifiers.
     */
    getAll(boardFilters, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.GET_ALL_BOARD_SUCCESS : types.GET_ALL_BOARD_FAILURE,
                    payload: !err ? {...response.toObject(), pagination: {
                        size: boardFilters.getPage().getSize(),
                        page: boardFilters.getPage().getPage(),
                        desc: boardFilters.getPage().getIsDescending(),
                        sort: boardFilters.getPage().getSort(),
                    }} : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_filters", boardFilters.getFiltersList())
                })
            }
        }
        return this.constructor.callAPI(this.client.getAll.bind(this.client), boardFilters, callback, dispatch)
    }

    /**
     * @function getItems()
     * @returns boardsItems || error
     * 
     * GetItems returns the content (items) paginated of the given boards.
     */
    getItems(boardsIDWithPagination, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.GET_ITEMS_BOARD_SUCCESS : types.GET_ITEMS_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("boards", boardsIDWithPagination.getSnsList())
                })
            }
        }
        return this.constructor.callAPI(this.client.getItems.bind(this.client), boardsIDWithPagination, callback, dispatch)
    }

    /**
     * @function ping()
     * @returns requestStatus || error
     * 
     * Ping sends a request to the board for getting connection state.
     */
    ping(boardId, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.PING_BOARD_SUCCESS : types.PING_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardId.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.ping.bind(this.client), boardId, callback, dispatch)
    }

    /**
     * @function fullScan()
     * @returns requestStatus || error
     * 
     * FullScan sends a request to the board for getting a full scan.
     */
    fullScan(boardScan, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.FULLSCAN_BOARD_SUCCESS : types.FULLSCAN_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardScan.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.fullScan.bind(this.client), boardScan, callback, dispatch)
    }

    /**
     * @function setWifiCred()
     * @returns requestStatus || error
     * 
     * setWifiCred sends a request to the board for setting its wifi credentials.
     */
    setWifiCred(boardWifiCred, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.SET_WIFI_CREDENTIALS_BOARD_SUCCESS : types.SET_WIFI_CREDENTIALS_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardWifiCred.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.setWifiCred.bind(this.client), boardWifiCred, callback, dispatch)
    }

    /**
     * @function setMqttURL()
     * @returns requestStatus || error
     * 
     * setMqttURL sends a request to the board for setting the MQTT broker config.
     */
    setMqttURL(boardMqttURL, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.SET_MQTT_URL_BOARD_SUCCESS : types.SET_MQTT_URL_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardMqttConfig.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.setMqttURL.bind(this.client), boardMqttURL, callback, dispatch)
    }

    /**
     * @function setRFType()
     * @returns requestStatus || error
     * 
     * SetRFType sends a request to the board for setting its RF type.
     */
     setRFType(boardId, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.SET_RF_TYPE_BOARD_SUCCESS : types.SET_RF_TYPE_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardId.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.setRFType.bind(this.client), boardId, callback, dispatch)
    }

    /**
     * @function reboot()
     * @returns requestStatus || error
     * 
     * Reboot sends a request to the board for reboot.
     */
    reboot(boardId, dispatch, resolve, reject, callback=null) {
        if (!callback) {
            callback = (err, response) => {
                dispatch({
                    type:    !err ? types.REBOOT_BOARD_SUCCESS : types.REBOOT_BOARD_FAILURE,
                    payload: !err ? response.toObject() : null,
                    error:   !err ? null : err
                })

                this.constructor.handleResponseOrError(response, err, resolve, reject, scope => {
                    scope.setExtra("board_sn", boardId.getSn())
                })
            }
        }
        return this.constructor.callAPI(this.client.reboot.bind(this.client), boardId, callback, dispatch)
    }
}