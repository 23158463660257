import '@babylonjs/loaders'

import { Mesh } from "@babylonjs/core/Meshes";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Vector3 } from "@babylonjs/core/Maths/math";

import AbstractModel from "./AbstractModel"

// Point (default) three-dimensional model.
class PointerModel extends AbstractModel {
    dimensions = new Vector3(15, 15, 15);
    offsets    = new Vector3(0, 29.5, 0);

    buildInternalMesh(scene) {
        return new Promise((resolve, reject) => {
            SceneLoader.ImportMeshAsync("", "/models/pointer/", "map_pointer_3d_icon.glb", scene)
            .then((data) => {
                resolve(Object.assign(this, { mesh: Mesh.MergeMeshes(data.meshes.slice(1), true, true, null, false, true) }))
            })
            .catch((error) => { // Handle any errors that occurred during the asynchronous operation
                console.error("Error loading meshes:", error);
                reject(error);
            });
        })
    }
}

export default PointerModel