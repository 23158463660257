import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"

import { default as CLIENTS } from "../../../api/index"
import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form, InputPassword } from "../../../components/form/index"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    ssid : "",
    password : ""
}

const WifiCredentials = (props) => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ sn, ssid, password }, setFields] = useState({...initialFields, sn: props.sn});
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn }))
    }, [props.sn])

    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: value }));
    };

    return (
        <Form
            title={"Set WIFI credentials"}
            fields={{...initialFields, sn: props.sn}}
            toggle={
                useCallback(() => { /* WifiCred */
                    const boardWifiCred = new BoardPb.BoardWifiCred(); 
                    boardWifiCred.setSn(sn)
                    boardWifiCred.setSsid(ssid)
                    boardWifiCred.setPwd(password)
                    
                    // Sends a request to the board for setting its wifi credentials.
                    return new Promise((resolve, reject) => CLIENTS.geo.board.setWifiCred(boardWifiCred, dispatch, resolve, reject))
                })
            }
            setFields={setFields}
            // reboot={true}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-10 mb-4">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="sn" type="text" value={sn}
                            className="form-control w-auto"
                            readOnly="readonly" 
                            required 
                        />
                    </div>
                    <small id="serial_numberHelp" className="form-text text-muted">Board serial number</small>
                </div>
                <div className="d-flex align-items-center">
                    <div className="col-sm-6">
                        <input id="ssid" name="ssid" type="text" value={ssid} autoComplete="false"
                            onChange={handleChange}
                            className="form-control w-75" 
                            required 
                        />
                        <small id="ssidHelp" className="form-text text-muted">Network's name</small>
                    </div>
                    <div className="col-sm-6">
                        <InputPassword value={password} id="password" name="password" className="form-control w-75"
                            onChange={handleChange}
                            required={true}
                        />
                        <small id="passwordHelp" className="form-text text-muted">Password</small>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default WifiCredentials;