import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from '../../redux/root.reducer'; // Import the RootState from your Redux store
import { Link } from 'react-router-dom';
import { GrTechnology } from 'react-icons/gr';
import { AiFillHome, AiOutlineClose } from 'react-icons/ai';
import { BsFillCpuFill, BsBookshelf } from 'react-icons/bs';
import { MdNfc } from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';

import '../../assets/style/layout/header.css';

interface NavProps {
    sidebar: boolean;
    handleSidebarToggle: () => void;
}

// Implementation of the navigation view, as a sidebar, between pages
const Nav: React.FC<NavProps> = ({ sidebar, handleSidebarToggle: toggleSidebar }) => {
    const [navLinks, setNavLinks] = useState([]);

    // Allows you to extract data from the Redux store state, using a selector function
    // In this case, it retrieves the user's permission
    const permissions = useSelector((state: RootState) => state.user.permissions, shallowEqual);

    // List of all pages navigation links
    useEffect(() => {
        const links = [
            { path: '/', title: 'Home', icon: <AiFillHome />, cName: 'nav-text', exact: true }, /* Homepage */
            { path: '/board', title: 'Boards', icon: <BsFillCpuFill />, cName: 'nav-text', exact: true }, /* Boards */
            { path: '/furniture', title: 'Furnitures', icon: <BsBookshelf />, cName: 'nav-text', exact: true }, /* Furnitures */
            { path: '/gateway', title: 'Gateways', icon: <GrTechnology />, cName: 'nav-text', exact: true }, /* Gateways */
            { path: '/item', title: 'Items', icon: <MdNfc />, cName: 'nav-text', exact: true }, /* Items */
        ];

        if ((permissions["admin.tenant.transfer"] === true && permissions["admin.tenant.delete"] === true)
            && window.location.origin !== "file://"
        ) {
            links.push({ path: '/tenant', title: 'Tenants', icon: <RiAdminFill />, cName: 'nav-text', exact: true }); /* Tenants */
        }
        setNavLinks(links);
    }, [permissions]);

    return (
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={toggleSidebar}>
                <li className="sidebar-head">
                    <AiOutlineClose className='sidebar-toggler' size={35} />
                </li>
                <hr className="sidebar-break solid" />

                {navLinks.map((item, index) => (
                    <li key={index} className={item.cName}>
                        <Link to={item.path}>
                            {item.icon}
                            <span className="nav-title">{item.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Nav;
