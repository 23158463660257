import '@babylonjs/loaders'
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Vector3 } from "@babylonjs/core/Maths/math";
import AbstractModel from "./AbstractModel"

// Razor (rasoir) three-dimensional model.
class Razor extends AbstractModel {
    dimensions = new Vector3(- 15, 1, 10.75)
    rotation   = new Vector3(Math.PI * 1.5, 0, 0)
    offsets    = new Vector3(- 50, - 182.5, -2.5)

    buildInternalMesh(scene) {
        return new Promise((resolve, reject) => {
            SceneLoader.ImportMeshAsync("", "/models/razor/", "razor.obj", scene)
            .then((data) => { // Dispose of all sub-meshes except the first element that will be refered as the model (model for a blister view)
                resolve(Object.assign(this, { 
                    mesh: data.meshes.map((_, index) => (index != 0 && data.meshes[index].dispose(), data.meshes[index]))[0],
                    hookable: true
                }))
            })
            .catch((error) => { // Handle any errors that occurred during the asynchronous operation
                console.error("Error loading meshes:", error);
                reject(error);
            });
        })
    }
}

export default Razor;
