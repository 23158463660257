// import clients from "../RPCFactory"
// import { default as ApiManager } from '../RPC'
import clients from "../ClientFactory"
import { default as ApiManager } from '../ApiManager'
import { ItemWeb, ItemId, ItemCreation, ItemFilters, URLFilters } from '@centiloc/centiloc-ops-api-geo-grpc'

class Item extends ApiManager {
    private static instance: Item | null = null;
    public client: ItemWeb.ItemClient;

    private constructor() {
        super();
        
        if (!Item.instance) { // If the instance doesn't exist, set it to the current instance
            if (!this.client) {
                if(!(clients.getItemGeo() instanceof ItemWeb.ItemClient)) {
                    throw new Error("Failed to instantiate the client of the service 'Item' of `geo`");
                }
        
                this.client = clients.getItemGeo()
            }
        }
    }

    public static getInstance(): Item {
        if (!Item.instance) {
            Item.instance = new Item();
        }
        return Item.instance;
    }

    /**
     * Create creates an item.
     *
     * @param itemCreation - The ID of the item to create.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with item data or rejects with an error. 
     */
    async create(
        itemCreation: ItemCreation,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Item.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("item_uid", ItemId.getUid());
                })
            }

            // Request the API
            Item.callAPI(this.client.create.bind(this.client), itemCreation, callback, dispatch);
        });
    }

    /**
     *  Read returns the data related to an item.
     *
     * @param itemId - The ID of the item to fetch.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with item data or rejects with an error. 
     */
    async read(
        itemId: ItemId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Item.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("item_uid", ItemId.getUid());
                })
            }

            // Request the API
            Item.callAPI(this.client.read.bind(this.client), itemId, callback, dispatch);
        });
    }

    /**
     * Delete deletes an item.
     *
     * @param itemId - The ID of the item to delete.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with item data or rejects with an error. 
     */
    async delete(
        itemId: ItemId,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Item.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("item_uid", ItemId.getUid());
                })
            }

            // Request the API
            Item.callAPI(this.client.delete.bind(this.client), itemId, callback, dispatch);
        });
    }

    /**
     * GetAll returns all items corresponding to the filters.
     *
     * @param filters - Filters and pagination options.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with item data or rejects with an error. 
     */
    async getAll(
        arg: ItemFilters,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Item.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("item_filters", arg.getFiltersList());
                })
            }
            
            // Request the API
            Item.callAPI(this.client.getAll.bind(this.client), arg, callback, dispatch);
        });
    }

    /**
     * SetURL sets an URL for all items corresponding to the filters.
     *
     * @param arg - Filters and pagination options.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with item data or rejects with an error. 
     */
    async setURL(
        arg: URLFilters,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        try {
            return Item.callAPI(this.client.setURL.bind(this.client), arg, callback, dispatch);
        } catch (error) {
            Item.handleResponseOrError(null, error, null, null, (scope: any) => scope.setExtra("url_filters_list", arg.getFiltersList()));
        }
    }
}

export default Item.getInstance()
