import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    uid : "",
}

const Create = () => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ uid }, setFields] = useState(initialFields);

    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: value }));
    };

    return (
        <Form
            title={"Create"}
            response={"The item has been created successfully"}
            fields={initialFields}
            toggle={
                useCallback(() => { // Creates a new item.
                    const itemCreation = new ItemPb.ItemCreation(); 
                    itemCreation.setUid(uid)
                    
                    return CLIENTS.geo.item.create(itemCreation, dispatch /* EDIT: nest a GetAll() | dispatch */);
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-6">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="uid" type="text" value={uid} 
                            onChange={handleChange}
                            className="form-control w-75"
                            required 
                        />
                    </div>
                    <small id="uidHelp" className="form-text text-muted">Item UID</small>
                </div>

                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default Create;