import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from "bootstrap";
import { BoardPb, ItemPb } from '@centiloc/centiloc-ops-api-geo-grpc'
import CADS from "../containers/CADS";
import DMO from "../containers/DMO";
import CustomModal from "../components/Modal"
import centerIcon from '../assets/img/center.svg'
import 'virtual-select-plugin/dist/virtual-select.min.css';
import 'virtual-select-plugin/dist/virtual-select.min.js';
import '../assets/style/scene.css'

export default ({ uid, board, furniture }) => {
    
    // This hook returns a function that lets you navigate programmatically, able to redirect to another component, refresh the current one, etc...
    const navigate = useNavigate();

    const [pick, setPick]               = useState(uid);
    const [items, setItems]             = useState([]);
    const [isOpen, setIsOpen]           = useState(false);
    const [tooltip, setTooltip]         = useState();
    const [streamError, setStreamError] = useState({ setupError: false, show: false, title: "", message: "" });

    // The useRef Hook allows you to persist values between renders and can be used to store a mutable value that does not cause a re-render when updated
    const target   = useRef(uid); // Defines the item to highlight by default on reaching the 3D Interface from the list of the items
    const toastRef = useRef();

    // DOM EVENTS =========================================
    // Multiple select initialization
    useEffect(() => {
        window.VirtualSelect.init({ 
            ele: '#sample-select', 
            search: true 
        });

        // Defined to display the select or not
        const vWrapper = document?.querySelector(".vscomp-wrapper")
        if (vWrapper?.style) {
            vWrapper.style.display = vWrapper?.options?.length > 0 ?  "block" : "none"
        }
    }, [])

    // Items IN counter
    useEffect(() => {
        const counter = document.getElementById("count");
        
        if (!counter) {
            return;
        }

        const inCnt = items?.reduce((count, item) => {
            if (item?.isIn 
                || (item?.status && (parseInt(item.status) === ItemPb.ItemStatus.IN || item.status === "IN")) 
                || (!item?.hasOwnProperty("isIn") && !item.hasOwnProperty("status"))) {
                return count + 1;
            }
            return count;
        }, 0);
    
        counter.textContent = inCnt > 1 ? `${inCnt} products` : `${inCnt} product`;
    }, [items]);

    // Picked board/item data management
    useEffect(() => {
        let clipboard = document.getElementById("clipboard-icon-expand") // Global infos content
        let clipboardInfos = document.getElementById("clipboard-content-infos") // Picked element dataset

        // If the selected element has changed
        if (document.getElementById("clipboard-section-icon")?.getAttribute('data-pick-element') !== pick) {
            document.getElementById("clipboard-section-icon")?.setAttribute("data-pick-element", pick) // Update the data attribute
            clipboard.innerHTML = pick; // Update the displayed uid on hover
            tooltip?.hide() // Hide the initial toastR while the picked elem has changed
            setTooltip(new Tooltip(toastRef.current, { title: "Copy the " + (items.find((i) => i.uid === pick) ? "uid" : "serial number"), placement: 'right', trigger: 'hover' }))
        }

        // Data has changed, update info
        if (items.find((i) => i.uid === pick)) {
            const item = items.find((i) => i.uid === pick)
            let itemMomentum = item.hasOwnProperty('lastMomentum') ? item.lastMomentum : item.momentum

            // Update the displayed data for the selected item
            clipboardInfos.innerHTML = `Position: [ ` + item.posMmList[0] + `, ` + item.posMmList[1] + ` ] <br/>
                ` + (itemMomentum        ? `Last event: ` + new Date(itemMomentum.seconds * 1000 + itemMomentum.nanos / 1e6).toLocaleString() + ` <br/>`: ``) + `
                ` + (item.productReference ? `Product reference: `+ item.productReference + ` <br/>` : ``)  + `
                ` + (item.locationReference ? `Location reference: `+ item.locationReference + ` <br/>` : ``)  + `
                ` + (item.fillRatio >= 0 ? `Level: `+ (Math.round(item.fillRatio * 100)) + `%` : ``) 
        } else if (board?.sn === pick) {
            
            // Update the displayed data for the selected board
            clipboardInfos.innerHTML = `Status: ` + Object.entries(BoardPb.BoardStatus).find(([key, val]) => val === board.status)?.[0] + ` <br/> 
                ` + (board.lastConnection       ? `Last connection: ` + new Date(board?.lastConnection?.seconds * 1000 + board?.lastConnection?.nanos / 1e6).toLocaleString() + `<br/>` : ``) + `  
                ` + (board.fwVersion.length > 0 ? `Version: `         + board.fwVersion + ` <br/>` : ``) + `  
                ` + (board.dimensionsMmList[0]  ? `Dimensions: [ `    + board.dimensionsMmList[0] + `, ` + board.dimensionsMmList[1] + ` ]<br/>`: ``) + `
                ` + (board?.gwId.sn.length > 0  ? `Gateway: `         + board.gwId.sn + ` / ` + board.gwId.type : ``)
        } else if (furniture?.shelvesList?.find((s) => s.id.shelfSn === pick)) {
            const shelf = furniture?.shelvesList?.find((s) => s.id.shelfSn === pick)
            
            // Update the displayed data for the selected shelf
            clipboardInfos.innerHTML = `Furniture: ` + shelf.floorInFurniture.furnitureId.sn + ` <br/> 
                ` + (shelf.dimension ? `Dimensions: [ x : `    + shelf.dimension.x + `, y : ` + shelf.dimension.y + `, z :  `+ shelf.dimension.z +` ]<br/>`: ``) + `
                ` + (shelf.offset    ? `Offsets:    [ x : `    + shelf.offset.x + `, y : ` + shelf.offset.y + `, z :  `+ shelf.offset.z +` ]<br/>`: ``) + `
                ` + (shelf.offset    ? `Rotations:  [ x : `    + shelf.rotationAngles.x + `, y : ` + shelf.rotationAngles.y + `, z :  `+ shelf.rotationAngles.z +` ]<br/>`: ``) + `
                ` + (shelf.floorInFurniture ? `Floor: ` + shelf.floorInFurniture.floor : ``)
        }
    }, [pick, items, board, furniture, tooltip]);

    // Hook throwing up
    const Labels = useMemo(() => {
        const item = items.find((i) => i.uid === pick)

        if (item?.itemLabels) {
            if (item.itemLabels.labelsList.length > 0) {
                return <div className="labels-container d-flex flex-wrap align-items-stretch">Item's labels: {item.itemLabels.labelsList.map((label, index) => {
                    const [labelKey, labelValue] = label.split('::');
                    return <span key={label} className="badge text-bg-primary ms-2">
                            {labelKey} { labelValue && <span key={labelValue} className="badge text-bg-secondary">{labelValue}</span> }
                        </span>
                    })
                }</div>
            }
        }

        if (item?.locationLabels) {
            if (item.locationLabels.labelsList.length > 0) {
                return <div className="labels-container d-flex flex-wrap align-items-stretch">Location's labels: {item.locationLabels.labelsList.map((label, index) => {
                    const [labelKey, labelValue] = label.split('::');
                    return <span key={label} className="badge text-bg-info ms-2">
                            {labelKey} { labelValue && <span key={labelValue} className="badge text-bg-light">{labelValue}</span> }
                        </span>
                    })
                }</div>
            }
        }
    }, [pick])

    // Hook throwing up
    const ErrorModal = useMemo(() => {
        return <CustomModal show={ streamError.show } title={ streamError.title } message={ streamError.message } footer={ 
            <Modal.Footer>
                <Button variant="light" onClick={() => { navigate("/") }}>Home</Button>
                <Button variant="secondary" onClick={() => { navigate(0) }}>Refresh</Button>
            </Modal.Footer>
         } />
    }, [streamError])

    // Hook returning the scene content
    const SceneComponent = useMemo(() => {
        if (board || furniture) {
            const properties = {
                furniture,
                items,
                board,
                target: target.current,
                blister: furniture?.id?.sn === "DemoHook" ? true : false,
                navigate,
                setItems,
                setPick,
                setIsOpen,
                setStreamError,
            };

            // Three-dimensional(3D) interface component
            return <DMO {...properties} />;
        }
    }, [board, furniture, target, navigate, setPick])


    return (
        <>
            <div className="overlay">
                <div className="d-flex justify-content-between">
                    <div /* Expand Element Identifier Div with Tooltip */
                        id="clipboard"
                        style={{
                            opacity: isOpen ? "1" : "0",
                            visibility: isOpen ? "visible" : "hidden",
                        }}
                    >
                        <div
                            id="clipboard-section-icon"
                            ref={toastRef}
                            role={"none"}
                            data-pick-element={""}
                            onClick={() => { /* Info icon */
                                if (tooltip && tooltip?.tip?.innerText !== "Copied!") {
                                    tooltip?.hide(); // Hide the initial tooltip
                                    
                                    // Show and save the new tooltip
                                    const toastR = new Tooltip(toastRef.current, {
                                        show:      true,
                                        title:     "Copied!",
                                        placement: "right",
                                        trigger:   "hover",
                                    });
                                    toastR.show();
                                    setTooltip(toastR);
                                    
                                    navigator.clipboard.writeText(pick); // Copy the boardSn
                                }
                            }}
                        >
                            <AiOutlineInfoCircle size={22.5} />
                            <div id="clipboard-icon-expand">{pick}</div> {/* Expandable div - board info */}
                        </div>
                        {Labels}
                        <div id="clipboard-section-content">
                            <div id="clipboard-content-infos"></div>
                        </div>
                    </div>
                    <div className="">
                        <p /* Items IN counter */
                            id="count" 
                            style={{ cursor: "initial", textAlign: "right" }}
                        >0 product</p>
                        <select /* Select of Item References */
                            multiple
                            className="w-auto"
                            id="sample-select"
                            name="native-select"
                            data-search="true"
                            data-silent-initial-value-set="true"
                        ></select>
                    </div>
                </div>
            </div>

            {/* 3D display */}
            { SceneComponent }

            {/* Modal on setup error, timeout/crash */}
            { ErrorModal }

            {/* Input resetting the camera */}
            <div className="position-fixed d-flex flex-column justify-content-center" style={{ bottom:"70px", right: "49%" }}>
                <button type="button" className="invisible-button" id="resetZoom" onClick={() => window.dispatchEvent(new Event("resetZoom"))}>
                    <img src={centerIcon} width="40px" height="40px" alt="resetZoom"/>
                </button>
            </div>
        </>
    )
}