import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { TenantPb } from '@centiloc/centiloc-ops-api-admin-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
    newTenant : ""
}

const UpdateTenant = (props) => {
    const dispatch = useDispatch();

    // Creates state form's fields
    const [{ sn, newTenant }, setFields] = useState({...initialFields, sn: props.sn});
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn }))
    }, [props.sn])

    // Changes the input value
    const handleChange = e => {
        const { name, value } = e.target;
        setFields(fields => ({ ...fields, [name]: value }));
    };
    
    return (
        <Form
            title={"Update board tenant"}
            response={"The tenant has been correctly changed"}
            fields={{ ...initialFields, sn: props.sn }}
            toggle={
                useCallback(() => { /* UpdateTenant */
                    const arg = new TenantPb.BoardTenant();
                    arg.setSn(sn)
                    arg.setNewTenant(newTenant) // new owner of the board
                    
                    // Sends a request to update the board's tenant.
                    return CLIENTS.admin.tenant.updateBoard(arg, dispatch)
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="d-flex justify-content-start align-boards-center">
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-start align-boards-center">
                            <input name="sn" type="text" value={sn}
                                onChange={handleChange}
                                className="form-control w-75"
                                readOnly="readonly"
                                required 
                            />
                        </div>

                        <small id="snHelp" className="form-text text-muted">Board serial number</small>
                    </div>
                    <div className="col-sm-6">
                        <input id="tenant" name="newTenant" type="text" value={newTenant}
                            onChange={handleChange}
                            className="form-control w-75" 
                            required
                        />
                        <small id="tenantHelp" className="form-text text-muted">New board tenant</small>
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default UpdateTenant;