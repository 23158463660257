import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { BrowserRouter, HashRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { withProfiler } from "@sentry/react";
import { signOn, scopePermissions } from "./redux/actions/user.actions"
import { Footer } from './components/layouts/Layout.js'
import { Homepage, Board, Furniture, Gateway, Item, Tenant } from './pages'
import Player from './containers/Player'
import Header from './containers/Header'

// Conditions the routing system used depending on the used protocol (http:// or file://).
// Mandatory to handle a good behaviour of the routing system (while refreshing, redirecting, etc...)
//  once the app is packaged and then run from an .exe or lauched from a browser (as most of the time)
const Router = window.location.origin == "file://" ? HashRouter : BrowserRouter

const App = ({accessManaged}) => {
    // Returns the reference to the dispatch function from the redux store. May useful to dispatch actions
    const dispatch = useDispatch()

    // Redux stored data selection & extraction 
    // Allows you to extract data from the Redux store state for use in this component, using a selector function.
    const permissions = useSelector(state => state.user.permissions, shallowEqual);

    // The useState hook allows us to have state variables in the JSX functional component.
    // It takes one argument which is the initial state and returns a state value and a function to update it.
    const [isAdmin, setIsAdmin] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => { // loading page
        // Get user's permissions if we don't know yet
        // here and not in keycloak.js after token valid
        // because scopePermissions do API calls IN PARALLEL, so the sessionStorage is still empty when the second call begins, 
        // that themself use keycloak.js that will call again scopePermissions because sessionStorage is empty
        // => loop until the fist call has ended and filled sessionStorage
        dispatch(scopePermissions(false))
            .then(() => setIsReady(true))
            .catch(err => console.error(err))
    }, [dispatch])

    useEffect(() => {
        if (accessManaged) {
            dispatch(signOn()).catch(err => console.error(err))
        }
    }, [dispatch, accessManaged]); // Make sure to include dispatch in the dependencies array to prevent eslint warnings

    useEffect(() => {
        if(permissions["admin.tenant.transfer"] !== undefined && permissions["admin.tenant.delete"] !== undefined) {
            setIsAdmin(
                !!Object.entries(permissions)?.find(([key, value]) => ["admin.tenant.transfer", "admin.tenant.delete"].includes(key)
                && value === true))
        }
    }, [permissions])

    return React.useMemo(() => {
        if (!isReady) {
            return
        }

        return (
            <Router>
                <div className="main">
                    <Header accessManaged={ accessManaged } />
                    <Routes> 
                        <Route path='/' element={ <Homepage /> } />

                        {/* Board / Item / Furniture / Gateway */}
                        <Route  path='board' >
                            <Route index element={ <Board /> } ></Route>
                            <Route path=":filters" element={ <Board /> } ></Route>
                            <Route path='demo' element={ <Player /> } />
                        </Route>
                        <Route path='furniture' >
                            <Route index element={ <Furniture /> } />
                            <Route path=":filters" element={ <Furniture /> } />
                            <Route path='demo' element={ <Player /> } />
                        </Route>
                        <Route path='gateway' >
                            <Route index element={ <Gateway /> } />
                            <Route path=":filters" element={ <Gateway /> } />
                        </Route>
                        <Route path='item'>
                            <Route index element={ <Item /> } />
                            <Route path=":filters" element={ <Item /> } />
                            <Route path='demo' element={ <Player /> } />
                        </Route>
                        <Route path="*" element={ <Navigate to ="/" /> }/>

                        {/* Demos */}
                        <Route path='demo' element={ <Player /> } />

                        {/* Admin */}
                        { isAdmin && <Route path='tenant' element={ <Tenant /> } /> }
                    </Routes>
                    <Footer />
                </div>
            </Router>
        )
    }, [isAdmin, isReady]);
}

// Wraps the higher app's component in a Sentry `Profiler` component
export default withProfiler(App);