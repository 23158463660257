

import React, { useEffect, useRef } from "react";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color";
import { Engine } from "@babylonjs/core/Engines/engine";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight"
import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math";
// import { AxesViewer } from "@babylonjs/core/Debug/axesViewer.js";

interface BabylonProps {
    antialias?:          boolean;
    engineOptions?:      any;
    adaptToDeviceRatio?: boolean;
    sceneOptions?:       any;
    onRender?:           (scene: Scene) => void;
    onSceneReady:        (scene: Scene) => Promise<void>;
    /**
     * Automatically trigger engine resize when the canvas resizes (default: true)
     */
    // observeCanvasResize?: boolean
    // children?: React.ReactNode
    [ props: string ]:   any;
}

// InteractiveScene used to render a surface for 3D view, creates the engine, set up the scene, and starts the render loop
const InteractiveScene: React.FC<BabylonProps> = ({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, ...props }) => {
    const reactCanvas = useRef<HTMLCanvasElement>(null); // Referred canvas element
    const sceneRef = useRef<Scene>(null);// Referred BABYLON scene

    // Camera toggle
    const createArcRotateCamera = (scene: Scene, canvas: HTMLCanvasElement) => { 
        const radius = props.data?.length > 1 
            ? props.width * (props.data.length * 0.85)
            : Math.max(props.width, 500);
        const position = new Vector3(props.width / 2, props.height / (Array.isArray(props.data) ? 15 : 4), props.depth / 2);
        
        const camera = new ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2, radius, position, scene);
        camera.wheelPrecision = camera.wheelPrecision / (props.data?.length ?? 1)
        camera.zoomToMouseLocation = true;
        camera.attachControl(canvas, true);
        camera.storeState()
        return camera;
    };

    // Function to check if main meshes are visible in the scene
    const checkIsReady = (scene: Scene) => {
        if (props.blister) { /* Are all hooks visible ? (hook) */
            return props.data.every((data) => scene.getMeshByName(data.serial) !== null);
        }
        if (Array.isArray(props.data) && props.data?.length > 0) { /* Are all shelves visible ? (furniture) */
            return props.data.every((data) => scene.getMeshByName(data.id.shelfSn) !== null);
        }
        if (props.data != undefined && scene.getMeshByName(props.data.sn) !== null) { /* Is the board visible ? (board) */
            return true
        }
        return false;
    };

    // Cleanup the Babylon scene
    const cleanupScene = (scene: Scene) => {
        if (scene) {
            scene.getEngine().stopRenderLoop();
            scene.getEngine().dispose();
            scene.dispose();
        }
    };

    // Cleanup window event listeners
    const cleanupWindow = () => {
        if (window) {
            window.removeEventListener("resize", null);
            window.removeEventListener("resetZoom", null);
        }
    };

    // Set up the Babylon.js engine and scene on component mount
    useEffect(() => {
        try {
            const { current: canvas } = reactCanvas;

            if (canvas) {
                const engine = new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
                sceneRef.current = new Scene(engine, sceneOptions);
                sceneRef.current.clearColor = new Color4(255, 255, 255);

                const keylight = new HemisphericLight("keylight", new Vector3(0, 1, 0), sceneRef.current);
                keylight.diffuse       = new Color3(0.7, 0.7, 0.7);
                keylight.specular      = new Color3(0.2, 0.2, 0.2);
                keylight.groundColor   = new Color3(0, 0, 0);

                if (props.blister) {
                    const fill = new HemisphericLight("filllight", new Vector3(0, 1, -1), sceneRef.current);
                    fill.diffuse       = new Color3(0.7, 0.7, 0.7);
                    fill.specular      = new Color3(0.2, 0.2, 0.2);
                    fill.groundColor   = new Color3(0, 0, 0);
                }
        
                // Create an ArcRotateCamera and check if datas are loaded
                const camera = createArcRotateCamera(sceneRef.current, canvas);

                // Helpful feature for displaying world axes (X: red, Y: green, Z: blue)
                // const axis = new AxesViewer(sceneRef.current, 50)
        
                // Handle scene readiness
                if (sceneRef.current.isReady()) {
                    onSceneReady(sceneRef.current).then(() => {
                        if (props.blister) {
                            const panel: AbstractMesh = sceneRef.current.getMeshByName("panel")
                            
                            if (panel != null) {
                                camera.setTarget(new Vector3(panel.position.x - 25, panel.position.y, panel.position.z))
                                camera.position         = new Vector3(panel.position.x - 25, panel.position.y, panel.position.z)
                                camera.alpha            = Math.PI*1.5
                                camera.radius           = 750
                                camera.lowerRadiusLimit = 5
                                camera.storeState()
                            }
                        }
                    });
                } else {
                    sceneRef.current.onReadyObservable.addOnce(() => { onSceneReady(sceneRef.current) });
                }
        
                // Run the render loop
                engine.runRenderLoop(() => {
                    if (typeof onRender === "function") {
                        onRender(sceneRef.current);
                    }

                    // Render the scene while main mesh(es) are built
                    if(checkIsReady(sceneRef.current)) {
                        sceneRef.current.render();
                    }
                });
        
                // Event listeners for window resize and camera reset
                const resize = () => {
                    if (sceneRef.current) {
                        sceneRef.current.getEngine().resize();
                    }
                };
                const resetZoom = () => {
                    camera.restoreState();
                };
                const escape = (e: KeyboardEvent) => {
                    if (e.key === "Escape") {
                        resetZoom();
                    }
                };
        
                // Add event listeners to canvas and window
                if (window) {
                    window.addEventListener("resize", resize);
                    window.addEventListener("resetZoom", resetZoom, false);
                }
                canvas.addEventListener("keydown", escape, false);

                // Cleanup functions for scene and window on component unmount
                return () => {
                    cleanupScene(sceneRef.current);
                    cleanupWindow();
                };
            }
        } catch (e) {
            console.error("Failed to load 3Dview setup, an unexpected error occurred:", e);
        }
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, reactCanvas, props]);

    // Render the canvas element
    return <canvas id="my-canvas" ref={reactCanvas} />;
};

export default InteractiveScene;

// // show axis
// function showAxis(size) {
//     var makeTextPlane = function(text, color, size) {
//         var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, scene, true);
//         dynamicTexture.hasAlpha = true;
//         dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
//         var plane = BABYLON.MeshBuilder.CreatePlane("TextPlane", size, scene, true);
//         plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", scene);
//         plane.material.backFaceCulling = false;
//         plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
//         plane.material.diffuseTexture = dynamicTexture;
//         return plane;
//     };

//     var axisX = BABYLON.MeshBuilder.CreateLines("axisX", [ 
//         new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0), 
//         new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
//         ], scene);
//     axisX.color = new BABYLON.Color3(1, 0, 0);
//     var xChar = makeTextPlane("X", "red", size / 10);
//     xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);
//     var axisY = BABYLON.MeshBuilder.CreateLines("axisY", [
//         new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( -0.05 * size, size * 0.95, 0), 
//         new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3( 0.05 * size, size * 0.95, 0)
//         ], scene);
//     axisY.color = new BABYLON.Color3(0, 1, 0);
//     var yChar = makeTextPlane("Y", "green", size / 10);
//     yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);
//     var axisZ = BABYLON.MeshBuilder.CreateLines("axisZ", [
//         new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0 , -0.05 * size, size * 0.95),
//         new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3( 0, 0.05 * size, size * 0.95)
//         ], scene);
//     axisZ.color = new BABYLON.Color3(0, 0, 1);
//     var zChar = makeTextPlane("Z", "blue", size / 10);
//     zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);
// };