import React from "react";

import "../assets/style/layout/card.css"

/**
 * @constant Card
 * @param { state props } props 
 * @returns HTML content
 * 
 * Card React component.
 */
const Card = (props) => {

    // Conditioning display of the card illustration
    const displayIllustration = React.useMemo(() => {
        if(props.illus)
            return (
                <div className="mx-3 my-3">
                    <img src={ props.illus.src } className="card-img-top" alt={ props.illus.alt }  />
                </div>
            )
    }, [props])

    // Conditioning render of the card content
    const displayBody = React.useMemo(() => {
        if(props.illus) {
            return (
                <div className="card-body">
                    <hr className="w-75" style={{ margin: "auto" }} />
    
                    {/* Title */}
                    <h5><blockquote className="blockquote card-title py-3">{ props.title }</blockquote></h5>
                    
                </div>
            )
        } else {
            return (
                <div className="card-body">
    
                    {/* Title */}
                    <h5><blockquote className="blockquote card-title py-2">{ props.title }</blockquote></h5>
                   
                </div>
            )
        }
    }, [props])

    return (
        <div className="card">
            
            {/* Card illustration */}
            { displayIllustration }

            {/* Card content body */}
            { displayBody }
        </div>
    )
}

export { Card };