import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import { default as CLIENTS } from "../../../api/index"
import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'

import { Form } from "../../../components/form/index.js"
import '../../../assets/style/layout/form.css'

const initialFields = {
    sn : "",
}

const Reboot = (props) => {
    const dispatch = useDispatch();
    
    // Creates state form's fields
    const [{ sn }, setFields] = useState({...initialFields, sn: props.sn});
    
    // Trig the props' changes to update the form's field
    useEffect(() => {
        setFields(fields => ({ ...fields, sn: props.sn }))
    }, [props.sn])

    return (
        <Form
            title={"Reboot"}
            fields={{...initialFields, sn: props.sn}}
            toggle={
                useCallback(() => { /* Reboot */
                    const id = new BoardPb.BoardID();
                    id.setSn(sn)
                    
                    // Sends a request to the board for restarting.
                    return new Promise((resolve, reject) => CLIENTS.geo.board.reboot(id, dispatch, resolve, reject))
                })
            }
            setFields={setFields}
        >
            <div className="form-group mx-4 my-4">
                <div className="col-sm-4">
                    <div className="d-flex justify-content-start align-items-center">
                        <input name="sn" type="text" value={sn}
                            className="form-control w-auto" 
                            readOnly="readonly"
                            required 
                        />
                    </div>
                    <small id="serial_numberHelp" className="form-text text-muted">Board serial number</small>
                </div>

                <button type="submit" className="btn btn-primary">OK</button>
            </div>
        </Form>
    );
}

export default Reboot;