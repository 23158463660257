import * as types from '../actions.types'
import { default as CLIENTS } from '../../api/index'
import { formatFilters } from "../../shared/utils";

import { BoardPb } from '@centiloc/centiloc-ops-api-geo-grpc'
// import '../../../registry/apigeogrpc/board_pb'

// get returns all boards matching with the filters given.
export const get = (payload) => async(dispatch) => {
    const boardId = new BoardPb.BoardID(); // Proto message payload request parameter
    // const boardId = new proto.apigeogrpc.BoardID(); // Proto message payload request parameter
    boardId.setSn(payload)

    // Call the `Get` method through the `Board` service
    return CLIENTS.geo.board.get(boardId, dispatch);
}

// getItems returns the content (boards) paginated of the given boards.
export const getItems = (payload) => async(dispatch) => {
    const boardsIDWithPagination = new BoardPb.BoardsIDWithPagination();
    boardsIDWithPagination.addSns(payload)

    // Call the `GetItems` method through the `Board` service
    return CLIENTS.geo.board.getItems(boardsIDWithPagination, dispatch);
}

// Sends a Board.getAll gRPC request and dispatch the response to get all gateways matching with the filters given.
export const getAll = (payload, resolve, reject) => async(dispatch) => {
    try {
        const boardFilters = formatFilters(payload, "board")
        
        // Call the `GetAll` method through the `Board` service
        return CLIENTS.geo.board.getAll(boardFilters, dispatch, resolve, reject, null);
    } catch(err) {
        CLIENTS.geo.board.constructor.handleResponseOrError(null, err, resolve, reject, scope => { // Adds and reports an additional dataset  within the log
            if(payload !== undefined && payload.filters !== undefined) {
                payload.filters.forEach(filter => {
                    scope.setExtra(filter.filter, filter.value)
                })
            }
        })

        dispatch({ type: types.GET_ALL_BOARD_FAILURE, error: err })
        return; 
    }
}