import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const initSentry = () => {
    let rate = 1.0
    
    if(!isNaN(parseFloat(window.SENTRY_RATE))) {
        rate = parseFloat(window.SENTRY_RATE)
    } else if (window.SENTRY_RATE != undefined) {
        console.error("SENTRY_RATE is badly set. Expected a float value, got : " + window.SENTRY_RATE)
    }

    let url = window.location.host
    let env = "production"
    let tmp = url.split(".")[1]
    if (tmp != undefined && tmp?.length > 0){
        if (tmp != "centiloc") env = tmp
    } else {
        console.log("Local environment, sentry should not be used, abort")
        return
    }

    Sentry.init({
        dsn: window.SENTRY_URL,
        tracesSampleRate: rate, // Recommend to be adjust in production, or using tracesSampler for finer control
        // enabled: process.env.NODE_ENV !== 'development',
        integrations: [
            new BrowserTracing()
        ], // Error Boundary component used to automatically send Javascript errors from inside a component tree to Sentry, and set a fallback UI
        // beforeSend(event, hint) {
        //     // Check if it is an exception, and if so, show the report dialog
        //     if (event.exception) {
        //       Sentry.showReportDialog({ eventId: event.event_id });
        //     }
        //     return event;
        // },
        autoSessionTracking: true,
        environment: env,
    },);
}

export default { initSentry };