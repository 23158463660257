import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux"

import { Layout } from "./layouts/Layout.js";
import { Table } from "../containers/Table";

export default ({ type, payload, columns, filters, actions, getAll, createForm }) => {
    const permissions = useSelector(state => state.user.permissions, shallowEqual);

    const [id, setId]               = useState();
    const [action, setAction]       = useState({ name: null, form: null, active: false });
    const [observer, setObserver]   = useState();

    const actionTable = useCallback((props) => {
        if(props !== undefined && props.action.length > 0) {
            setId(props.id);
            setAction({ 
                ...action,
                name: props.action 
            });
        }
    }, [])

    const urlParams = new URLSearchParams(location?.search); // retrieves the current URL with its params

    // Process for the return of a form matching the selection, with all necessaries resources
    const handleForms = React.useMemo(() => {
        const selectedAction = actions.find(act => act.value === action.name)

        if(selectedAction) {
            setAction({ ...action, active: true });

            // Resets all other lists of selectables actions
            for (const select of document.querySelectorAll("select#actions")) {
                if(select.closest("tr").firstChild.id === (type === "gateway" ? JSON.stringify(id) : id)) {
                    select.selectedIndex = [...select.options].indexOf([...select.options].find((opt) => opt.value === action.name))
                } else {
                    select.selectedIndex = 0
                }
            }
        }

        const elem = payload.list.find((e) => type === "item"
                ? e.uid === id
            : type === "board" 
                ? e.sn === id
            : JSON.stringify(e.id) === JSON.stringify(id)
        )
        if (elem) {
            switch(type) {
            case "item":
                if(action.name === "set_url") {
                    return selectedAction.form(id, elem.url)
                } else {
                    return selectedAction.form(id, elem.status)
                }
            case "board":
                if(action.name === "update") {
                    return selectedAction.form(
                        id,
                        elem.dimensionsMmList?.[0] ?? "", // dimX
                        elem.dimensionsMmList?.[1] ?? "", // dimY
                        elem?.marginsMmList?.[0] ?? "",   // marginX
                        elem?.marginsMmList?.[1] ?? "",   // marginY
                        elem.noGeoloc ?? false,           // noGeoloc
                    )
                } else {
                    return selectedAction.form(id, elem.fwVersion)
                }
            case "gateway":
                return selectedAction.form(id?.sn, id?.type, elem.continuousScanModeEnabled)
            }
        }

        setAction({ ...action, active: false });
    }, [id, action.name, actions, payload.list, type])

    useEffect(() => {
        if(document.querySelectorAll("table[role=table]")[0]) {

            // Create an observer instance linked to the callback function
            const mObserver = new MutationObserver(() => {
                observer?.disconnect()
                
                if(actions.find(act => act.value === action.name)) {

                    // Resets all other lists of selectables actions
                    for (const select of document.querySelectorAll("select#actions")) {
                        if(select.closest("tr").firstChild.id === (type === "gateway" ? JSON.stringify(id) : id)) {
                            select.selectedIndex = [...select.options].indexOf([...select.options].find((opt) => opt.value === action.name))
                        } else {
                            select.selectedIndex = 0
                        }
                    }
                }
            });
            setObserver(mObserver)
        
            // Start observing the target node for configured mutations
            mObserver.observe(document.querySelectorAll("table[role=table]")[0], { attributes: true, childList: true, subtree: true });
        }
    }, [id, payload, action.name])
    
    // Stop observing on leaving component
    useEffect(() => {
        return () => observer?.disconnect();
    }, [observer])
    
    return (
        <Layout>
            <div className="row headerow justify-content-center">
                <div className={ action.active ? "col-sm-8 col-xs-12" : "col-sm-10 col-xs-12"} >
                    <div className="row">
                        <Table type={ type } getAll={ getAll } func={ actionTable } /* Table */
                            data={ payload }
                            actions={ actions }
                            columns={ columns }
                            filters={ filters }
                        />
                        {
                            permissions["geo."+type+".create"] ? <div className="col-sm-6 flex-grow mx-2" >
                                    <div className="shadow">
                                        <div className="d-flex flex-column justify-content-left">
                                            { createForm } {/* Create form component */}
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                    <div className={ "action-group" + (urlParams && urlParams?.getAll('filters') && urlParams.getAll('filters')?.length > 0 ? "-filters" : "") + " shadow"}>
                        <div className="d-flex flex-column justify-content-left">
                            { handleForms } {/* Displayed selected action */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}