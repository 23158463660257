// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
    padding: 1.5vh;
}

.card {
    height: 100%;
    width: 21rem;
}

.card h5{
    font-weight: 300;
}

.card-body {
    bottom: 0;
}

.card-content {
    height: 60%;
}

.card-text {
    left: 0;
}

.status-list {
    margin-left: 5px;
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/assets/style/layout/card.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".card-container {\r\n    padding: 1.5vh;\r\n}\r\n\r\n.card {\r\n    height: 100%;\r\n    width: 21rem;\r\n}\r\n\r\n.card h5{\r\n    font-weight: 300;\r\n}\r\n\r\n.card-body {\r\n    bottom: 0;\r\n}\r\n\r\n.card-content {\r\n    height: 60%;\r\n}\r\n\r\n.card-text {\r\n    left: 0;\r\n}\r\n\r\n.status-list {\r\n    margin-left: 5px;\r\n    font-size: 13px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
