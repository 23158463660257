import * as itemTypes from '../actions.types'

/**
 * @constant INITIAL_STATE
 * 
 * Initial items state structure.
 */
const INITIAL_STATE = {
    list: [],
    links: {first: undefined, previous: undefined, next: undefined, last: undefined},
    pagination: {size: 25, page: 1, desc: false, sort: 1},
    count: 0
}

/**
 * @constant reducer
 * @param { initial item state } state 
 * @param { action } action 
 * @returns state
 * 
 * Build the items state.
 */
const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {

        /** ITEMS ACTIONS **/
        case itemTypes.CREATE_ITEM_SUCCESS : // Succeed Item.create action result
            if(!state.list.find(item => item.uid === action.payload.uid)) {
                return {
                    ...state, list: [{ uid: action.payload.uid, status: "NEW", posMmList: [] }].concat(state.list)
                }
            } else {
                console.error('Inconsistency data, try to dupplicate an entry already stored with the uid : ' + action.payload.id)
            }

            return { ...state }
        case itemTypes.DELETE_ITEM_SUCCESS : // Succeed Item.delete action result
            return { // Retrieves the deleted item to remove it from the list
                ...state, list : state.list.filter((item) => item.uid !== action.payload.uid) 
            }
        case itemTypes.GET_ITEM_SUCCESS : // Succeed Item.getAll action result
            if(!state.list.find(item => item.uid === action.payload.uid)) {
                return { // While none dupplication, juste concatenate the store with the returned payload
                    ...state, list: state.list.concat(action.payload)
                }
            } else return { // Concatenate the previous state with the returned payload and replace same occurencies by the new returned dataset 
                ...state, list: [...state.list.filter((i) => i.uid !== action.payload.uid), action.payload]
            }
        case itemTypes.GET_ALL_ITEM_SUCCESS : // Succeed Item.getAll action result
            return {
                list: action.payload.itemsList,
                links: { ...state.links, ...action.payload.links },
                pagination: { ...state.pagination, ...action.payload.pagination },
                count: !isNaN(action.payload.count) ? action.payload.count : 0
            }
        default :
            return { ...state }
    }
}

export { reducer }