import React, { useEffect, useMemo, useRef, useState } from "react";

import { Link } from 'react-router-dom';
import { useUserContext } from "../contexts/UserContext";
import { ImProfile } from "react-icons/im";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { Dispatch } from "redux";

interface UserProps {
  dispatch:      Dispatch;
  accessManaged: boolean;
}

const Profile: React.FC<UserProps> = ({ dispatch, accessManaged }) => {

    const [isAim, setIsAim] = useState(false);

    // User context
    const { isAuthenticated, displayName, signOut } = useUserContext();

    // Display beautified name
    const beautifyUserName: string = useMemo(() => {
        let name = displayName.split(' ');
        let lastName = name[name.length - 1]
        name.pop()
        
        let beautified = ""
        name.forEach((s) => beautified += s)
        
        return beautified += " " + lastName.toUpperCase()
    }, [displayName])

    const profileRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
                setIsAim(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        (accessManaged && isAuthenticated) && (
            <div className="profile-box ps-2 pe-3" onClick={() => setIsAim(!isAim)} ref={profileRef} >
                <div className={`first-in-box ${isAim ? 'visible' : ''}`}>
                    <div className="pop-container">
                        <div className="header">
                            <span>{beautifyUserName}</span>
                        </div>
                        <hr className="solid" />

                        <div className="menu">
                            <Link target="_blank" to={ (window as any).KEYCLOAK_URL + "/auth/realms/centiloc/account/" }>
                                <div className="pop-item">
                                    <span><ImProfile size={25} />Account</span>
                                </div>
                            </Link>
                            <div className="pop-item">
                                <span onClick={() => signOut(dispatch)}><MdLogout size={25} />Sign out</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-in-box">
                    <span className="row"><FaUserCircle size={27.5} /></span>
                </div>
            </div>
        )
    );
};

export default Profile;