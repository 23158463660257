// import clients from "../RPCFactory"
// import { default as ApiManager } from '../RPC'
import clients from "../ClientFactory"
import { default as ApiManager } from '../ApiManager'
import { TenantWeb, BoardTenant, ItemTenant, TenantTransfer, TenantDelete } from '@centiloc/centiloc-ops-api-admin-grpc'

class Tenant extends ApiManager {
    private static instance: Tenant | null = null;
    public client: TenantWeb.TenantClient;

    private constructor() {
        super();

        if (!Tenant.instance) { // If the instance doesn't exist, set it to the current instance
            if (!this.client) {
                if(!(clients.getAdminTenant() instanceof TenantWeb.TenantClient)) {
                    throw new Error("Failed to instantiate the client of the service 'Tenant' of `admin`");
                }
        
                this.client = clients.getAdminTenant()
            }
        }
    }

    public static getInstance(): Tenant {
        if (!Tenant.instance) {
            Tenant.instance = new Tenant();
        }
        return Tenant.instance;
    }

    /**
     * @param furnitureId - The ID of the furniture to fetch.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with furniture data or rejects with an error.
     * 
     * updateBoard updates the board's tenant.
     */
     async updateBoard(
        boardTenant: BoardTenant,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Tenant.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("board_sn", boardTenant.getSn());
                })
            }
            
            Tenant.callAPI( // Request the API
                this.client.updateBoard.bind(this.client),
                boardTenant,
                callback,
                dispatch
            );
        });
    }

    /**
     * @param furnitureId - The ID of the furniture to fetch.
     * @param dispatch - Redux dispatch function.
     * @param callback - Optional callback function.
     * @returns Promise that resolves with furniture data or rejects with an error.
     * 
     * updateItem updates the item's tenant.
     */
    async updateItem(
        itemTenant: ItemTenant,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!callback) {
                callback = (err: any, response: any) => Tenant.handleResponseOrError(response, err, resolve, reject, (scope: any) => {
                    scope.setExtra("uid", itemTenant.getUid());
                })
            }
            
            Tenant.callAPI( // Request the API
                this.client.updateItem.bind(this.client),
                itemTenant,
                callback,
                dispatch
            );
        });
    }
    
    // Transfer transfers all data owned by a tenant to another.
    async transfer(
        arg: TenantTransfer,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        try {
            return Tenant.callAPI(this.client.transfer.bind(this.client), arg, callback, dispatch);
        } catch (error) {
            Tenant.handleResponseOrError(null, error, null, null, (scope: any) => { 
                scope.setExtra("old_tenant", arg.getOldTenant());
                scope.setExtra("new_tenant", arg.getNewTenant());
            });
        }
    }

    // Delete deletes all data owned by a tenant and optionally the tenant itself from the authentication system.
    async delete(
        arg: TenantDelete,
        dispatch: (action: any) => void,
        callback: (err: any, response: any) => void = null
    ): Promise<any> {
        try {
            return Tenant.callAPI(this.client.delete.bind(this.client), arg, callback, dispatch);
        } catch (error) {
            Tenant.handleResponseOrError(null, error, null, null, (scope: any) => { 
                scope.setExtra("tenant", arg.getTenant());
                scope.setExtra("delete_from_auth", arg.getDeleteFromAuth());
            });
        }
    }
}

export default Tenant.getInstance()