import * as types from "../actions.types";

/**
 * @function userMiddleware()
 * @returns next(action)
 * 
 * Middleware used to convert the returned users datasets before passing through the store.
 */
export function userMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            switch(action.type) {
                case types.USER_SCOPE_PERMISSIONS_SUCCESS:
                    sessionStorage.setItem("permissions", JSON.stringify({ ...action.payload }))
                    break;
                default:
                    return next(action, dispatch)
            }
            return next(action); // You should always return next(action) in your middleware.
            // If you forget to return this the application will stop, and no other action will reach the reducer.
        }
    }
}