import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { StatusPill } from "../containers/Table";
import { CreateForm, PingForm, FullScanForm, WifiCredentialsForm, MqttConfigForm, RFTypeForm, RebootForm, UpdateTenantForm, UpdateForm } from "../containers/forms/board";
import { default as Gangplank } from "../components/Gangplank.jsx";
import { getAll } from "../redux/actions/board.actions"


export default () => {
    const dispatch = useDispatch();

    /** 
     * @constant boards
     * @function useSelector()
     * 
     * Allows you to extract data from the Redux store state
     */
    const permissions = useSelector(state => state.user.permissions, shallowEqual);
    const boards = useSelector(state => state.board);

    /**
     * @function useState()
     * 
     * The useState hook allows us to have state variables in the JSX functional component.
     * It takes one argument which is the initial state and returns a state value and a function to update it.
     */
    const [actions, setActions] = useState([]);

    useEffect(() => {
        const actionsList = []

        permissions["geo.board.ping"]        && actionsList.push({ Label: "Ping", value: "ping", form: (boardSn) => { return <PingForm sn={ boardSn }/> }})
        permissions["geo.board.update"]      && actionsList.push({ Label: "Update", value: "update", form: (boardSn, dimX, dimY, marginX, marginY, noGeoloc) => { return <UpdateForm sn={ boardSn } dimX={ dimX } dimY={ dimY } marginX={ marginX } marginY={ marginY } noGeoloc={ noGeoloc }/> }})
        permissions["geo.board.fullScan"]    && actionsList.push({ Label: "Full scan", value: "full_scan", exceptions: [{ attribute: "status", value: "DISCONNECTED" }], form: (boardSn) => { return <FullScanForm sn={ boardSn }/> } })
        permissions["geo.board.setWifiCred"] && actionsList.push({ Label: "Set WIFI credentials", value: "wifi_credentials", exceptions: [{ attribute: "status", value: "DISCONNECTED" }, { attribute: "gateway", value: "notnull" }], form: (boardSn) => { return <WifiCredentialsForm sn={ boardSn } /> } })
        permissions["geo.board.setMqttURL"]  && actionsList.push({ Label: "Set MQTT configuration", value: "mqtt_config", exceptions: [{ attribute: "status", value: "DISCONNECTED" }, { attribute: "gateway", value: "notnull" }], form: (boardSn, boardVersion) => { return <MqttConfigForm sn={ boardSn } fwVersion={ boardVersion }/> } })
        permissions["geo.board.setRFType"]   && actionsList.push({ Label: "Set RF type", value: "rf_type", exceptions: [{ attribute: "status", value: "DISCONNECTED" }], form: (boardSn) => { return <RFTypeForm sn={ boardSn } /> }})
        permissions["geo.board.reboot"]      && actionsList.push({ Label: "Reboot", value: "reboot", form: (boardSn) => { return <RebootForm sn={ boardSn }/> }})
        
        if (permissions["admin.tenant.updateBoard"] && window.location.origin !== "file://") {
            actionsList.push({ Label: "Change tenant", value: "tenant", form: (boardSn) => { return <UpdateTenantForm sn={ boardSn } />}})
        }

        if (JSON.stringify(actions) !== JSON.stringify(actionsList)) {
            setActions(actionsList)
        }
    }, [dispatch, permissions, actions])

    return <Gangplank type={ "board" } getAll={ getAll }
        payload={ boards }
        columns={ columns }
        filters={ filters }
        actions={ actions }
        createForm={ <CreateForm /> }
    />
}

// List defining filters for boards
const filters = [
    { Name: "SN", type: "text", value: "SN" },
    { Name: "Board Status", type: "status", value: "BOARDSTATUS" },
    { Name: "Last event", type: "date", value: "LAST_EVENT" },
    { Name: "No activity after", type: "date", value: "NO_ACTIVITY_AFTER" },
    { Name: "Last connection", type: "date", value: "LAST_CONNECTION" },
    { Name: "Gateway", type: "string", value: "GW" },
]

// Dataset of the table columns content settings
const columns = [
    { Header: "SN", accessor: "sn", value: "SN", isSortable: true },
    { Header: "Board Status", accessor: "status", value: "BOARDSTATUS", isSortable: true, Cell: StatusPill },
    { Header: "Version", accessor: "fwVersion", value: "FWVERSION", isSortable: false },
    { Header: "Last connection", accessor: "lastConnection", value: "LAST_CONNECTION", isSortable: true },
    { Header: "Gateway", accessor: "gwId", value: "GW", isSortable: true,
        Cell: (props) => ( // Link redirecting to "/gateway" and filter the table by board SN
            <Link to={ "/gateway?filters="+ encodeURIComponent(JSON.stringify({ filter: "ID", value: props.cell.row.original.gwId })) } style={{ cursor: "pointer" }}>
                { props.cell.row.original.gwId !== undefined ? props.cell.row.original.gwId.sn + " / " + props.cell.row.original.gwId.type : "" }
            </Link>
        ) 
    }
]