import React, { useRef, useState, useEffect, createRef } from "react";
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table"; // React-Table components & specifics hooks
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronDoubleRight, BsChevronRight } from 'react-icons/bs'; // React-Bootstrap icons
import { TableHeader, TableContent, PagingButton } from '../components/table/index'
import { default as CLIENTS } from "../api/index"
import { BoardPb, ItemPb, GwPb } from '@centiloc/centiloc-ops-api-geo-grpc'
import { FurniturePb } from '@centiloc/centiloc-ops-api-inventory-grpc'
import { formatAttributes, formatFilters } from "../shared/utils";
import '../assets/style/layout/table.css'

const Table = (props) => {
    const dispatch = useDispatch();
     
    // The useLocation hook returns the location object that represents the current URL.
    // You can think about it like a useState that returns a new location whenever the URL changes.
    const location = useLocation();

    // Related ressources used to handle the request of the data which should be listed
    const [limitResult, setLimitResult] = useState(props.data.pagination.size);
    const [filtersList, setFiltersList] = useState([]);
    const [sortBy, setSortBy]           = useState({ id: props.columns[0].accessor, value: props.columns[0].value, desc: false });

     // Dispatch the data listed of the following built table
    useEffect(() => {
        const retParams = Array(0);
        const urlParams = new URLSearchParams(location?.search); // retrieves the current URL with its params

        // Identifies if there is a redirection with filters parameters sent in 'location'
        if(urlParams && urlParams?.getAll('filters') && urlParams.getAll('filters')?.length > 0) {
            urlParams.getAll('filters').forEach((filter) =>  retParams.push({filter: JSON.parse(decodeURI(filter))?.filter, value: JSON.parse(decodeURI(filter))?.value }))
        }

        switch(props.type){
        case "item":
            var sortRef = isNaN(sortBy.value) ? ItemPb.ItemAttr[sortBy.value] : sortBy.value
            break;
        case "board":
            var sortRef = isNaN(sortBy.value) ? BoardPb.BoardAttr[sortBy.value] : sortBy.value
            break;
        case "gateway":
            var sortRef = isNaN(sortBy.value) ? GwPb.GwAttr[sortBy.value] : sortBy.value
            break;
        }
        
        dispatch(props.getAll({ /* GetAll - ask for pagination */
            page: sortBy.desc != props.data.pagination.desc || sortRef != props.data.pagination.sort ? props.data.pagination.page : props.type === "furniture" ? 0 : 1,
            size: limitResult,
            sort: sortBy.value,
            isDescending: sortBy.desc,
            filters: retParams,
        }));
        setFiltersList(retParams) // Save the list useful to handle all visual content (cards, manages filters already set, ...)
    }, [dispatch, setFiltersList, location, limitResult, sortBy, props.type]);

    const dataCsv = useRef([])
    const csvLink = createRef();
    const [dataCsvLoaded, setDataCsvLoaded] = useState(false);

    // Shared properties between components
    const data = props.data.list
    const columns = props.columns
    const initialSearch = props.initialSearch
    const dataChunkForCsv = 1000

    const { page, headerGroups, setPageSize, getTableProps, getTableBodyProps, prepareRow } = useTable({
        columns,
        data,
        autoResetPage: true,
        initialState: { pageSize: limitResult, globalFilter: initialSearch }
    }, useGlobalFilter, useSortBy, usePagination)

    useEffect(() => {
        if (dataCsvLoaded) {
            setDataCsvLoaded(false);
            csvLink.current.link.click();

            // Once the file is generated, reset the list
            dataCsv.current = []
        }
    }, [dataCsv, dataCsvLoaded]);

    const getHeadersForCsv = () => {
        const header = [];
        columns.forEach(function(attr) {
            if (attr.Header != "Boards") {
                header.push({label: attr.Header, key: attr.accessor})
            }
        })

        return header
    }

    let pageForCsv = props.type === "furniture" ? 0 : 1
    const getDataForCsv = () => {
        new Promise((resolve, reject) => {
            let ApiClient = props.type === "furniture" ? CLIENTS.inventory.furniture : CLIENTS.geo[props.type]

            /* `GetAll` - ask for CSV */
            ApiClient.constructor.callAPI(ApiClient.client.getAll.bind(ApiClient.client), formatFilters({ page: pageForCsv, size: dataChunkForCsv, sort: sortBy.value, isDescending: sortBy.desc, filters: filtersList}, props.type),
                (err, response) => {
                    const list = []
                    Object.values(response.toObject())[0].forEach((element) => list.push(formatAttributes(element, props.type)))
                    
                    const rows = [...dataCsv.current];
                    list.forEach(function(cur) {
                        if (props.type === "gateway") {
                            cur.id = cur.id.sn  + " / " + cur.id.type
                        }
                        if (props.type === "furniture") {
                            cur.id = cur.id.sn
                        }

                        rows.push(cur.gwId != undefined ? {...cur, gwId: cur.gwId.sn  + " / " + cur.gwId.type } : cur)
                    })
                    dataCsv.current = rows

                    if(!err && resolve) {
                        resolve(response)
                    } else if (err && reject) { 
                        reject(err)
                    }
                },
            dispatch)
        }).then((response) => {
            if (response.array[2] > pageForCsv) {
                pageForCsv++
                getDataForCsv() // getAll again to get next data
            } else {
                setDataCsvLoaded(true)
            }
        })
    }

    return (
        <div className="mx-2">
            <TableHeader /* Table header containing all filtering behaviour */
                type={ props.type }
                dataCsv = { dataCsv.current }
                csvLink = { csvLink }
                filters={ props.filters }
                filtersList={ filtersList }
                buildDataCsv = { getDataForCsv }
                setFiltersList={ setFiltersList }
                getHeadersCsv={ getHeadersForCsv }
            />
            <TableContent /* Table content listing datasets */
                page={ page }
                func={ props.func }
                type={ props.type }
                getAll={ props.getAll }
                columns={ props.columns }
                actions={ props.actions }
                pagination={ props.data.pagination }
                prepareRow={ prepareRow }
                limitResult={ limitResult }
                filtersList={ filtersList }
                headerGroups={ headerGroups }
                sortBy={ sortBy }
                setSortBy={ setSortBy }
                getTableProps={ getTableProps }
                getTableBodyProps={ getTableBodyProps }
            />


            <div className="py-2 container">
                <div className="d-flex justify-content-between d-block d-xl-none">

                    {/* PAGINATION - reduced screen size */}
                    { props?.data?.links?.first?.page ? Object.entries(props?.data?.links).map(([key, value]) => {
                        if(/^(previous|next)$/.test(key)) { /* Paginations buttons - only next/previous - on a reduced screen size */
                            return <PagingButton key={key} className={"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 rounded"}
                                link={{name: key, pagination: value}}
                                data={props.data}
                                getAll={props.getAll}
                                filters={ filtersList }
                                limitResult={limitResult}
                                disabled={!value || !value.page}
                                visualContent={ key === "previous" ? "Previous" : key === "next" ? "Next" : "" }
                            ></PagingButton>
                        }
                    }) : "" }
                </div>
                <div className="d-none d-xl-block">
                    <div className="d-flex justify-content-between">

                        {/* PAGE SIZE */}
                        <div className="d-flex justify-content-start align-items-center">
                            <select id="listLimit" className="form-select" style={{ width: "auto" }} onChange={(e) => { setLimitResult(Number(e.target.value)); setPageSize(Number(e.target.value)) }} >
                                {[25, 50, 100].map(pageSize => <option key={pageSize} value={pageSize}>Show {pageSize}</option>)}
                            </select>
                        </div>

                        {/* COUNTER */}
                        { <div className="d-flex justify-content-center align-items-center">
                            <div className="px-3 flex-row">
                                <span className="d-inline"> 
                                    {
                                        (props.data.list.length >= (limitResult * props.data.pagination.page) ? props.data.list.length : ((limitResult * props.data.pagination.page) > props.data.count ? props.data.count : limitResult * props.data.pagination.page))
                                            + "/" +props.data.count+ " " +props.type+ (props.data.count > 1 ? "s" : "")
                                    }
                                </span>
                            </div>
                        </div> }

                        {/* PAGINATION - large screen size */}
                        <div className="px-3">
                            <nav className="position-relative d-inline-flex rounded shadow-sm" aria-label="Pagination">
                                { props?.data?.links?.first?.page ? Object.entries(props?.data?.links).map(([key, value]) => { /* Pagination buttons - default screen size */
                                    return <PagingButton key={key} className={(key == "first" ? "rounded-start" : key === "last" ? "rounded-end" : "") + " relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"}
                                        link={{name: key, pagination: value }}
                                        data={props.data}
                                        getAll={props.getAll}
                                        filters={ filtersList }
                                        limitResult={limitResult}
                                        disabled={!value || !value.page}
                                        visualContent={ key === "first" ? <BsChevronDoubleLeft className="" aria-hidden="true" />
                                            : key === "previous" ? <BsChevronLeft className="" aria-hidden="true" />
                                            : key === "next" ? <BsChevronRight className="" aria-hidden="true" />
                                            : key === "last" ? <BsChevronDoubleRight className="" aria-hidden="true" /> 
                                        : "" }
                                    ></PagingButton>
                                }) : "" }
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Table cells status format
const StatusPill = ({ value }) => {
    return <span className={ "px-3 py-1 text-uppercase font-bold text-xs rounded-pill shadow-sm " + (
        value === "ERROR" ? "bg-status-grey" 
        : value == "CONNECTED" || value === "GW_CONNECTED" || value === "IN" ? "bg-status-green" 
        : value === "DISCONNECTED" || value === "GW_DISCONNECTED" || value === "OUT" ? "bg-status-red" 
        : null
    )} >{ value }</span>
};

export { Table, StatusPill, TableHeader, TableContent, PagingButton };