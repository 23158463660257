import * as types from "../actions.types";
import { formatAttributes } from "../../shared/utils";

/**
 * @function gatewayMiddleware()
 * @returns next(action)
 * 
 * Middleware used to convert the returned gateways datasets before passing through the store.
 */
export function gatewayMiddleware({ getState, dispatch }) {
    return function(next) {
        return function(action) {
            switch(action.type) {
                case types.GET_GW_SUCCESS:
                    action.payload = formatAttributes(action.payload, "gateway");

                    break;  
                case types.GET_ALL_GW_SUCCESS:
                    action.payload.gwsList.forEach((gateway) => {
                        gateway = formatAttributes(gateway, "gateway")
                    });

                    break;             
                default:
                    return next(action, dispatch)
            }

            return next(action); // You should always return next(action) in your middleware.
            // If you forget to return this the application will stop, and no other action will reach the reducer.
        }
    }
}